import { InputText } from 'primereact/inputtext'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ActionInRow from '../../components/Templates/ActionInRow'
import Table from '../../components/Templates/Table/Table'
import TableInput from '../../components/Templates/TableInput'
import { PrivateRoute } from '../../helpers/PrivateRoute'
import { useBaseFunction } from '../../layouts/AppContext'
import { useLoading } from '../../layouts/FullScreenLoading/FullScreenLoading'
import { fetchMajors } from '../../redux/slice/major.slice'
import MajorSchema from '../../shared/schema/major.schema'
import { createMajor, deleteMajor, updateMajor } from '../../shared/services/major.service'
import { Chips } from 'primereact/chips'
import { Tag } from 'primereact/tag'
import TableCaption from '../../components/Templates/TableCaption'
import { Space } from 'antd'
import { Button } from 'primereact/button'
import Card from '../../components/Templates/Card'
import { Dialog } from 'primereact/dialog'
import { MajorForm } from './Form/MajorForm'
import { useFormik } from 'formik'
export default function MajorListing() {
  const { auth } = useSelector((state) => state.admin)
  const { entities, totalRecords, loading: majorLoading } = useSelector((state) => state.major)
  const dispatch = useDispatch()
  const loading = useLoading()
  const [visible, setVisible] = useState(false)
  const base = useBaseFunction()
  const [table, setTable] = useState({
    first: 0,
    rows: 10,
    page: 1,
    filters: {
      name: { value: '' },
      categories: { value: '' },
    },
  })
  const schema = new MajorSchema(loading, base, auth)
  const fetchData = useCallback(() => {
    dispatch(fetchMajors(table))
  }, [dispatch, table])
  useEffect(() => {
    fetchData()
  }, [fetchData])
  const columns = [
    {
      key: 'name',
      name: 'สาขาวิชา',
      width: '40%',
      sortable: true,
      editor: (options) => (
        <TableInput options={options} validate={schema.nameValidation} placeholder='สาขาวิชา'>
          <InputText />
        </TableInput>
      ),
    },
    {
      key: 'categories',
      name: 'หมวดหมู่',
      width: '50%',
      sortable: true,
      editor: (options) => (
        <TableInput options={options} validate={schema.categories} placeholder='ใช้ , เพิ่อแบ่งหมวดหมู่'>
          <Chips separator=',' />
        </TableInput>
      ),
      render: (record) => (
        <span>
          {record.categories?.map((item, id) => (
            <Tag value={item} key={id} className='mx-1' />
          ))}
        </span>
      ),
    },
    {
      key: 'action',
      width: '150px',
      rowEditor: true,
      render: (record, { rowEditor }) => (
        <ActionInRow record={record} rowEditor={rowEditor} handleRemove={handleRemove} />
      ),
    },
  ]
  const formik = useFormik({
    initialValues: schema.defaultValue,
    validationSchema: schema.validation,
    onSubmit: (values) => {
      schema.handleSubmit(values)
    },
  })
  schema.setUpdateFunction(async (...props) => {
    await updateMajor(...props)
    fetchData()
  })
  schema.setCrateFunction(async (...props) => {
    await createMajor(...props)
    hideDialog()
    fetchData()
  })
  schema.setRemoveFunction(async (...props) => {
    const { data } = await deleteMajor(...props)
    if (data) {
      fetchData()
    }
  })
  const handleRemove = (id) => {
    schema.onRemove(id, auth.token)
  }
  const handleRowSave = async (e) => {
    const { newData } = e
    schema.setIdentified(newData.id)
    schema.handleSubmit(newData, false)
  }

  const showDialog = () => setVisible(true)
  const hideDialog = () => {
    setVisible(false)
    formik.resetForm()
    formik.setErrors({})
  }
  return (
    <PrivateRoute>
      <Dialog
        header='เพิ่มข้อมูลประเภทคะแนนภาษา'
        draggable={false}
        modal={true}
        visible={visible}
        style={{ maxWidth: '50vw', minWidth: '450px' }}
        onHide={hideDialog}
      >
        <MajorForm formik={formik} handleClose={hideDialog} />
      </Dialog>
      <TableCaption
        render={
          <Space size='small' className='mb-2'>
            <Button
              className='p-button-secondary'
              icon='pi pi-refresh'
              onClick={fetchData}
              loading={majorLoading}
            />
            <Button icon='pi pi-plus' onClick={showDialog} />
            <Button
              icon='pi pi-question'
              className='p-button-help'
              onClick={() => base.infomationToast('ในหัวข้อหมวดหมู่ ระหว่างกรอกข้อมูลให้ใช้ comma หรือ , เพื่อแบ่งประเภท')}
            />
          </Space>
        }
      />
      <Card header='ข้อมูลสาขาวิชา'>
        <Table
          onRowEditComplete={handleRowSave}
          editMode='row'
          validate={schema.validation}
          dataKey='id'
          value={entities}
          totalRecords={totalRecords}
          loading={majorLoading}
          table={table}
          setTable={setTable}
          columns={columns}
        />
      </Card>
    </PrivateRoute>
  )
}
