import axios from 'axios'
import i18next from 'i18next'
import I18NextXhrBackend from 'i18next-xhr-backend'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import 'sweetalert2/src/sweetalert2.scss'
import { AppProvider } from './layouts/AppContext'
import App from './pages'
import { persistor, store } from './redux/configureStore'
import Backend from 'i18next-http-backend'
import Cache from 'i18next-localstorage-cache'
import { initReactI18next } from 'react-i18next'
// DEVELOPMENT BUILD
process.env.NODE_ENV === 'production' &&
  process.env.REACT_APP_ENV_NAME === 'development' &&
  console.log('[INFO] Application running on development build.')
// PRODUCTION BUILD
process.env.NODE_ENV === 'production' &&
  process.env.REACT_APP_ENV_NAME === 'production' &&
  console.log('[INFO] Application running on production build.')
// DEVELOPMENT
process.env.NODE_ENV === 'development' &&
  console.log('[INFO] Application running on development build.', process.env)

axios.defaults.baseURL = process.env.REACT_APP_API_URL
axios.defaults.timeout = 50000
const root = ReactDOM.createRoot(document.getElementById('root'))

i18next
  .use(I18NextXhrBackend)
  .use(Cache)
  .use(Backend)
  .use(initReactI18next)
  .init({
    fallbackLng: 'th',
    ns: ['translations'],
    interpolation: {
      escapeValue: false,
    },
    react: {
      wait: true,
    },
  })
  .then(() => {
    root.render(
      <Provider store={store}>
        <AppProvider>
          <PersistGate loading={null} persistor={persistor}>
            <App />
          </PersistGate>
        </AppProvider>
      </Provider>
    )
  })
