import { Space } from 'antd'
import { useFormik } from 'formik'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { InputText } from 'primereact/inputtext'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ActionInRow from '../../components/Templates/ActionInRow'
import Card from '../../components/Templates/Card'
import Table from '../../components/Templates/Table/Table'
import TableCaption from '../../components/Templates/TableCaption'
import TableInput from '../../components/Templates/TableInput'
import { PrivateRoute } from '../../helpers/PrivateRoute'
import { useBaseFunction } from '../../layouts/AppContext'
import { useLoading } from '../../layouts/FullScreenLoading/FullScreenLoading'
import { fetchLanguageType } from '../../redux/slice/language-type.slice'
import LanguageTypeSchema from '../../shared/schema/language-type.schema'
import {
  createLanguagType,
  deleteLanguageType,
  updateLanguageType,
} from '../../shared/services/language-type.service'
import { LanguageTypeForm } from './Form/LanguageTypeForm'
export default function LanguageTypeListing() {
  const { auth } = useSelector((state) => state.admin)
  const { entities, totalRecords, loading: languageTypeLoading } = useSelector((state) => state.languageType)
  const dispatch = useDispatch()
  const loading = useLoading()
  const [visible, setVisible] = useState(false)
  const base = useBaseFunction()
  const [table, setTable] = useState({
    first: 0,
    rows: 10,
    page: 1,
    filters: {
      name: { value: '' },
    },
  })
  const schema = new LanguageTypeSchema(loading, base, auth)
  const fetchData = useCallback(() => {
    dispatch(fetchLanguageType(table))
  }, [dispatch, table])
  useEffect(() => {
    fetchData()
  }, [fetchData])
  const columns = [
    {
      key: 'name',
      name: 'ชื่อหลักสูตร',
      width: '90%',
      sortable: true,
      editor: (options) => (
        <TableInput options={options} validate={schema.nameValidation} placeholder='ชื่อหลักสูตร'>
          <InputText />
        </TableInput>
      ),
    },
    {
      key: 'action',
      width: '150px',
      rowEditor: true,
      render: (record, { rowEditor }) => (
        <ActionInRow record={record} rowEditor={rowEditor} handleRemove={handleRemove} />
      ),
    },
  ]
  const formik = useFormik({
    initialValues: schema.defaultValue,
    validationSchema: schema.validation,
    onSubmit: (values) => {
      schema.handleSubmit(values)
    },
  })
  schema.setUpdateFunction(async (...props) => {
    await updateLanguageType(...props)
    fetchData()
  })
  schema.setCrateFunction(async (...props) => {
    await createLanguagType(...props)
    hideDialog()
    fetchData()
  })
  schema.setRemoveFunction(async (...props) => {
    const { data } = await deleteLanguageType(...props)
    if (data) {
      fetchData()
    }
  })
  const handleRemove = (id) => {
    schema.onRemove(id, auth.token)
  }
  const handleRowSave = async (e) => {
    const { newData } = e
    schema.setIdentified(newData.id)
    schema.handleSubmit(newData, false)
  }

  const showDialog = () => setVisible(true)
  const hideDialog = () => {
    setVisible(false)
    formik.resetForm()
    formik.setErrors({})
  }
  return (
    <PrivateRoute>
      <Dialog
        header='เพิ่มข้อมูลหลักสูตร'
        draggable={false}
        modal={true}
        visible={visible}
        style={{ maxWidth: '50vw', minWidth: '450px' }}
        onHide={hideDialog}
      >
        <LanguageTypeForm formik={formik} handleClose={hideDialog} />
      </Dialog>
      <TableCaption
        render={
          <Space size='small' className='mb-2'>
            <Button
              className='p-button-secondary'
              icon='pi pi-refresh'
              onClick={fetchData}
              loading={languageTypeLoading}
            />
            <Button icon='pi pi-plus' onClick={showDialog} />
          </Space>
        }
      />
      <Card header='ข้อมูลหลักสูตร'>
        <Table
          onRowEditComplete={handleRowSave}
          editMode='row'
          validate={schema.validation}
          dataKey='id'
          value={entities}
          totalRecords={totalRecords}
          loading={languageTypeLoading}
          table={table}
          setTable={setTable}
          columns={columns}
        />
      </Card>
    </PrivateRoute>
  )
}
