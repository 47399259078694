import { object, string } from 'yup'
import { SchemaTemplate } from './template.schema'

export default class BankSchema extends SchemaTemplate {
  validation = object().shape({
    name: string().required('โปรดระบุ {}!'),
    logo: string().required('โปรดระบุ {}!'),
  })

  defaultValue = {
    name: '',
    logo: null,
  }

  constructor(loader, base, auth, def) {
    super(loader, base, auth)
    if (def) {
      this.defaultValue = def
    }
  }
}
