import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { AppContextPath } from '../../shared/constant/_environment'

export const fetchSetting = createAsyncThunk('setting/fetchSetting ', async (_, { getState }) => {
  const response = await axios({
    method: 'GET',
    url: `${AppContextPath.v1.ADMIN_URL}/config`,
    headers: {
      Authorization: 'Bearer ' + getState().admin.auth.token,
    },
  })
  return response.data
})

export const settingSlice = createSlice({
  name: 'setting',
  initialState: {
    entities: [],
    loading: false,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSetting.pending, (state) => {
      state.loading = true
    })
    builder.addCase(fetchSetting.fulfilled, (state, action) => {
      state.entities = action.payload
      state.loading = false
    })
    builder.addCase(fetchSetting.rejected, (state) => {
      state.entities = []
      state.loading = false
    })
  },
})
