import React from 'react'

export default function Breadcrumbs() {
  
  return (
    <nav aria-label='breadcrumb'>
      <ol className='breadcrumb my-0 ms-2'>
        <li className='breadcrumb-item'>
          <span>Homes</span>
        </li>
        <li className='breadcrumb-item active'>
          <span>Dashboard</span>
        </li>
      </ol>
    </nav>
  )
}
