import { Space } from 'antd'
import { useFormik } from 'formik'
import { Button } from 'primereact/button'
import { TabMenu } from 'primereact/tabmenu'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { useNavigate } from 'react-router-dom'
import Card from '../../components/Templates/Card'
import TableCaption from '../../components/Templates/TableCaption'
import { PrivateRoute } from '../../helpers/PrivateRoute'
import { useBaseFunction } from '../../layouts/AppContext'
import { useLoading } from '../../layouts/FullScreenLoading/FullScreenLoading'
import { fetchUniversitySingle } from '../../redux/slice/university.slice'
import UniversitySchema from '../../shared/schema/university.schema'
import { createUniversity, updateUniversity } from '../../shared/services/university.service'
import FormUtils from '../../utils/form.utils'
import * as ObjectUtils from '../../utils/object.utils'
import ApplyingListing from './ApplyingListing'
import CostListing from './CostListing'
import UniversityForm from './Form/UniversityForm'
import ScholarshipListing from './ScholarshipListing'

export default function UniversitySingle({ isEdit }) {
  const { universityId } = useParams()
  const navigate = useNavigate()
  const loading = useLoading()
  const { auth } = useSelector((state) => state.admin)
  const base = useBaseFunction()
  const [activeMenu, setActiveMenu] = useState(0)
  const [visibleDialog, setVisibleDialog] = useState(false)
  const schema = useMemo(() => new UniversitySchema(loading, base, auth), [loading, auth, base])
  const { current: university, loading: universityLoading } = useSelector((state) => state.university)
  const [initialValues, setInitialValues] = useState(schema.defaultValue)
  const dispatch = useDispatch()
  schema.setIdentified(universityId)
  schema.setCrateFunction(async (...props) => {
    const { data } = await createUniversity(...props)
    navigate(`/admin/university/edit/${data.id}`, { replace: true })
  })
  schema.setUpdateFunction(updateUniversity)
  const onSubmit = (values) => {
    schema.handleSubmit({ ...values, slug: FormUtils.getSlug(values.name) })
  }
  const formik = useFormik({
    initialValues,
    onSubmit: onSubmit,
    validationSchema: schema.validation,
    enableReinitialize: true,
  })

  useEffect(() => {
    if (isEdit && ObjectUtils.isNotEmpty(university)) {
      setInitialValues(ObjectUtils.copy(schema.defaultValue, university))
    }
  }, [university, isEdit, schema])
  useEffect(() => {
    if (isEdit) {
      dispatch(fetchUniversitySingle(universityId))
    }
  }, [dispatch, universityId, isEdit])
  const tabs = [
    { label: 'การรับสมัคร', key: 'applyings' },
    { label: 'ทุนการศึกษา', key: 'scholarships' },
    { label: 'ค่าใช้จ่าย', key: 'costs' },
  ]
  const hideDialog = () => setVisibleDialog(false)
  const showDialog = () => setVisibleDialog(true)
  return (
    <PrivateRoute>
      <Card header={isEdit ? 'แก้ไขมหาวิทยาลัย' : 'เพิ่มมหาวิทยาลัย'} loading={universityLoading}>
        <UniversityForm formik={formik} />
      </Card>
      {isEdit && universityId && (
        <>
          <TableCaption
            render={
              <Space size='small' className='mb-2'>
                <Button icon='pi pi-plus' onClick={showDialog} />
              </Space>
            }
          />
          <TabMenu model={tabs} activeIndex={activeMenu} onTabChange={(e) => setActiveMenu(e.index)} />
          <Card header={tabs[activeMenu]?.label}>
            {activeMenu === 0 && (
              <ApplyingListing
                universityId={universityId}
                visibleDialog={visibleDialog}
                hideDialog={hideDialog}
              />
            )}
            {activeMenu === 1 && (
              <ScholarshipListing
                universityId={universityId}
                visibleDialog={visibleDialog}
                hideDialog={hideDialog}
              />
            )}
            {activeMenu === 2 && (
              <CostListing
                universityId={universityId}
                visibleDialog={visibleDialog}
                hideDialog={hideDialog}
              />
            )}
          </Card>
        </>
      )}
    </PrivateRoute>
  )
}
