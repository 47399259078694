import { Checkbox } from 'primereact/checkbox'
import { InputText } from 'primereact/inputtext'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchRegisterForm } from '../../../redux/slice/application.slice'
import { EMBASSY_ENUM } from '../../../shared/constant/application'
import DateUtils from '../../../utils/date.utils'
export default function DisplayRegisterForm({ applicationId }) {
  const { register } = useSelector((state) => state.application)
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(fetchRegisterForm(applicationId))
  }, [dispatch, applicationId])
  if (!register) {
    return <div className='text-red-500 p-3'>ยังไม่มีการกรอกใบสมัคร</div>
  }
  const json = JSON.parse(register.value)
  console.log(json)
  return (
    <div>
      <PersonalInfomation infomation={json.personalInfomation} />
      <hr />
      <EducationInfomation infomation={json.educationInfomation} />
      <hr />
      <FamilyInfomation infomation={json.familyInfomation} />
      <hr />
      <StudyPlanInfomation infomation={json.studyPlan} />
      <hr />
      <LanguageAbilityInfomation infomation={json.languageAbility} />
      <hr />
      <OtherInfomation infomation={json.otherInfomation} embassy={json.embassy} />
    </div>
  )
}
function InlineDisplay({ label, value }) {
  return (
    <div className='grid'>
      <label className='col-fixed font-bold' style={{ maxWidth: '100%' }}>
        {label}
      </label>
      <div className='col'>{value || <span className='text-red-500'>-</span>}</div>
    </div>
  )
}
function PersonalInfomation({ infomation }) {
  return (
    <div className='p-3'>
      <h4>Personal & Passport</h4>
      <InlineDisplay label='English Name' value={infomation.englishFName + ' ' + infomation.englishLName} />
      <InlineDisplay label='Chinese Name' value={infomation.chineseName} />
      <InlineDisplay label='Currently In China' value={infomation.currentlyInChina ? 'Yes' : 'No'} />
      <InlineDisplay label='Gender' value={infomation.gender} />
      <InlineDisplay label='Marital Status' value={infomation.maritalStatus} />
      <InlineDisplay label='Religion' value={infomation.religion} />
      <InlineDisplay label='Place of birth' value={infomation.placeOfBirth} />
      <InlineDisplay label='Date of birth' value={DateUtils.getString(infomation.dateOfBirth)} />
      <InlineDisplay label='Nationality' value={infomation.nationality} />
      <InlineDisplay label='Passport valid date' value={DateUtils.getString(infomation.passportValidDate)} />
      <InlineDisplay label='Passport No.' value={infomation.passportNo} />
    </div>
  )
}

function FamilyInfomation({ infomation }) {
  return (
    <div className='p-3'>
      <h4>Family Infomation</h4>
      <div className='grid mt-2'>
        <div className='col-4 border-1 border-bluegray-100'>
          <h5 className='underline'>Father</h5>
          <InlineDisplay label='Name' value={infomation.father?.name} />
          <InlineDisplay label='Telephone' value={infomation.father?.telephone} />
          <InlineDisplay label='Company' value={infomation.father?.company} />
          <InlineDisplay label='Occupation' value={infomation.father?.occupation} />
          <InlineDisplay label='Age' value={infomation.father?.age} />
          <InlineDisplay label='E-mail' value={infomation.father?.email} />
          <InlineDisplay label='Line ID' value={infomation.father?.lineId} />
          <InlineDisplay label='Wechat ID' value={infomation.father?.wechatId} />
          <InlineDisplay label='Nationality' value={infomation.father?.nationality} />
        </div>
        <div className='col-4 border-1 border-bluegray-100'>
          <h5 className='underline'>Mother</h5>
          <InlineDisplay label='Name' value={infomation.mother?.name} />
          <InlineDisplay label='Telephone' value={infomation.mother?.telephone} />
          <InlineDisplay label='Company' value={infomation.mother?.company} />
          <InlineDisplay label='Occupation' value={infomation.mother?.occupation} />
          <InlineDisplay label='Age' value={infomation.mother?.age} />
          <InlineDisplay label='E-mail' value={infomation.mother?.email} />
          <InlineDisplay label='Line ID' value={infomation.mother?.lineId} />
          <InlineDisplay label='Wechat ID' value={infomation.mother?.wechatId} />
          <InlineDisplay label='Nationality' value={infomation.mother?.nationality} />
        </div>
        <div className='col-4 border-1 border-bluegray-100'>
          <h5 className='underline'>Uncle</h5>
          <InlineDisplay label='Name' value={infomation.uncle?.name} />
          <InlineDisplay label='Telephone' value={infomation.uncle?.telephone} />
          <InlineDisplay label='Company' value={infomation.uncle?.company} />
          <InlineDisplay label='Occupation' value={infomation.uncle?.occupation} />
          <InlineDisplay label='Age' value={infomation.uncle?.age} />
          <InlineDisplay label='E-mail' value={infomation.uncle?.email} />
          <InlineDisplay label='Line ID' value={infomation.uncle?.lineId} />
          <InlineDisplay label='Wechat ID' value={infomation.uncle?.wechatId} />
          <InlineDisplay label='Nationality' value={infomation.uncle?.nationality} />
        </div>
        <div className='col-4 border-1 border-bluegray-100'>
          <h5 className='underline'>Aunt</h5>
          <InlineDisplay label='Name' value={infomation.aunt?.name} />
          <InlineDisplay label='Telephone' value={infomation.aunt?.telephone} />
          <InlineDisplay label='Company' value={infomation.aunt?.company} />
          <InlineDisplay label='Occupation' value={infomation.aunt?.occupation} />
          <InlineDisplay label='Age' value={infomation.aunt?.age} />
          <InlineDisplay label='E-mail' value={infomation.aunt?.email} />
          <InlineDisplay label='Line ID' value={infomation.aunt?.lineId} />
          <InlineDisplay label='Wechat ID' value={infomation.aunt?.wechatId} />
          <InlineDisplay label='Nationality' value={infomation.aunt?.nationality} />
        </div>
        <div className='col-4 border-1 border-bluegray-100'>
          <h5 className='underline'>Brother</h5>
          <InlineDisplay label='Name' value={infomation.brother?.name} />
          <InlineDisplay label='Telephone' value={infomation.brother?.telephone} />
          <InlineDisplay label='Company' value={infomation.brother?.company} />
          <InlineDisplay label='Occupation' value={infomation.brother?.occupation} />
          <InlineDisplay label='Age' value={infomation.brother?.age} />
          <InlineDisplay label='E-mail' value={infomation.brother?.email} />
          <InlineDisplay label='Line ID' value={infomation.brother?.lineId} />
          <InlineDisplay label='Wechat ID' value={infomation.brother?.wechatId} />
          <InlineDisplay label='Nationality' value={infomation.brother?.nationality} />
        </div>
        <div className='col-4 border-1 border-bluegray-100'>
          <h5 className='underline'>Sister</h5>
          <InlineDisplay label='Name' value={infomation.sister?.name} />
          <InlineDisplay label='Telephone' value={infomation.sister?.telephone} />
          <InlineDisplay label='Company' value={infomation.sister?.company} />
          <InlineDisplay label='Occupation' value={infomation.sister?.occupation} />
          <InlineDisplay label='Age' value={infomation.sister?.age} />
          <InlineDisplay label='E-mail' value={infomation.sister?.email} />
          <InlineDisplay label='Line ID' value={infomation.sister?.lineId} />
          <InlineDisplay label='Wechat ID' value={infomation.sister?.wechatId} />
          <InlineDisplay label='Nationality' value={infomation.sister?.nationality} />
        </div>
      </div>
    </div>
  )
}

function EducationInfomation({ infomation }) {
  return (
    <div className='p-3'>
      <h4>Education Infomation</h4>
      <InlineDisplay label='Occupation' value={infomation.occupation} />
      <InlineDisplay label='Education level' value={infomation.educationLevel} />
      <InlineDisplay label='Place of study or work at present' value={infomation.placeOfStudyOrWork} />
      <InlineDisplay label='HSK Level' value={infomation.hskLevel} />
      <InlineDisplay label='Score' value={infomation.hskScore} />
      <InlineDisplay label='English Cert.' value={infomation.englishCert} />
      <InlineDisplay label='Score' value={infomation.englishScore} />
      <InlineDisplay label='Place of residence at present' value={infomation.placeOfResidence} />
      <InlineDisplay label='E-mail' value={infomation.email} />
      <InlineDisplay label='Tel' value={infomation.tel} />
      <InlineDisplay label='Line ID' value={infomation.lineId} />
      <InlineDisplay label='WeChat ID' value={infomation.weChatId} />
      <InlineDisplay
        label='Permanent Address for correspondence including postcode, contact person and Telephone'
        value={infomation.permanentAddress}
      />
      <InlineDisplay
        label='Criminal Record'
        value={(infomation.criminalRecord ? 'Yes' : 'No') + ' ' + infomation.criminalRecordDesc}
      />
      <InlineDisplay
        label='Studied from China'
        value={(infomation.studiedChina ? 'Yes' : 'No') + ' ' + infomation.studiedChinaDesc}
      />
      <InlineDisplay
        label='Ever received a scholarship in China'
        value={(infomation.receivedScholar ? 'Yes' : 'No') + ' ' + infomation.receivedScholarDesc}
      />
    </div>
  )
}
function OtherInfomation({ infomation, embassy }) {
  const educationBackground = infomation.educationBackground
  const employmentRecord = infomation.employmentRecord
  return (
    <div className='p-3'>
      <h4>Other</h4>
      <div className='grid'>
        <div className='col'>
          <h5>Education Background</h5>
          {educationBackground.map((background) => (
            <div key={background.school + background.major}>
              <InlineDisplay label='School' value={background.school} />
              <InlineDisplay label='Time (From To)' value={DateUtils.getString(background.time)} />
              <InlineDisplay label='Certificate/Degree' value={background.major} />
              <InlineDisplay label='Major/Specialty' value={background.cert} />
            </div>
          ))}
        </div>
        <div className='col'>
          <h5>Employment Records</h5>
          {employmentRecord.map((record) => (
            <div key={record.school + record.major}>
              <InlineDisplay label='Employer/Institute/Organization' value={record.institute} />
              <InlineDisplay label='Job Title' value={record.major} />
              <InlineDisplay label='Time (From To)' value={DateUtils.getString(record.time)} />
            </div>
          ))}
        </div>
      </div>
      <h5>Which Chinese embassy or consulate would you apply visa at</h5>
      <ul>
        {embassy.map((em) => (
          <li key={em}>{EMBASSY_ENUM[em]}</li>
        ))}
      </ul>
    </div>
  )
}
function StudyPlanInfomation({ infomation }) {
  return (
    <div className='p-3'>
      <h4>Study Plan</h4>
      <div className='grid'>
        <div className='col-12 md:col-4'>
          <h5 className='m-2'>Student Status</h5>
          <div className='flex align-items-center mb-2'>
            <Checkbox
              disabled
              value="Bachelor's Degree"
              name='studentStatus'
              checked={infomation.studentStatus.includes("Bachelor's Degree")}
            />
            <label className='ml-2'>Bachelor's Degree</label>
          </div>
          <div className='flex align-items-center mb-2'>
            <Checkbox
              disabled
              value="Master's Degree"
              name='studentStatus'
              checked={infomation.studentStatus.includes("Master's Degree")}
            />
            <label className='ml-2'>Master's Degree</label>
          </div>
          <div className='flex align-items-center mb-2'>
            <Checkbox
              disabled
              value='Doctorate'
              name='studentStatus'
              checked={infomation.studentStatus.includes('Doctorate')}
            />
            <label className='ml-2'> Doctorate</label>
          </div>
          <div className='flex align-items-center mb-2'>
            <Checkbox
              disabled
              value='Advanced student'
              name='studentStatus'
              checked={infomation.studentStatus.includes('Advanced student')}
            />
            <label className='ml-2'>Advanced student</label>
          </div>
        </div>
        <div className='col-12 md:col-4'>
          <h5 className='m-2'>Field of Study</h5>
          <div className='flex align-items-center mb-2'>
            <Checkbox
              disabled
              value='Chinese Language'
              name='fieldOfStudy'
              checked={infomation.fieldOfStudy.includes('Chinese Language')}
            />
            <label className='ml-2'>Chinese Language</label>
          </div>
          <div className='flex align-items-center mb-2'>
            <Checkbox
              disabled
              value='Clinical Medicine'
              name='fieldOfStudy'
              checked={infomation.fieldOfStudy.includes('Clinical Medicine')}
            />
            <label className='ml-2'>Clinical Medicine</label>
          </div>
          <div className='flex align-items-center mb-2'>
            <Checkbox
              disabled
              value='Stomatology'
              name='fieldOfStudy'
              checked={infomation.fieldOfStudy.includes('Stomatology')}
            />
            <label className='ml-2'> Stomatology</label>
          </div>
          <div className='flex align-items-center mb-2'>
            <Checkbox
              disabled
              value='Others'
              name='fieldOfStudy'
              checked={infomation.fieldOfStudy.includes('Others')}
            />
            <label className='ml-2'> Others</label>
          </div>
          {infomation.fieldOfStudy.includes('Others') && (
            <InputText
              name='otherField'
              value={infomation.otherField}
              placeholder='Other Field of study'
              style={{ width: '100%' }}
            />
          )}
        </div>
        <div className='col-12 md:col-4'>
          <h5 className='m-2'>Source of Funding</h5>
          <div className='flex align-items-center mb-2'>
            <Checkbox
              disabled
              value='Self-support'
              name='sourceOfFounding'
              checked={infomation.sourceOfFounding.includes('Self-support')}
            />
            <label className='ml-2'>Self-support</label>
          </div>
          <div className='flex align-items-center mb-2'>
            <Checkbox
              disabled
              value='Scholarship'
              name='sourceOfFounding'
              checked={infomation.sourceOfFounding.includes('Scholarship')}
            />
            <label className='ml-2'>Scholarship</label>
          </div>
        </div>
      </div>
      <h5 className='flex gap-2'>
        Duration From
        <h5 className='underline'>
          {infomation.duration.fromMonth} {infomation.duration.fromYear} to {infomation.duration.toMonth}{' '}
          {infomation.duration.toYear}
        </h5>
      </h5>
      <h5>Other Program สมัครเข้าโครงการอื่นๆ</h5>
      <InlineDisplay label='ค่ายฤดูร้อน Summer Camp' value={(infomation.summerCamp || '0') + ' สัปดาห์'} />
      <InlineDisplay label='ค่ายฤดูหนาว Winter Camp' value={(infomation.winterCamp || '0') + ' สัปดาห์'} />
      <InlineDisplay
        label='เรียนติวภาษาจีน Chinese Language'
        value={(infomation.chineseLanguage || '0') + ' ชั่วโมง'}
      />
      <InlineDisplay
        label='เรียนติวภาษาอังกฤษ English Language'
        value={(infomation.medicalSkillTraining || '0') + ' ชั่วโมง'}
      />
      <InlineDisplay
        label='เรียนติวสอบใบประกอบโรคศิลป์ (แพทยศาสตร์) Medical Skill Training'
        value={(infomation.summerCamp || '0') + ' ชั่วโมง'}
      />
    </div>
  )
}
function LanguageAbilityInfomation({ infomation }) {
  return (
    <div className='p-3'>
      <h4>Language Ability</h4>
      <div className='grid'>
        <div className='col'>
          <h5>Chinese</h5>
          <InlineDisplay label='Reading' value={infomation.chineseReading} />
          <InlineDisplay label='Speaking' value={infomation.chineseSpeaking} />
          <InlineDisplay label='Listening' value={infomation.chineseListening} />
          <InlineDisplay label='Writing' value={infomation.chineseWriting} />
        </div>
        <div className='col'>
          <h5>English</h5>
          <InlineDisplay label='Reading' value={infomation.englishReading} />
          <InlineDisplay label='Speaking' value={infomation.englishSpeaking} />
          <InlineDisplay label='Listening' value={infomation.englishListening} />
          <InlineDisplay label='Writing' value={infomation.englishWriting} />
        </div>
        {infomation.languageOther && (
          <div className='col'>
            <h5>{infomation.otherLanguage}</h5>
            <InlineDisplay label='Reading' value={infomation.otherReading} />
            <InlineDisplay label='Speaking' value={infomation.otherSpeaking} />
            <InlineDisplay label='Listening' value={infomation.otherListening} />
            <InlineDisplay label='Writing' value={infomation.otherWriting} />
          </div>
        )}
      </div>
      <InlineDisplay label='Special skills or interests' value={infomation.skillsOrInterests} />
      <InlineDisplay label='Requirement For Resident' value={infomation.requirementForResident} />
      <InlineDisplay label='Special Request' value={infomation.specialRequest} />
      <InlineDisplay
        label='หลังจากเข้าเรียนในมหาวิทยาลัยแล้ว หากมีโอกาส ข้าพเจ้ายินดีที่จะช่วยบริษัทฯโฆษณาเชิญชวนรุ่นน้อง (ตัวอย่างเช่น เป็นตัวแทนพูดในงานสัมมนาของบริษัทฯหรือเขียนข้อความแสดงความคิดเห็นในสื่อโฆษณาบริษัทฯหรือเว็บไซด์บริษัทฯ)'
        value={infomation.agreeTermOfService ? 'Yes' : 'No'}
      />
    </div>
  )
}
