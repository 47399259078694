import { object, string } from 'yup'
import { SchemaTemplate } from './template.schema'

export default class ScholarshipSchema extends SchemaTemplate {
  nameValidation = string().required('ต้องระบุ {}!')
  descValidation = string().required('ต้องระบุ {}!')
  linkValidation = string().url('รูปแบบ {} ไม่ถูกต้อง')
  validation = object().shape({
    name: this.nameValidation,
    desc: this.descValidation,
    link: this.linkValidation,
  })

  defaultValue = {
    name: '',
    desc: '',
    link: '',
  }
}
