import axios from 'axios'
import { AppContextPath } from '../constant/_environment'
export const deleteUniversity = async (universityId, token) => {
  return await axios.delete(`${AppContextPath.ADMIN_URL}/university/delete/${universityId}`, {
    headers: { Authorization: `Bearer ${token}` },
  })
}

export const updateUniversity = async (payload, universityId, token) => {
  return await axios.put(`${AppContextPath.ADMIN_URL}/university/update/${universityId}`, payload, {
    headers: { Authorization: `Bearer ${token}` },
  })
}

export const createUniversity = async (payload, token) => {
  return await axios.post(`${AppContextPath.ADMIN_URL}/university/new`, payload, {
    headers: { Authorization: `Bearer ${token}` },
  })
}
