import { object, string } from 'yup'
import { SchemaTemplate } from './template.schema'

export default class CountrySchema extends SchemaTemplate {
  validation = object().shape({
    name: string().required('โปรดระบุ {}!'),
  })

  defaultValue = {
    name: '',
    unit: '',
  }
}
