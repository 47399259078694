export function transToKeyValue(obj) {
  const keys = Object.keys(obj)
  return keys.map((k) => ({
    key: k,
    value: obj[k],
  }))
}

export function copy(source, target) {
  const object = {}
  const names = Object.keys(target)
  Object.keys(source).forEach((name) => {
    if (names.includes(name) && !!target[name]) {
      object[name] = target[name]
    } else {
      object[name] = source[name]
    }
  })
  return object
}

export function isNotEmpty(object) {
  if (Array.isArray(object)) {
    return object.length > 0
  } else if (typeof object === 'object') {
    return Object.keys(object).length > 0
  } else {
    return !!object
  }
}
