import i18next from 'i18next'
import { Dialog } from 'primereact/dialog'
import { Toast } from 'primereact/toast'
import React, { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { I18nextProvider } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import ChangeRole from '../components/ChangeRole'
import { getAdminInfo } from '../redux/slice/admin.slice'
import { storeLanguage } from '../redux/slice/i18n.slice'
import { FullScreenLoadingProvider } from './FullScreenLoading/FullScreenLoading'
export function AppProvider({ children }) {
  const toast = useRef()
  const dispatch = useDispatch()
  const { current } = useSelector((state) => state.i18n)
  const { auth, currentRole } = useSelector((state) => state.admin)
  useEffect(() => {
    i18next.changeLanguage(current).then(() => {
      setLanguage(current)
    })
  }, [current])
  useEffect(() => {
    if (auth?.id) {
      dispatch(getAdminInfo(auth?.id))
    }
  }, [dispatch, auth?.id])
  const [language, setLanguage] = useState('th')
  const [roleDialog, setRoleDialog] = useState(false)
  const successToast = (message) => {
    toast.current.show({
      severity: 'success',
      summary: 'Success!',
      detail: message,
      life: 5000,
    })
  }

  const infomationToast = (message) => {
    toast.current.show({
      severity: 'info',
      summary: 'Infomation',
      detail: message,
      life: 5000,
    })
  }

  const warningToast = (message) => {
    toast.current.show({ severity: 'warn', summary: 'Warning!', detail: message, life: 5000 })
  }

  const errorToast = (message) => {
    toast.current.show({ severity: 'error', summary: 'Error!', detail: message, life: 5000 })
  }

  const changeLanguage = useCallback(
    async (lang) => {
      await i18next.changeLanguage(lang).then(() => {
        setLanguage(i18next.language)
        dispatch(storeLanguage(i18next.language))
      })
    },
    [dispatch]
  )

  const base = useMemo(
    () => ({
      successToast,
      infomationToast,
      warningToast,
      errorToast,
      language: {
        changeLanguage,
        value: language,
      },
      role: {
        roleDialog: {
          open() {
            setRoleDialog(true)
          },
          close() {
            setRoleDialog(false)
          },
        },
      },
    }),
    [language, changeLanguage]
  )

  return (
    <AppContext.Provider value={base}>
      <I18nextProvider>
        <FullScreenLoadingProvider>
          <Toast ref={toast} style={{ zIndex: 9999 }} />
          <Dialog
            modal
            visible={roleDialog}
            draggable={false}
            header='เลือกบทบาท'
            onShow={() => dispatch(getAdminInfo())}
            onHide={() => base.role.roleDialog.close()}
          >
            ปัจจุบัน :{currentRole?.name}
            <ChangeRole roles={auth?.roles} currentRole={currentRole} />
          </Dialog>
          {children}
        </FullScreenLoadingProvider>
      </I18nextProvider>
    </AppContext.Provider>
  )
}
const AppContext = createContext({
  successToast: (message) => {},
  infomationToast: (message) => {},
  warningToast: (message) => {},
  errorToast: (message) => {},
  language: {
    changeLanguage: (lang) => {},
    value: null,
  },
  role: {
    roleDialog: {
      open: () => {},
      close: () => {},
    },
  },
})

export function useBaseFunction() {
  const context = useContext(AppContext)
  if (!context) {
    throw new Error('useLoading must be used within AppProvider')
  }
  return context
}
