import axios from 'axios'
import { AppContextPath } from '../constant/_environment'
export const deleteBank = async (bankId, token) => {
  return await axios.delete(`${AppContextPath.ADMIN_URL}/bank/delete/${bankId}`, {
    headers: { Authorization: `Bearer ${token}` },
  })
}

export const updateBank = async (payload, bankId, token) => {
  return await axios.put(`${AppContextPath.ADMIN_URL}/bank/update/${bankId}`, payload, {
    headers: { Authorization: `Bearer ${token}` },
  })
}

export const createBank = async (payload, token) => {
  return await axios.post(`${AppContextPath.ADMIN_URL}/bank/new`, payload, {
    headers: { Authorization: `Bearer ${token}` },
  })
}
