import { FieldArray, Formik } from 'formik'
import { Button } from 'primereact/button'
import { Dropdown } from 'primereact/dropdown'
import { InputNumber } from 'primereact/inputnumber'
import { InputText } from 'primereact/inputtext'
import FilePicker from '../../../../components/FilePicker'
import InlineLabel from '../../../../components/Templates/InlineLabel'
import ProductSchema from '../../../../shared/schema/product.schema'

export default function ProductForm({ onSubmit, initialValues, validationSchema }) {
  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={validationSchema}
    >
      {({ errors, touched, values, handleChange, handleSubmit, setFieldValue }) => (
        <form onSubmit={handleSubmit}>
          <InlineLabel errors={errors} touched={touched} label='ชื่อสินค้า' name='name' required>
            <InputText onChange={handleChange} value={values.name} />
          </InlineLabel>
          <InlineLabel label='ราคา' createElement={false} errors={errors} required>
            <FieldArray
              name='price'
              render={({ push, remove }) => (
                <>
                  {values.price.map((item, index) => (
                    <span className='flex align-items-center' key={index}>
                      <InputNumber
                        onValueChange={handleChange}
                        min={0}
                        className={errors.price && errors.price[index] && touched.price && 'p-invalid'}
                        value={values.price[index]}
                        name={`price.${index}`}
                      />
                      <Button
                        className='p-button-text p-button-sm'
                        type='button'
                        onClick={() => push('')}
                        icon='pi pi-plus'
                      />
                      {index > 0 && (
                        <Button
                          className='p-button-text p-button-sm'
                          type='button'
                          onClick={() => remove(index)}
                          icon='pi pi-minus'
                        />
                      )}
                    </span>
                  ))}
                  {errors.price && touched.price && (
                    <small className='p-error m-0 p-0 ml-auto'>โปรดระบุราคา!</small>
                  )}
                </>
              )}
            />
          </InlineLabel>
          <InlineLabel errors={errors} touched={touched} label='ประเภท' name='type' required>
            <Dropdown
              options={Object.keys(ProductSchema.PRODUCT_ENUM)}
              onChange={handleChange}
              value={values.type}
            />
          </InlineLabel>
          <InlineLabel label='ใบสมัคร'>
            <>
              <FilePicker
                id='filePicker'
                onChange={(e) => setFieldValue('file', e.target.files[0])}
                name='file'
                value={values.file}
              />
            </>
          </InlineLabel>
          <div className='d-flex justify-content-end gap-2 m-3'>
            <Button type='submit' label='บันทึก' />
          </div>
        </form>
      )}
    </Formik>
  )
}
