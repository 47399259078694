import { t } from 'i18next'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import { PickList } from 'primereact/picklist'
import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react'
import InlineLabel from '../../../../components/Templates/InlineLabel'
import { getSidebarItems } from '../../../../shared/constant/_sidebar'
export default function RoleForm({ formik }) {
  const { errors, touched, handleChange, values, handleSubmit, setFieldValue } = formik
  const filterTitle = ({ isTitle }) => !isTitle
  const filterCurrent = useCallback(({ name }) => !values.pageNames.includes(name), [values])
  const filterSuperAdmin = ({ superadmin }) => !superadmin
  const sidebar = useMemo(
    () =>
      getSidebarItems()
        .filter(filterSuperAdmin)
        .filter(filterTitle)
        .map((i) => ({ name: i.name, path: i.path, getIcon: () => i.icon })),
    []
  )
  const [source, setSource] = useState([])
  const [target, setTarget] = useState([])
  useEffect(() => {
    setSource(sidebar.filter(filterCurrent))
    setTarget(sidebar.filter((i) => !filterCurrent(i)))
  }, [filterCurrent, sidebar])
  const onChange = (e) => {
    setSource(e.source)
    setTarget(e.target)
    setFieldValue(
      'pageNames',
      (e.target ?? []).map((i) => i.name)
    )
  }
  return (
    <Fragment>
      <form onSubmit={handleSubmit}>
        <InlineLabel errors={errors} touched={touched} label='ชื่อบทบาท' name='name'>
          <InputText onChange={handleChange} value={values.name} />
        </InlineLabel>
        <div className='p-3'>
          <PickList
            source={source}
            target={target}
            itemTemplate={PickItem}
            onChange={onChange}
            targetStyle={{ height: '100%' }}
            sourceStyle={{ height: '100%' }}
            showSourceControls={false}
            showTargetControls={false}
          />
        </div>
        <div className='d-flex justify-content-end p-4'>
          <Button label='บันทึก' type='submit' />
        </div>
      </form>
    </Fragment>
  )
}

function PickItem(item) {
  return (
    <div className='flex align-items-center gap-4'>
      {item.getIcon()}
      {t(`menu.${item.name}`)}
    </div>
  )
}
