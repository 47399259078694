import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import React from 'react'
import InlineLabel from '../../../../components/Templates/InlineLabel'
export function CityForm({ formik }) {
  const { errors, touched, handleChange, values, handleSubmit } = formik
  return (
    <form onSubmit={handleSubmit}>
      <InlineLabel errors={errors} touched={touched} label='ชื่อเมือง' name='name' required>
        <InputText onChange={handleChange} value={values.name} />
      </InlineLabel>

      <div className='d-flex justify-content-end gap-2 m-3'>
        <Button type='submit' label='บันทึก' />
      </div>
    </form>
  )
}
