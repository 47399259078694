import React from 'react'
import { HelmetProvider } from 'react-helmet-async'
import { useSelector } from 'react-redux'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { useBaseFunction } from '../../../layouts/AppContext'
import AppHelmet from '../../../layouts/AppHelmet/AppHelmet'
import Navbar from '../../../layouts/Navbar'
import Sidebar from '../../../layouts/Sidebar'
import Login from '../../../pages/Auth/Login'
import NoneRole from '../../../pages/Auth/NoneRole'
import { getSidebarItems } from '../../../shared/constant/_sidebar'

export default function MainContainer({ children }) {
  const { auth } = useSelector((state) => state.admin)
  const { language } = useBaseFunction()
  return (
    <HelmetProvider>
      <AppHelmet lang={language} />
      <BrowserRouter>
        <Routes>
          <Route path='/admin/login' element={<Login />} />
          <Route path='/admin/notfound' element={<span>404 notfound</span>} />
          <Route path='/admin/none-role' element={<NoneRole />} />
          <Route
            path='admin/*'
            element={
              <React.Fragment>
                <Sidebar sidebarItems={getSidebarItems()} auth={auth} />
                <div className='wrapper d-flex flex-column min-vh-100 bg-light'>
                  <Navbar />
                  <div className='body flex-grow-1 px-3'>
                    <div className='container-lg'>
                      {auth ? children : <Navigate to='/admin/login' replace />}
                    </div>
                  </div>
                </div>
              </React.Fragment>
            }
          />
          <Route path='*' element={<Navigate to='/admin/login' replace />} />
        </Routes>
      </BrowserRouter>
    </HelmetProvider>
  )
}
