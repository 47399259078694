import { Children, cloneElement, isValidElement, useCallback, useState } from 'react'
import style from './table-input.module.scss'
export default function TableInput({ children, options, validate, placeholder }) {
  const { value, editorCallback } = options
  const [errors, setErrors] = useState('')
  const handleChange = useCallback(
    async (e) => {
      try {
        editorCallback(e.target.value)
        try {
          if (validate) {
            await validate.validate(e.target.value)
            setErrors('')
          }
        } catch (err) {
          setErrors(err.message)
        }
      } catch (err) {}
    },
    [editorCallback, validate]
  )
  const childrenWithProps = useCallback(() => {
    return Children.map(children, (child) => {
      if (isValidElement(child)) {
        const props = {
          value: value,
          className: errors ? 'p-invalid p-dirty' : '',
          placeholder,
        }
        if (child.props.__TYPE && child.props.__TYPE === 'InputNumber') {
          props.onValueChange = handleChange
        } else {
          props.onChange = handleChange
        }
        return cloneElement(child, props)
      }
      return child
    })
  }, [children, value, handleChange, errors, placeholder])
  return (
    <div className={style.tableInput}>
      {childrenWithProps()}
      {!!errors && (
        <small className='p-error' style={{ position: 'absolute', bottom: '-15px', right: '0' }}>
          {errors.replaceAll('{}', placeholder)}
        </small>
      )}
    </div>
  )
}
