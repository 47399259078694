import style from './FilePicker.module.scss'
export default function FilePicker({ name, id, onChange, value }) {
  return (
    <div className={style.filePicker}>
      {value ? (
        <HasFile value={value} onChange={onChange} />
      ) : (
        <>
          <input type='file' hidden name={name} id={id} onChange={onChange} />
          <label htmlFor={id} className='p-button-text w-fit p-0 px-3'>
            <span className='pi pi-paperclip' /> {value?.name || 'Select file'}
          </label>
        </>
      )}
    </div>
  )
}

function HasFile({ value, onChange }) {
  return (
    <div className='px-2 flex gap-1 align-items-center'>
      <a
        href={typeof value === 'string' ? value : undefined}
        className='text-black'
        style={{ textDecoration: 'none' }}
      >
        <span className='pi pi-cloud-download' /> {typeof value === 'string' ? 'Download' : value.name}
      </a>
      <span
        className={style.removeBtn}
        onClick={() => {
          onChange({ target: { files: [null] } })
        }}
      >
        x
      </span>
    </div>
  )
}
