import { Space } from 'antd'
import { Button } from 'primereact/button'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import TableEditButton from '../../components/Templates/Button/TableEditButton'
import TableDeleteButton from '../../components/Templates/Button/TableRemoveButton'
import Table from '../../components/Templates/Table/Table'
import TableCaption from '../../components/Templates/TableCaption'
import { PrivateRoute } from '../../helpers/PrivateRoute'
import { useBaseFunction } from '../../layouts/AppContext'
import { useLoading } from '../../layouts/FullScreenLoading/FullScreenLoading'
import { fetchProgram } from '../../redux/slice/program.slice'
import ProgramSchema from '../../shared/schema/program.schema'
import { deleteProgram } from '../../shared/services/program.service'

export default function ProgramListing() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [table, setTable] = useState({
    first: 0,
    rows: 10,
    page: 1,
    filters: {
      name: { value: '' },
      'university.name': { value: '' },
      'major.name': { value: '' },
      price: { value: '' },
    },
  })
  const { entities, loading: programLoading, totalRecords } = useSelector((state) => state.program)
  const { auth } = useSelector((state) => state.admin)
  const loading = useLoading()
  const base = useBaseFunction()
  const schema = new ProgramSchema(loading, base, auth)
  const columns = [
    {
      name: 'ชื่อโปรแกรม',
      key: 'name',
      width: '30%',
      sortable: true,
    },
    {
      name: 'มหาวิทยาลัย',
      key: 'university.name',
      width: '35%',
      sortable: true,
      render: (record) => <span>{record.university?.name}</span>,
    },
    {
      name: 'สาขาวิชา',
      key: 'major.name',
      width: '20%',
      sortable: true,
      render: (record) => <span>{record.major?.name}</span>,
    },
    {
      name: 'ราคา',
      key: 'price',
      width: '10%',
      sortable: true,
    },
    {
      key: 'action',
      width: '150px',
      render: (record) => (
        <Space size='small'>
          <TableEditButton id={record.id} onClick={onClickEdit} />
          <TableDeleteButton id={record.id} onClick={handleRemove} />
        </Space>
      ),
    },
  ]
  const onClickEdit = (id) => navigate(`/admin/program/edit/${id}`)
  const onClickAdd = () => navigate('/admin/program/new')
  const handleRemove = (id) => {
    schema.setRemoveFunction(async () => {
      const { data } = await deleteProgram(id, auth.token)
      if (data) {
        fetchData()
      }
    })
    schema.onRemove(id)
  }
  const fetchData = useCallback(() => {
    dispatch(fetchProgram(table))
  }, [table, dispatch])
  useEffect(() => {
    fetchData()
  }, [fetchData])
  return (
    <PrivateRoute>
      <TableCaption
        render={
          <Space size='small' className='mb-2'>
            <Button
              className='p-button-secondary'
              icon='pi pi-refresh'
              onClick={fetchData}
              loading={programLoading}
            />
            <Button icon='pi pi-plus' onClick={onClickAdd} />
          </Space>
        }
      />
      <Table
        value={entities}
        totalRecords={totalRecords}
        loading={programLoading}
        table={table}
        setTable={setTable}
        columns={columns}
      />
    </PrivateRoute>
  )
}
