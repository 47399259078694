import { Space } from 'antd'
import { useFormik } from 'formik'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { InputText } from 'primereact/inputtext'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ActionInRow from '../../../components/Templates/ActionInRow'
import Card from '../../../components/Templates/Card'
import Table from '../../../components/Templates/Table/Table'
import TableCaption from '../../../components/Templates/TableCaption'
import TableInput from '../../../components/Templates/TableInput'
import { PrivateRoute } from '../../../helpers/PrivateRoute'
import { useBaseFunction } from '../../../layouts/AppContext'
import { useLoading } from '../../../layouts/FullScreenLoading/FullScreenLoading'
import { fetchPosition } from '../../../redux/slice/position.slice'
import useTable from '../../../shared/hook/useTable'
import PositionSchema from '../../../shared/schema/position.schema'
import { createPosition, deletePosition, updatePosition } from '../../../shared/services/position.service'
import { PositionForm } from './Form/PositionForm'

export default function PositionListing() {
  const { entities, loading: positionLoading, totalRecords } = useSelector((state) => state.position)
  const { auth } = useSelector((state) => state.admin)
  const [visibleDialog, setVisibleDialog] = useState(false)
  const [table, setTable] = useTable({
    nameEn: { value: '' },
    nameTh: { value: '' },
  })
  const dispatch = useDispatch()
  const loading = useLoading()
  const base = useBaseFunction()
  const schema = new PositionSchema(loading, base, auth)
  const columns = [
    {
      key: 'nameTh',
      name: 'ชื่อ (ภาษาไทย)',
      width: '40%',
      sortable: true,
      editor: (options) => (
        <TableInput options={options} validate={schema.nameValidation} placeholder='ชื่อ (ภาษาไทย)'>
          <InputText />
        </TableInput>
      ),
    },
    {
      key: 'nameEn',
      name: 'ชื่อ (ภาษาอังกฤษ)',
      width: '40%',
      sortable: true,
      editor: (options) => (
        <TableInput options={options} validate={schema.nameValidation} placeholder='ชื่อ (ภาษาอังกฤษ)'>
          <InputText />
        </TableInput>
      ),
    },
    {
      key: 'action',
      width: '150px',
      rowEditor: true,
      render: (record, { rowEditor }) => (
        <ActionInRow record={record} rowEditor={rowEditor} handleRemove={handleRemove} />
      ),
    },
  ]
  const formik = useFormik({
    initialValues: schema.defaultValue,
    validationSchema: schema.validation,
    onSubmit: (values) => {
      schema.handleSubmit(values)
    },
  })
  schema.setUpdateFunction(async (...props) => {
    await updatePosition(...props)
    fetchData()
  })
  schema.setCrateFunction(async (...props) => {
    await createPosition(...props)
    hideDialog()
    fetchData()
  })
  schema.setRemoveFunction(async (...props) => {
    const { data } = await deletePosition(...props)
    if (data) {
      fetchData()
    }
  })
  const handleRemove = (id) => {
    schema.onRemove(id, auth.token)
  }

  const hideDialog = () => {
    formik.setErrors({})
    formik.resetForm()
    setVisibleDialog(false)
  }
  const showDialog = () => {
    setVisibleDialog(true)
  }
  const handleRowSave = async (e) => {
    const { newData } = e
    schema.setIdentified(newData.id)
    schema.handleSubmit(newData, false)
  }
  const fetchData = useCallback(() => {
    dispatch(fetchPosition(table))
  }, [dispatch, table])

  useEffect(() => {
    fetchData()
  }, [fetchData])
  return (
    <PrivateRoute>
      <TableCaption
        render={
          <Space size='small' className='mb-2'>
            <Button
              className='p-button-secondary'
              icon='pi pi-refresh'
              onClick={fetchData}
              loading={positionLoading}
            />
            <Button icon='pi pi-plus' onClick={showDialog} />
          </Space>
        }
      />
      <Dialog
        header='เพิ่มข้อมูลตำแหน่งภายในบริษัท'
        draggable={false}
        modal={true}
        visible={visibleDialog}
        style={{ maxWidth: '50vw', minWidth: '450px' }}
        onHide={hideDialog}
      >
        <PositionForm formik={formik} handleClose={hideDialog} />
      </Dialog>
      <Card header='ข้อมูลตำแหน่งภายในบริษัท'>
        <Table
          onRowEditComplete={handleRowSave}
          editMode='row'
          validate={schema.validation}
          dataKey='id'
          value={entities}
          totalRecords={totalRecords}
          loading={positionLoading}
          table={table}
          setTable={setTable}
          columns={columns}
        />
      </Card>
    </PrivateRoute>
  )
}
