import { Image, Space } from 'antd'
import { Button } from 'primereact/button'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import TableEditButton from '../../components/Templates/Button/TableEditButton'
import TableDeleteButton from '../../components/Templates/Button/TableRemoveButton'
import Card from '../../components/Templates/Card'
import Table from '../../components/Templates/Table/Table'
import TableCaption from '../../components/Templates/TableCaption'
import { PrivateRoute } from '../../helpers/PrivateRoute'
import { useBaseFunction } from '../../layouts/AppContext'
import { useLoading } from '../../layouts/FullScreenLoading/FullScreenLoading'
import { fetchUniversity } from '../../redux/slice/university.slice'
import UniversitySchema from '../../shared/schema/university.schema'
import { deleteUniversity } from '../../shared/services/university.service'
export default function UniversityListing() {
  const dispatch = useDispatch()
  const { entities, totalRecords, loading: universityLoading } = useSelector((state) => state.university)
  const loading = useLoading()
  const base = useBaseFunction()
  const navigate = useNavigate()
  const { auth } = useSelector((state) => state.admin)
  const [table, setTable] = useState({
    first: 0,
    rows: 10,
    page: 1,
    sortField: null,
    sortOrder: null,
    filters: {
      name: { value: '' },
      address: { value: '' },
      viewCount: { value: '' },
    },
  })
  const handleRemove = async (universityId) => {
    const university = new UniversitySchema(loading, base, auth)
    university.setRemoveFunction(async () => {
      const { data } = await deleteUniversity(universityId, auth.token)
      if (data) {
        dispatch(fetchUniversity(table))
      }
    })
    university.onRemove(universityId)
  }

  const handleEdit = (universityId) => navigate(`/admin/university/edit/${universityId}`)

  const columns = [
    {
      name: 'รูปภาพ',
      key: 'images',
      width: '250px',
      render: ({ images }) => <Image style={{width:'150px'}} src={images ? images[0] : null} alt='logoPath' />,
    },
    {
      name: 'ชื่อมหาวิทยาลัย',
      key: 'name',
      width: '25%',
      sortable: true,
    },
    {
      name: 'ที่อยู่',
      key: 'address',
      width: '30%',
      sortable: true,
    },
    {
      name: 'ยอดคนดู',
      key: 'viewCount',
      width: '10%',
      align: 'center',
    },
    {
      name: 'โลโก้',
      key: 'logoPath',
      width: '10%',
      render: ({ logoPath }) => <Image src={logoPath} alt='logoPath' />,
    },
    {
      key: 'action',
      width: '150px',
      render: (record) => (
        <Space size='small'>
          <TableEditButton id={record.id} onClick={handleEdit} />
          <TableDeleteButton id={record.id} onClick={handleRemove} />
        </Space>
      ),
    },
  ]

  useEffect(() => {
    dispatch(fetchUniversity(table))
  }, [table, dispatch])
  return (
    <PrivateRoute>
      <TableCaption
        render={
          <Space size='small' className='mb-2'>
            <Button
              className='p-button-secondary'
              icon='pi pi-refresh'
              onClick={() => dispatch(fetchUniversity(table))}
              loading={universityLoading}
            />
            <Button icon='pi pi-plus' onClick={() => navigate('/admin/university/new')} />
          </Space>
        }
      />
      <Card>
        <Table
          value={entities}
          totalRecords={totalRecords}
          loading={universityLoading}
          table={table}
          setTable={setTable}
          columns={columns}
        />
      </Card>
    </PrivateRoute>
  )
}
