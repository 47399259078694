import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

export const storeLanguage = createAsyncThunk('i18n/storeLanguage ', (language) => {
  return language
})

export const i18nSlice = createSlice({
  name: 'i18n',
  initialState: {
    current: 'th',
  },
  extraReducers: (builder) => {
    builder.addCase(storeLanguage.fulfilled, (state, action) => {
      state.current = action.payload
    })
  },
})
