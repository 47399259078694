import { Button } from 'primereact/button'
import { InputNumber } from 'primereact/inputnumber'
import { InputText } from 'primereact/inputtext'
import React, { Fragment } from 'react'

import InlineLabel from '../../../components/Templates/InlineLabel'
import ListOfValue from '../../../components/Templates/ListOfValue/ListOfValue'
export function ProgramForm({ formik }) {
  const aboutInlineStyle = { display: 'flex', justifyContent: 'space-between', gap: '10px' }
  const { errors, touched, handleChange, values, handleSubmit, setFieldValue } = formik

  return (
    <Fragment>
      <form onSubmit={handleSubmit}>
        <InlineLabel errors={errors} touched={touched} label='ชื่อโปรแกรม' name='name'>
          <InputText onChange={handleChange} value={values.name} />
        </InlineLabel>
        <InlineLabel errors={errors} touched={touched} label='สาขาวิชา' name='majorId' required>
          <ListOfValue
            setFieldValue={setFieldValue}
            value={values.majorId}
            callTo='/major/list-of-value'
            displayField='name'
            field='id'
          />
        </InlineLabel>
        <InlineLabel errors={errors} touched={touched} label='ลิงก์' name='link'>
          <InputText onChange={handleChange} value={values.link} />
        </InlineLabel>
        {/* <InlineLabel errors={errors} touched={touched} label='ระดับการศึกษา' name='studyLevelId' required>
          <ListOfValue
            setFieldValue={setFieldValue}
            value={values.studyLevelId}
            callTo='/study-level/list-of-value'
            displayField='name'
            field='id'
          />
        </InlineLabel> */}
        <InlineLabel errors={errors} touched={touched} label='หลักสูตร' name='language' required>
          <ListOfValue
            setFieldValue={setFieldValue}
            value={values.language}
            callTo='/language-type/list-of-value'
            displayField='name'
            field='name'
          />
        </InlineLabel>
        <InlineLabel errors={errors} touched={touched} label='มหาวิทยาลัย' name='universityId' required>
          <ListOfValue
            setFieldValue={setFieldValue}
            value={values.universityId}
            callTo='/university/list-of-value'
            displayField='name'
            field='id'
          />
        </InlineLabel>
        <InlineLabel errors={errors} touched={touched} label='ระยะเวลา (ปี)' name='duration'>
          <div style={aboutInlineStyle}>
            <InputText onChange={handleChange} value={values.duration} name='about2' />

            {/* <span className='d-flex gap-2 w-full'>
              <label> ถึง:</label>
              <InputText onChange={handleChange} value={values.duration2} name='about3' />
            </span> */}
          </div>
        </InlineLabel>
        <InlineLabel errors={errors} touched={touched} label='ราคา' name='price'>
          <InputNumber onValueChange={handleChange} value={values.price} minFractionDigits='2' />
        </InlineLabel>
        <div className='d-flex justify-content-end p-4'>
          <Button label='บันทึก' type='submit' />
        </div>
      </form>
    </Fragment>
  )
}
