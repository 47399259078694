import { Button } from 'primereact/button'
import { Dropdown } from 'primereact/dropdown'
import { InputText } from 'primereact/inputtext'
import InlineLabel from '../../../components/Templates/InlineLabel'
import { APPLICATION_STEPS } from '../../../shared/constant/application'

export default function UserInfomation({ formik }) {
  const { values, handleSubmit, errors, touched, handleChange, handleReset } = formik
  return (
    <form onSubmit={handleSubmit} onReset={handleReset}>
      <InlineLabel label='ผู้ที่ดูแล'>
        <InputText disabled={true} value={values.admin?.fullName} />
      </InlineLabel>
      <InlineLabel label='ขื่อ'>
        <InputText disabled={true} value={values.user.firstName} />
      </InlineLabel>
      <InlineLabel label='นายสกุล'>
        <InputText disabled={true} value={values.user.lastName} />
      </InlineLabel>
      <InlineLabel label='อีเมล'>
        <InputText disabled={true} value={values.user.email} />
      </InlineLabel>
      <InlineLabel label='ขั้นตอนที่อยู่' errors={errors} touched={touched} name='currentStep'>
        <Dropdown
          onChange={handleChange}
          value={values.currentStep}
          options={APPLICATION_STEPS}
          optionLabel='label'
        />
      </InlineLabel>
      <InlineLabel label='CASC ID' errors={errors} touched={touched} name='cascId'>
        <InputText value={values.cascId} onChange={handleChange} />
      </InlineLabel>
      <InlineLabel label='หมายเลขโทรศัพท์ที่สามารถติดต่อได้'>
        <ol className='m-0 pl-3'>
          {values.phone.map((i) => (
            <li key={i.phone}>
              {i.name} ({i.relation}) {i.phone}
            </li>
          ))}
        </ol>
      </InlineLabel>
      <div className='flex justify-content-end m-3 gap-2'>
        <Button type='reset' label='ยกเลิก' className='p-button-secondary' />
        <Button type='submit' label='บันทึก' />
      </div>
    </form>
  )
}
