import { object, string } from 'yup'
import { SchemaTemplate } from './template.schema'

export default class StudyLevelSchema extends SchemaTemplate {
  nameValidation = string().required('ต้องระบุ {}!')
  validation = object().shape({
    name: this.nameValidation,
  })

  defaultValue = {
    name: '',
  }
}
