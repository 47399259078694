export const CONFIRM_SAVE = {
  title: 'ยืนยันการบันทึกข้อมูลหรือไม่?',
  text: 'ข้อมูลจะถูกบันทึกลงฐานข้อมูล!',
  icon: 'warning',
  showCancelButton: true,
  confirmButtonText: 'ยืนยัน',
  cancelButtonText: 'ยกเลิก',
}
export const CONFIRM_REMOVE = {
  title: 'ยืนยันการลบข้อมูลหรือไม่?',
  text: 'ข้อมูลจะไม่สามารถกู้คืนได้!',
  icon: 'warning',
  showCancelButton: true,
  confirmButtonText: 'ยืนยัน',
  cancelButtonText: 'ยกเลิก',
}
