import React from 'react'
import { Space } from 'antd'
import TableCancelButton from '../Button/TableCancelButton'
import TableEditButton from '../Button/TableEditButton'
import TableDeleteButton from '../Button/TableRemoveButton'
import TableSaveButton from '../Button/TableSaveButton'

export default function ActionInRow({ record, rowEditor, handleRemove }) {
  const { onInitClick, editing, onCancelClick, onSaveClick } = rowEditor
  const handleRowSave = (id) => {
    onSaveClick({ id, preventDefault: () => {} })
  }
  return editing ? (
    <Space size='small'>
      <TableSaveButton id={record.id} onClick={handleRowSave} />
      <TableCancelButton id={record.id} onClick={onCancelClick} />
    </Space>
  ) : (
    <Space size='small'>
      <TableEditButton id={record.id} onClick={() => onInitClick({ preventDefault: () => {} })} />
      <TableDeleteButton id={record.id} onClick={handleRemove} />
    </Space>
  )
}
