import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { AppContextPath } from '../../shared/constant/_environment'
import FilterUtils from '../../utils/filter.utils'

export const fetchProgram = createAsyncThunk(
  'program/fetchProgram',
  async ({ first: offset = 0, rows: limit = 10, filters }, { getState }) => {
    const filterParam = FilterUtils.getParams(filters)
    const response = await axios({
      method: 'GET',
      url: `${AppContextPath.ADMIN_URL}/program/list`,
      headers: {
        Authorization: 'Bearer ' + getState().admin.auth.token,
      },
      params: { offset, limit, ...filterParam },
    })
    return response.data
  }
)

export const fetchProgramSingle = createAsyncThunk(
  'program/fetchProgramSingle',
  async (programId, { getState }) => {
    const response = await axios({
      method: 'GET',
      url: `${AppContextPath.ADMIN_URL}/program/single/${programId}`,
      headers: {
        Authorization: 'Bearer ' + getState().admin.auth.token,
      },
    })
    return response.data
  }
)

export const programSlice = createSlice({
  name: 'program',
  initialState: {
    entities: [],
    totalRecords: 0,
    loading: false,
    error: null,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProgram.pending, (state) => {
      state.loading = true
    })
    builder.addCase(fetchProgram.fulfilled, (state, action) => {
      state.entities = action.payload.rows
      state.totalRecords = action.payload.count
      state.loading = false
    })
    builder.addCase(fetchProgram.rejected, (state) => {
      state.entities = []
      state.totalRecords = 0
      state.loading = false
    })
    builder.addCase(fetchProgramSingle.pending, (state) => {
      state.loading = true
    })
    builder.addCase(fetchProgramSingle.fulfilled, (state, action) => {
      state.current = action.payload
      state.loading = false
    })
    builder.addCase(fetchProgramSingle.rejected, (state) => {
      state.loading = false
      state.current = {}
    })
  },
})
