import { t } from 'i18next'
import { useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
export default function Sidebar({ sidebarItems = [], auth }) {
  const { currentRole } = useSelector((state) => state.admin)
  if (!auth) {
    return null
  }
  const filterByRoles = (sidebar) =>
    auth.role === 'superadmin'
      ? true
      : (currentRole?.pageNames ?? []).includes(sidebar.name) || sidebar.isTitle
  const filterTitle = (sidebar, index, curr) =>
    sidebar.isTitle && ((curr[index + 1] && curr[index + 1].isTitle) || !curr[index + 1]) ? false : true
  return (
    <div className='sidebar sidebar-dark sidebar-fixed' id='sidebar'>
      <div className='sidebar-brand d-none d-md-flex'>
        <span className='sidebar-brand-full text-center'>
          <div>CASC | Admin</div>
          {auth && <small>{auth.fullName}</small>}
        </span>
        <span className='sidebar-brand-narrow'>CASC</span>
      </div>
      <ul className='sidebar-nav' data-coreui='navigation'>
        {sidebarItems
          .filter(filterByRoles)
          .filter(filterTitle)
          .map((sidebar, key) =>
            sidebar.isTitle ? (
              <li className='nav-title' key={key}>
                {sidebar.name}
              </li>
            ) : (
              <li className='nav-item' key={key}>
                <NavLink to={sidebar.path} className={({ isActive }) => `nav-link ${isActive && 'active'}`}>
                  {sidebar.icon}
                  {t(`menu.${sidebar.name}`)}
                </NavLink>
              </li>
            )
          )}
      </ul>
    </div>
  )
}
