import { DateTime } from 'luxon'
import { Calendar } from 'primereact/calendar'
export default function AppCalendar(props) {
  return (
    <Calendar
      {...props}
      dateFormat='dd MM yy'
      value={typeof props.value === 'string' ? DateTime.fromISO(props.value).toJSDate() : props.value}
    />
  )
}
