import { EditOutlined } from '@ant-design/icons'
import { Button, Tooltip } from 'antd'
import React from 'react'
export default function TableEditButton({ id, tooltip = 'แก้ไข', onClick }) {
  return (
    <Tooltip title={tooltip}>
      <Button type='link' shape='circle' onClick={(e) => onClick(id)} icon={<EditOutlined />} />
    </Tooltip>
  )
}
