import { useDispatch, useSelector } from 'react-redux'
import { logout } from '../../redux/slice/admin.slice'
import style from './non-role.module.scss'
export default function NoneRole() {
  const { auth } = useSelector((state) => state.admin)
  const dispatch = useDispatch()
  const logoutClick = () => {
    dispatch(logout())
  }
  if (!auth) {
    window.location.href = '/admin/login'
  }
  return (
    <div className={`flex align-items-center justify-content-center text-center bg-light flex-column gap-4 ${style.container}`}>
      <img src='/assets/undraw_landscape_photographer_156c.svg' alt='background' />
      <h3>
        ยังไม่ได้รับบทบาท โปรดติดต่อผู้ดูแลระบบ
        <div>
          <a href='#' onClick={logoutClick} style={{ fontSize: '20px' }}>
            ออกจากระบบ
          </a>
        </div>
      </h3>
    </div>
  )
}
