import { number, object, string } from 'yup'
import FormUtils from '../../utils/form.utils'
import { SchemaTemplate } from './template.schema'

export default class ScoreUniversitySchema extends SchemaTemplate {
  scoreValidation = string().uuid().nullable(true).required('โปรดระบุ {}!')
  studyLevelValidation = string().uuid().nullable(true).required('โปรดระบุ {}!')
  pointValidation = number().nullable(true).transform(FormUtils.yup.transformNumber)
  validation = object().shape({
    point: this.pointValidation,
    scoreId: this.scoreValidation,
    studyLevelId: this.studyLevelValidation,
  })

  defaultValue = {
    scoreId: null,
    studyLevelId: null,
    point: '',
  }
}
