import React from 'react'
import style from './preview-upload.module.scss'
import { Image } from 'primereact/image'
import { Avatar } from 'primereact/avatar'
export default function PreviewUpload({ upload, size }) {
  switch (upload.uploadFileType?.toLowerCase()) {
    case 'png':
    case 'jpg':
    case 'jpeg':
      return (
        <Image
          width='40px'
          height='40px'
          className={style.image}
          src={`${process.env.REACT_APP_API_URL}/admin/redirect/assets?bucket=applications&uploadPath=${upload.uploadPath}`}
        />
      )
    case 'docx':
      return (
        <Avatar shape='square' size={size} className={style.avatar} image='/assets/images/docx-icon.svg' />
      )
    case 'pdf':
      return (
        <Avatar shape='square' className={style.avatar} size={size} image='/assets/images/pdf-icon.svg' />
      )
    default:
      return (
        <Avatar shape='square' size={size} className={style.avatar} image='/assets/images/file-icon.svg' />
      )
  }
}
