import axios from 'axios'
import { AppContextPath } from '../constant/_environment'
export const deleteCost = async (costId, token) => {
  return await axios.delete(`${AppContextPath.ADMIN_URL}/cost/delete/${costId}`, {
    headers: { Authorization: `Bearer ${token}` },
  })
}

export const updateCost = async (payload, costId, token) => {
  return await axios.put(`${AppContextPath.ADMIN_URL}/cost/update/${costId}`, payload, {
    headers: { Authorization: `Bearer ${token}` },
  })
}

export const createCost = async (payload, token) => {
  return await axios.post(`${AppContextPath.ADMIN_URL}/cost/new`, payload, {
    headers: { Authorization: `Bearer ${token}` },
  })
}
