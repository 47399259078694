import { Empty, Space } from 'antd'
import { ProgressSpinner } from 'primereact/progressspinner'
import React, { Fragment } from 'react'
import style from './loading.module.scss'
const Loading = () => (
  <Fragment>
    <div className={style['loading-overlay']}>
      <Space size='middle'>
        <Empty />
        <ProgressSpinner size='large' className={style.spinner} />
      </Space>
    </div>
  </Fragment>
)
export default Loading
