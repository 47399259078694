import { AutoComplete } from 'primereact/autocomplete'
import React, { useEffect, useState, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useBaseFunction } from '../../../layouts/AppContext'
import { callToApi } from '../../../shared/services/list-of-value.service'
export default function ListOfValue({
  callTo,
  field,
  displayField,
  setFieldValue = () => {},
  parameters,
  name,
  value,
  className,
  onChange = () => {},
}) {
  const { auth } = useSelector((state) => state.admin)
  const [filtered, setFiltered] = useState([])
  const [search, setSearch] = useState()
  const { errorToast } = useBaseFunction()
  const [suggest, setSuggest] = useState()
  const searchItem = async (event) => {
    try {
      const response = await callToApi(callTo, parameters, auth.token)
      const { data } = response
      setSuggest(data)
      let _filtered = []
      if (!event.query.trim().length) {
        _filtered = [...data]
      } else {
        _filtered = data.filter((suggest) => {
          return suggest[displayField].toLowerCase().startsWith(event.query.toLowerCase())
        })
      }
      setFiltered(_filtered)
    } catch (err) {
      errorToast(`List failed! ${err.message}`)
    }
  }
  const initialDefault = useCallback(async () => {
    try {
      if (value) {
        const response = await callToApi(callTo, parameters, auth.token)
        const { data } = response
        setSuggest(data)
        const defaultValue = data.find((v) => v[field] === value)
        setSearch(defaultValue && defaultValue[displayField])
      }
    } catch (err) {
      errorToast(`List failed! ${err.message}`)
    }
  }, [displayField, field, value, auth.token, parameters, callTo, errorToast])
  useEffect(() => {
    initialDefault()
  }, [initialDefault])
  useEffect(() => {
    if (!search) {
      setFieldValue(name, null)
    }
  }, [search, setFieldValue, name])

  useEffect(() => {
    if (!search) {
      onChange({ target: { value: null } })
    }
  }, [search, onChange])
  const handleChange = (event) => {
    if (event.value) {
      setSearch(event.value)
    }
  }
  const handleSelect = (event) => {
    setSearch(event.value[displayField])
    if (setFieldValue) {
      setFieldValue(name, event.value[field])
    }
    onChange({ target: { value: event.value[field] } })
  }
  return (
    <AutoComplete
      value={search}
      suggestions={filtered}
      completeMethod={searchItem}
      dropdown
      field={displayField}
      className={className}
      itemTemplate={(item) => <ItemTemplate displayField={displayField} item={item} />}
      emptyMessage='ไม่พบข้อมูล'
      onBlur={(e) => {
        const { value } = e.target
        const found = suggest && suggest.find((v) => v[displayField] === value)
        if (!found) {
          setFieldValue(field, null)
          setSearch(null)
        }
      }}
      onSelect={handleSelect}
      onChange={handleChange}
      showEmptyMessage={true}
    />
  )
}

const ItemTemplate = ({ displayField, item }) => {
  return <div>{item[displayField]}</div>
}
