import { useFormik } from 'formik'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router'
import Card from '../../../components/Templates/Card'
import { PrivateRoute } from '../../../helpers/PrivateRoute'
import { useBaseFunction } from '../../../layouts/AppContext'
import { useLoading } from '../../../layouts/FullScreenLoading/FullScreenLoading'
import { fetchAdminSingle } from '../../../redux/slice/management.slice'
import AdminSchema from '../../../shared/schema/admin.schema'
import { createAdmin, updateAdmin } from '../../../shared/services/admin.service'
import * as ObjectUtils from '../../../utils/object.utils'
import AdminForm from './Form/AdminForm'

export default function AdminSingle({ isEdit }) {
  const { adminId } = useParams()
  const { auth } = useSelector((state) => state.admin)
  const base = useBaseFunction()
  const loading = useLoading()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const schema = useMemo(() => new AdminSchema(loading, base, auth), [loading, base, auth])
  const { current: admin, adminLoading } = useSelector((state) => state.management)
  const [initialValues, setInitialValues] = useState(schema.defaultValue)
  const onSubmit = (values) => {
    schema.handleSubmit(values)
  }
  schema.setIdentified(adminId)
  schema.setCrateFunction(async (...props) => {
    const { data } = await createAdmin(...props)
    navigate(`/admin/management/edit/${data.id}`, { replace: true })
  })
  schema.setUpdateFunction(updateAdmin)
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: isEdit ? schema.editModeValidation : schema.validation,
    onSubmit: onSubmit,
    enableReinitialize: true,
  })
  useEffect(() => {
    if (isEdit && ObjectUtils.isNotEmpty(admin)) {
      setInitialValues(ObjectUtils.copy(schema.defaultValue, admin))
    }
  }, [admin, isEdit, schema])
  useEffect(() => {
    if (isEdit) {
      dispatch(fetchAdminSingle(adminId))
    }
  }, [dispatch, adminId, isEdit])
  return (
    <PrivateRoute>
      <Card header={isEdit ? 'แก้ไขข้อมูลแอดมิน' : 'เพิ่มข้อมูลแอดมิน'} loading={adminLoading}>
        <AdminForm formik={formik} isEdit={isEdit} />
      </Card>
    </PrivateRoute>
  )
}
