import { useFormik } from 'formik'
import { Dialog } from 'primereact/dialog'
import { InputText } from 'primereact/inputtext'
import { InputTextarea } from 'primereact/inputtextarea'
import { Fragment, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ActionInRow from '../../components/Templates/ActionInRow'
import Table from '../../components/Templates/Table/Table'
import TableInput from '../../components/Templates/TableInput'
import { useBaseFunction } from '../../layouts/AppContext'
import { useLoading } from '../../layouts/FullScreenLoading/FullScreenLoading'
import { fetchScholarship } from '../../redux/slice/scholarship.slice'
import ScholarshipSchema from '../../shared/schema/scholarship.schema'
import {
  createScholarship,
  deleteScholarship,
  updateScholarship,
} from '../../shared/services/scholarship.service'
import { ScholarshipForm } from './Form/ScholarshipForm'

export default function ScholarshipListing({ universityId, visibleDialog, hideDialog }) {
  const dispatch = useDispatch()
  const { entities, totalRecords, loading: scholarshipLoading } = useSelector((state) => state.scholarship)
  const base = useBaseFunction()
  const { auth } = useSelector((state) => state.admin)
  const loading = useLoading()
  const schema = new ScholarshipSchema(loading, base, auth)
  const [table, setTable] = useState({
    first: 0,
    rows: 10,
    page: 1,
    sortField: null,
    sortOrder: null,
    filters: {
      name: { value: '' },
      desc: { value: '' },
      link: { value: '' },
    },
  })
  const fetchData = useCallback(() => {
    dispatch(
      fetchScholarship({
        ...table,
        universityId,
      })
    )
  }, [table, universityId, dispatch])
  useEffect(() => {
    fetchData()
  }, [fetchData])
  schema.setUpdateFunction(async (...props) => {
    await updateScholarship(...props)
    fetchData()
  })
  schema.setCrateFunction(async (...props) => {
    await createScholarship(...props)
    handleClose()
    fetchData()
  })
  schema.setRemoveFunction(async (...props) => {
    const { data } = await deleteScholarship(...props)
    if (data) {
      fetchData()
    }
  })

  const handleRemove = (scholarshipId) => {
    schema.onRemove(scholarshipId, auth.token)
  }
  const columns = [
    {
      name: 'ทุนการศึกษา',
      key: 'name',
      width: '20%',
      align: 'left',
      sortable: true,
      editor: (options) => (
        <TableInput options={options} validate={schema.nameValidation} placeholder='ทุนการศึกษา'>
          <InputText />
        </TableInput>
      ),
    },
    {
      name: 'รายละเอียดทุน',
      key: 'desc',
      width: '50%',
      align: 'left',
      sortable: true,
      editor: (options) => (
        <TableInput options={options} validate={schema.descValidation} placeholder='รายละเอียดทุน'>
          <InputTextarea autoResize />
        </TableInput>
      ),
    },
    {
      name: 'ลิงก์',
      key: 'link',
      width: '20%',
      align: 'left',
      sortable: true,
      editor: (options) => (
        <TableInput options={options} validate={schema.linkValidation}>
          <InputText type='url' placeholder='ลิงก์' />
        </TableInput>
      ),
    },
    {
      key: 'action',
      width: '150px',
      rowEditor: true,
      render: (record, { rowEditor }) => (
        <ActionInRow record={record} rowEditor={rowEditor} handleRemove={handleRemove} />
      ),
    },
  ]
  const formik = useFormik({
    initialValues: schema.defaultValue,
    validationSchema: schema.validation,
    onSubmit: (values) => {
      schema.handleSubmit({
        ...values,
        universityId,
      })
    },
  })
  const handleRowSave = async (e) => {
    const { newData } = e
    schema.setIdentified(newData.id)
    schema.handleSubmit(newData, false)
  }
  const handleClose = () => {
    hideDialog()
    formik.resetForm(schema.defaultValue)
    formik.setErrors({})
  }
  return (
    <Fragment>
      <Dialog
        header='เพิ่มข้อมูลทุนการศึกษา'
        draggable={false}
        modal={true}
        visible={visibleDialog}
        style={{ maxWidth: '50vw', minWidth: '450px' }}
        onHide={hideDialog}
      >
        <ScholarshipForm formik={formik} handleClose={handleClose} />
      </Dialog>
      <Table
        onRowEditComplete={handleRowSave}
        editMode='row'
        validate={schema.validation}
        dataKey='id'
        value={entities}
        totalRecords={totalRecords}
        loading={scholarshipLoading}
        table={table}
        setTable={setTable}
        columns={columns}
      />
    </Fragment>
  )
}
