import { object, string } from 'yup'
import { SchemaTemplate } from './template.schema'

export default class PositionSchema extends SchemaTemplate {
  nameValidation = string().required('โปรดระบุ {}!')
  validation = object().shape({
    nameEn: this.nameValidation,
    nameTh: this.nameValidation,
  })

  defaultValue = {
    nameEn: '',
    nameTh: '',
  }
}
