import axios from 'axios'
import { AppContextPath } from '../constant/_environment'
export const deleteProgramUpload = async (programUploadId, token) => {
  return await axios.delete(`${AppContextPath.ADMIN_URL}/program-upload/delete/${programUploadId}`, {
    headers: { Authorization: `Bearer ${token}` },
  })
}

export const updateProgramUpload = async (payload, programUploadId, token) => {
  return await axios.put(
    `${AppContextPath.ADMIN_URL}/program-upload/update/${programUploadId}`,
    payload,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  )
}

export const createProgramUpload = async (payload, token) => {
  console.log(`createpaload ${payload}`)
  return await axios.post(`${AppContextPath.ADMIN_URL}/program-upload/new`, payload, {
    headers: { Authorization: `Bearer ${token}` },
  })
}
