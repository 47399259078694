import axios from 'axios'
import { AppContextPath } from '../constant/_environment'
export const deletePosition = async (positionId, token) => {
  return await axios.delete(`${AppContextPath.ADMIN_URL}/position/delete/${positionId}`, {
    headers: { Authorization: `Bearer ${token}` },
  })
}

export const updatePosition = async (payload, positionId, token) => {
  return await axios.put(`${AppContextPath.ADMIN_URL}/position/update/${positionId}`, payload, {
    headers: { Authorization: `Bearer ${token}` },
  })
}

export const createPosition = async (payload, token) => {
  return await axios.post(`${AppContextPath.ADMIN_URL}/position/new`, payload, {
    headers: { Authorization: `Bearer ${token}` },
  })
}
