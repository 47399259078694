export const ROLE = [
  {
    label: 'Super Admin',
    value: 'superadmin',
  },
  {
    label: 'Admin',
    value: 'admin',
  },
  {
    label: 'Group leader',
    value: 'groupleader',
  },
  {
    label: 'Management',
    value: 'management',
  },
]
