import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { AppContextPath } from '../../shared/constant/_environment'
import FilterUtils from '../../utils/filter.utils'

export const fetchScoreCategory = createAsyncThunk(
  'score-category/fetchScoreCategory',
  async ({ first: offset = 0, rows: limit = 10, filters }, { getState }) => {
    const filterParam = FilterUtils.getParams(filters)
    const response = await axios({
      method: 'GET',
      url: `${AppContextPath.ADMIN_URL}/score-category/list`,
      headers: {
        Authorization: 'Bearer ' + getState().admin.auth.token,
      },
      params: { offset, limit, ...filterParam },
    })
    return response.data
  }
)

export const scoreCategorySlice = createSlice({
  name: 'scoreCategory',
  initialState: {
    entities: [],
    totalRecords: 0,
    loading: false,
    error: null,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchScoreCategory.pending, (state) => {
      state.loading = true
    })
    builder.addCase(fetchScoreCategory.fulfilled, (state, action) => {
      state.entities = action.payload.rows
      state.totalRecords = action.payload.count
      state.loading = false
    })
    builder.addCase(fetchScoreCategory.rejected, (state) => {
      state.entities = []
      state.totalRecords = []
      state.loading = false
    })
  },
})
