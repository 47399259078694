import { object, string } from 'yup'
import { SchemaTemplate } from './template.schema'
export default class AdminSchema extends SchemaTemplate {
  validation = object().shape({
    firstName: string().required('โปรดระบุ {}!'),
    lastName: string().required('โปรดระบุ {}!'),
    email: string().required('โปรดระบุ {}!'),
    password: string().required('โปรดระบุ {}!'),
  })
  editModeValidation = object().shape({
    firstName: string().required('โปรดระบุ {}!'),
    lastName: string().required('โปรดระบุ {}!'),
    email: string().required('โปรดระบุ {}!'),
  })

  defaultValue = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    role: '',
    isSuperAdmin: false,
    roles: [],
    positionId: null,
  }
}
