import axios from 'axios'
import { AppContextPath } from '../constant/_environment'
export const deleteProduct = async (productId, token) => {
  return await axios.delete(`${AppContextPath.ADMIN_URL}/product/delete/${productId}`, {
    headers: { Authorization: `Bearer ${token}` },
  })
}

export const updateProduct = async (payload, productId, token) => {
  return await axios.put(`${AppContextPath.ADMIN_URL}/product/update/${productId}`, payload, {
    headers: { Authorization: `Bearer ${token}` },
  })
}

export const createProduct = async (payload, token) => {
  return await axios.post(`${AppContextPath.ADMIN_URL}/product/new`, payload, {
    headers: { Authorization: `Bearer ${token}` },
  })
}
