import { SchemaTemplate } from './template.schema'
import { string, object, array } from 'yup'
export default class MajorSchema extends SchemaTemplate {
  nameValidation = string().required('ต้องระบุ {}!')
  categories = array()
  validation = object().shape({
    name: this.nameValidation,
    major_categories: this.categories,
  })

  defaultValue = {
    name: '',
    categories: [],
  }
}
