import { useFormik } from 'formik'
import { Dialog } from 'primereact/dialog'
import { Fragment, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'primereact/button'
import InlineLabel from '../../components/Templates/InlineLabel'
import ActionInRow from '../../components/Templates/ActionInRow'
import ListOfValue from '../../components/Templates/ListOfValue/ListOfValue'
import Table from '../../components/Templates/Table/Table'
import TableInput from '../../components/Templates/TableInput'
import { useBaseFunction } from '../../layouts/AppContext'
import { useLoading } from '../../layouts/FullScreenLoading/FullScreenLoading'
import { fetchProgramUpload } from '../../redux/slice/program-upload.slice'
import ProgramUploadSchema from '../../shared/schema/program-upload.schema'
import {
  createProgramUpload,
  deleteProgramUpload,
  updateProgramUpload,
} from '../../shared/services/program-upload.service'

export default function ProgramUploadListing({ programId, visibleDialog, hideDialog }) {
  const dispatch = useDispatch()
  const {
    entities,
    totalRecords,
    loading: programUploadLoading,
  } = useSelector((state) => state.programUpload)
  const base = useBaseFunction()
  const { auth } = useSelector((state) => state.admin)
  const loading = useLoading()
  const schema = new ProgramUploadSchema(loading, base, auth)
  const [table, setTable] = useState({
    first: 0,
    rows: 10,
    page: 1,
    sortField: null,
    sortOrder: null,
    filters: {
      // name: { value: '' },
      // desc: { value: '' },
      // link: { value: '' },
    },
  })
  const fetchData = useCallback(() => {
    dispatch(
      fetchProgramUpload({
        ...table,
        programId,
      })
    )
  }, [table, programId, dispatch])
  useEffect(() => {
    fetchData()
  }, [fetchData])
  schema.setUpdateFunction(async (...props) => {
    await updateProgramUpload(...props)
    fetchData()
  })
  schema.setCrateFunction(async (...props) => {
    await createProgramUpload(...props)
    handleClose()
    fetchData()
  })
  schema.setRemoveFunction(async (...props) => {
    const { data } = await deleteProgramUpload(...props)
    if (data) {
      fetchData()
    }
  })

  const handleRemove = (scholarshipId) => {
    schema.onRemove(scholarshipId, auth.token)
  }
  const columns = [
    {
      name: 'ชื่อเอกสาร',
      key: 'uploadTypeId',
      width: '40%',
      align: 'left',
      editor: (options) => (
        <TableInput options={options} validate={schema.nameValidation} placeholder='ชื่อเอกสาร'>
          <ListOfValue callTo='/upload-type/list-of-value' name='name' displayField='name' field='id' />
        </TableInput>
      ),
      render: (record) => <span>{record.uploadType.name}</span>,
    },
    {
      name: 'ประเภทเอกสาร',
      key: 'uploadType.uploadType',
      width: '40%',
      align: 'left',
      render: (record) => <span>{record.uploadType.uploadType}</span>,
    },
    {
      key: 'action',
      width: '150px',
      rowEditor: true,
      render: (record, { rowEditor }) => (
        <ActionInRow record={record} rowEditor={rowEditor} handleRemove={handleRemove} />
      ),
    },
  ]
  const formik = useFormik({
    initialValues: schema.defaultValue,
    validationSchema: schema.validation,
    onSubmit: (values) => {
      values.programID = programId;
      schema.handleSubmit({
        values,
        programId,
      })
    },
  })
  const handleRowSave = async (e) => {
    const { newData } = e
    schema.setIdentified(newData.id)
    schema.handleSubmit(newData, false)
  }
  const handleClose = () => {
    hideDialog()
    formik.resetForm(schema.defaultValue)
    formik.setErrors({})
  }
  return (
    <Fragment>
      <Dialog
        header='เพิ่มเอกสารอัพโหลด'
        draggable={false}
        modal={true}
        visible={visibleDialog}
        style={{ maxWidth: '50vw', minWidth: '450px' }}
        onHide={hideDialog}
      >
        <form onSubmit={formik.handleSubmit}>
          <InlineLabel
            errors={formik.errors}
            touched={formik.touched}
            label='ชื่อเอกสาร'
            name='uploadTypeId'            
          >
            <ListOfValue
               setFieldValue={formik.setFieldValue}
              value={formik.values.id}
              callTo='/upload-type/list-of-value'
              displayField='name'
              field='id'
            />
          </InlineLabel>
          <div className='d-flex justify-content-end pt-3 gap-2'>
            <Button type='reset' label='ยกเลิก' className='p-button-secondary' onClick={handleClose} />
            <Button type='submit' label='บันทึก' />
          </div>

        </form>
        {/* <ScoreUniversityForm formik={formik} handleClose={handleClose} /> */}
      </Dialog>
      <Table
        onRowEditComplete={handleRowSave}
        editMode='row'
        // validate={schema.validation}
        dataKey='id'
        value={entities}
        totalRecords={totalRecords}
        loading={programUploadLoading}
        table={table}
        setTable={setTable}
        columns={columns}
      />
    </Fragment>
  )
}
