import { MenuOutlined } from '@ant-design/icons'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { logout } from '../../redux/slice/admin.slice'
import { useBaseFunction } from '../AppContext'
import Breadcrumbs from '../Breadcrumbs'
export default function Navbar() {
  const toggleSidebar = () => window.sidebarToggle()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { language, role } = useBaseFunction()
  const { auth, currentRole } = useSelector((state) => state.admin)
  const handleLogout = () => {
    dispatch(logout())
    navigate('/')
  }
  const selectRoles = () => {
    role.roleDialog.open()
  }
  return (
    <header className='header header-sticky mb-4'>
      <div className='container-fluid'>
        <button className='header-toggler px-md-0 me-md-3' type='button' onClick={toggleSidebar}>
          <MenuOutlined />
        </button>
        <a className='header-brand d-md-none' href='#'>
          <svg width='118' height='46' alt='CoreUI Logo'>
            <use xlinkHref='assets/brand/coreui.svg#full'></use>
          </svg>
        </a>
        <div className='d-flex'>
          <ul className='header-nav ms-3'>
            <li className='nav-item dropdown d-flex align-items-center'>
              <a
                className='nav-link py-0'
                data-coreui-toggle='dropdown'
                role='button'
                aria-haspopup='true'
                aria-expanded='false'
              >
                <h5 className='mb-0'>{language.value?.toUpperCase()}</h5>
              </a>
              <div className='dropdown-menu'>
                <a className='dropdown-item' onClick={() => language.changeLanguage('th')}>
                  TH
                </a>
                <a className='dropdown-item' onClick={() => language.changeLanguage('en')}>
                  EN
                </a>
              </div>
            </li>
          </ul>
          <ul className='header-nav ms-3'>
            <li className='nav-item dropdown'>
              <a
                className='nav-link py-0'
                data-coreui-toggle='dropdown'
                href='#'
                role='button'
                aria-haspopup='true'
                aria-expanded='false'
              >
                <div className='avatar avatar-md'>
                  <img
                    className='avatar-img'
                    src='https://cdn-icons-png.flaticon.com/512/147/147144.png'
                    alt='user@email.com'
                  />
                </div>
              </a>
              <div className='dropdown-menu dropdown-menu-end pt-0'>
                <div className='dropdown-header bg-light py-2'>
                  <div className='fw-semibold'>
                    บทบาท: {auth?.role === 'superadmin' ? 'ผู้ดูแลระบบ' : currentRole?.name}
                  </div>
                </div>
                {/* <a className='dropdown-item' href='#'>
                  Updates<span className='badge badge-sm bg-info ms-2'>42</span>
                </a> */}
                {auth?.role !== 'superadmin' && (
                  <a className='dropdown-item' onClick={selectRoles}>
                    เปลี่ยนบทบาท
                  </a>
                )}
                <a className='dropdown-item' onClick={handleLogout}>
                  ออกจากระบบ
                </a>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div className='header-divider'></div>
      <div className='container-fluid'>
        <Breadcrumbs />
      </div>
    </header>
  )
}
