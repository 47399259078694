import { number, object, string } from 'yup'
import FormUtils from '../../utils/form.utils'
import { SchemaTemplate } from './template.schema'

export default class CostSchema extends SchemaTemplate {
  nameValidation = string().required('โปรดระบุ {}!')
  value1Validation = number().required('โปรดระบุ {}!').nullable(true).transform(FormUtils.yup.transformNumber)
  value2Validation = number().nullable(true).transform(FormUtils.yup.transformNumber)
  validation = object().shape({
    name: this.nameValidation,
    value1: this.value1Validation,
    value2: this.value2Validation,
  })

  defaultValue = {
    name: '',
    value1: null,
    value2: null,
    filled: false,
  }
}
