import axios from 'axios'
import { AppContextPath } from '../constant/_environment'
export const deleteRole = async (roleId, token) => {
  return await axios.delete(`${AppContextPath.ADMIN_URL}/role/delete/${roleId}`, {
    headers: { Authorization: `Bearer ${token}` },
  })
}

export const updateRole = async (payload, roleId, token) => {
  return await axios.put(`${AppContextPath.ADMIN_URL}/role/update/${roleId}`, payload, {
    headers: { Authorization: `Bearer ${token}` },
  })
}

export const createRole = async (payload, token) => {
  return await axios.post(`${AppContextPath.ADMIN_URL}/role/new`, payload, {
    headers: { Authorization: `Bearer ${token}` },
  })
}
