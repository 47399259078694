// STEP 1
export const CASC_APPLICATION = 'CASC_APPLICATION'
export const MEMBERSHIP_FEE_SLIP = 'MEMBERSHIP_FEE_SLIP'
// STEP 2
export const PASSPORT = 'PASSPORT'
export const TRANSCRIPT = 'TRANSCRIPT'
export const RECOMMENDATION_LETTER_1 = 'RECOMMENDATION_LETTER_1'
export const RECOMMENDATION_LETTER_2 = 'RECOMMENDATION_LETTER_2'
export const HSK = 'HSK'
export const ADDITIONAL = 'ADDITIONAL'
// STEP 3
export const ADMISSION_LETTER = 'ADMISSION_LETTER'
// STEP 4
export const SERVICE_FEE_SLIP = 'SERVICE_FEE_SLIP'
export const VIP_FEE_SLIP = "VIP_FEE_SLIP"
// STEP 5
export const JW202 = 'JW202'
// STEP 6
export const VISA_PLANE_SLIP = 'VISA_PLANE_SLIP'
// STEP 7
export const VISA = 'VISA'
export const PLANE_TICKET = 'PLANE_TICKET'
