import { Space } from 'antd'
import { useFormik } from 'formik'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { InputText } from 'primereact/inputtext'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ActionInRow from '../../components/Templates/ActionInRow'
import Card from '../../components/Templates/Card'
import InlineLabel from '../../components/Templates/InlineLabel'
import Table from '../../components/Templates/Table/Table'
import TableCaption from '../../components/Templates/TableCaption'
import TableInput from '../../components/Templates/TableInput'
import { PrivateRoute } from '../../helpers/PrivateRoute'
import { useBaseFunction } from '../../layouts/AppContext'
import { useLoading } from '../../layouts/FullScreenLoading/FullScreenLoading'
import { fetchUploadType } from '../../redux/slice/upload-type.slice'
import UploadTypeSchema from '../../shared/schema/upload-type.schema'
import {
  createUploadType,
  deleteUploadType,
  updateUploadType,
} from '../../shared/services/upload-type.service'
export default function UploadTypeListing() {
  const dispatch = useDispatch()
  const loading = useLoading()
  const base = useBaseFunction()
  const { entities, loading: uploadTypeLoading, totalRecords } = useSelector((state) => state.uploadType)
  const { auth } = useSelector((state) => state.admin)
  const [visibleDialog, setVisibleDialog] = useState()
  const [table, setTable] = useState({
    first: 0,
    rows: 10,
    page: 1,
    sortField: null,
    sortOrder: null,
    filters: {
      name: { value: '' },
      uploadType:{value :''}
    },
  })
  const schema = new UploadTypeSchema(loading, base, auth)
  const columns = [
    {
      name: 'ประเภทเอกสาร',
      key: 'name',
      width: '40%',
      sortable: true,
      editor: (options) => (
        <TableInput options={options} validate={schema.nameValidation} placeholder='ชื่อเอกสาร'>
          <InputText />
        </TableInput>
      ),
    }, {
      name: 'รหัสเอกสาร',
      key: 'uploadType',
      width: '40%',
      sortable: true,
      editor: (options) => (
        <TableInput options={options} validate={schema.nameValidation} placeholder='ชื่อเอกสาร'>
          <InputText />
        </TableInput>
      ),
    },{
      key: 'action',
      width: '150px',
      rowEditor: true,
      render: (record, { rowEditor }) => (
        <ActionInRow record={record} rowEditor={rowEditor} handleRemove={handleRemove} />
      ),
    },
  ]
  const fetchData = useCallback(() => {
    dispatch(
      fetchUploadType({
        ...table,
      })
    )
  }, [table, dispatch])
  useEffect(() => {
    fetchData()
  }, [fetchData])
  const formik = useFormik({
    initialValues: schema.defaultValue,
    validationSchema: schema.validation,
    onSubmit: (values) => {
      schema.handleSubmit(values)
    },
  })
  schema.setUpdateFunction(async (...props) => {
    await updateUploadType(...props)
    fetchData()
  })
  schema.setCrateFunction(async (...props) => {
    await createUploadType(...props)
    handleClose()
    fetchData()
  })
  schema.setRemoveFunction(async (...props) => {
    const { data } = await deleteUploadType(...props)
    if (data) {
      fetchData()
    }
  })
  const handleRemove = (id) => {
    schema.onRemove(id, auth.token)
  }
  const handleRowSave = async (e) => {
    const { newData } = e
    schema.setIdentified(newData.id)
    schema.handleSubmit(newData, false)
  }
  const handleClose = () => {
    hideDialog()
    formik.resetForm(schema.defaultValue)
    formik.setErrors({})
  }
  const hideDialog = () => setVisibleDialog(false)
  const showDialog = () => setVisibleDialog(true)
  return (
    <PrivateRoute>
      <Dialog
        header='เพิ่มข้อมูลประเภทเอกสาร'
        draggable={false}
        modal={true}
        visible={visibleDialog}
        style={{ maxWidth: '50vw', minWidth: '450px' }}
        onHide={hideDialog}
      >
        <form onSubmit={formik.handleSubmit}>
          <InlineLabel
            label='ประเภทเอกสาร'
            errors={formik.errors}
            touched={formik.touched}
            name='name'
            required
          >
            <InputText onChange={formik.handleChange} value={formik.values.name} />
          </InlineLabel>
          <InlineLabel
            label='รหัสเอกสาร'
            errors={formik.errors}
            touched={formik.touched}
            name='uploadType'
            required
          >
            <InputText onChange={formik.handleChange} value={formik.values.uploadType} />
          </InlineLabel>
          <div className='d-flex justify-content-end pt-3 gap-2'>
            <Button type='reset' label='ยกเลิก' className='p-button-secondary' onClick={handleClose} />
            <Button type='submit' label='บันทึก' />
          </div>
        </form>
      </Dialog>
      <TableCaption
        render={
          <Space size='small' className='mb-2'>
            <Button
              className='p-button-secondary'
              icon='pi pi-refresh'
              onClick={() => fetchData()}
              loading={uploadTypeLoading}
            />
            <Button icon='pi pi-plus' onClick={showDialog} />
          </Space>
        }
      />
      <Card header='ข้อมูลประเภทเอกสาร'>
        <Table
          onRowEditComplete={handleRowSave}
          editMode='row'
          validate={schema.validation}
          dataKey='id'
          value={entities}
          totalRecords={totalRecords}
          loading={uploadTypeLoading}
          table={table}
          setTable={setTable}
          columns={columns}
        />
      </Card>
    </PrivateRoute>
  )
}
