import { DeleteOutlined } from '@ant-design/icons'
import { Button, Tooltip } from 'antd'
import React from 'react'
export default function TableDeleteButton({ onClick, tooltip = 'ลบ', id }) {

  return (
    <Tooltip title={tooltip}>
      <Button type='text' shape='circle' danger icon={<DeleteOutlined />} onClick={() => onClick(id)} />
    </Tooltip>
  )
}
