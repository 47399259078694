import axios from 'axios'
import { AppContextPath } from '../constant/_environment'
export const deleteLanguageType = async (languageTypeId, token) => {
  return await axios.delete(`${AppContextPath.ADMIN_URL}/language-type/delete/${languageTypeId}`, {
    headers: { Authorization: `Bearer ${token}` },
  })
}

export const updateLanguageType = async (payload, languageTypeId, token) => {
  return await axios.put(`${AppContextPath.ADMIN_URL}/language-type/update/${languageTypeId}`, payload, {
    headers: { Authorization: `Bearer ${token}` },
  })
}

export const createLanguagType = async (payload, token) => {
  return await axios.post(`${AppContextPath.ADMIN_URL}/language-type/new`, payload, {
    headers: { Authorization: `Bearer ${token}` },
  })
}
