import axios from 'axios'
import { AppContextPath } from '../constant/_environment'
export const deleteUploadType = async (uploadId, token) => {
  return await axios.delete(`${AppContextPath.ADMIN_URL}/upload-type/delete/${uploadId}`, {
    headers: { Authorization: `Bearer ${token}` },
  })
}

export const updateUploadType = async (payload, uploadId, token) => {
  console.log(`uploadId : ${uploadId}`)
  return await axios.put(`${AppContextPath.ADMIN_URL}/upload-type/update/${uploadId}`, payload, {
    headers: { Authorization: `Bearer ${token}` },
  })
}

export const createUploadType = async (payload, token) => {
  return await axios.post(`${AppContextPath.ADMIN_URL}/upload-type/new`, payload, {
    headers: { Authorization: `Bearer ${token}` },
  })
}
