import { Button, Tooltip } from 'antd'
import React from 'react'
export default function TableCancelButton({ onClick, tooltip = 'ยกเลิก', id }) {
  return (
    <Tooltip title={tooltip}>
      <Button
        type='text'
        shape='circle'
        danger
        icon={<span className='pi pi-times'></span>}
        onClick={onClick}
      />
    </Tooltip>
  )
}
