export const PROGRAM_TYPE_MAP = {
  BACHELOR: 'ปริญญาตรี',
  MASTER: 'ปริญญาโท',
  PHD: 'ปริญญาเอก',
  SCHOLARSHIP: 'ทุนการศึกษา',
  SHORT_TERM: 'ภาษาจีนระยะสั้น',
  HIGH_SCHOOL: 'มัธยมปลาย',
}

export const LANGUAGE_TYPE = Object.keys(PROGRAM_TYPE_MAP)
