import { object, string } from 'yup'
import { SchemaTemplate } from './template.schema'

export default class UploadTypeSchema extends SchemaTemplate {
  nameValidation = string().required('ต้องระบุ {}!')
  validation = object().shape({
    name: this.nameValidation,
    uploadType : this.nameValidation
  })

  defaultValue = {
    name: '',
    uploadType:''
  }
}
