export const APPLICATION_STEPS = [
  { value: 1, label: 'ขั้นที่ 1: ผู้สมัครกรอกข้อมูล' },
  { value: 2, label: 'ขั้นที่ 2: ผู้สมัครอัพโหลดเอกสาร' },
  { value: 3, label: 'ขั้นที่ 3: ผู้สมัครอัพโหลดสลิป' },
  { value: 4, label: 'ขั้นที่ 4: รอผลตอบกลับ' },
  { value: 5, label: 'ขั้นที่ 5: แอดมินอัพโหลดจดหมายจากมหาวิทยาลัย' },
  { value: 6, label: 'ขั้นที่ 6: แอดมินอัพโหลดสลิป' },
  { value: 7, label: 'ขั้นที่ 7: แอดมินอัพโหลด passport, jw202' },
  { value: 8, label: 'ขั้นที่ 8: แอดมินอัพโหลด visa, ตั่วเครื่องบิน' },
  { value: 9, label: 'ขั้นที่ 9: การสมัครเสร็จสิ้น' },
]
export const EMBASSY_ENUM = {
  1: "embassy of the peole's republic of China in the Kingdom of Thailand",
  2: "embassy of the peole's republic of China in Chiangmai",
  3: "embassy of the peole's republic of China in the Khon Kaen",
  4: "embassy of the peole's republic of China in the Songkhla",
}
