import { Space } from 'antd'
import { useFormik } from 'formik'
import { Button } from 'primereact/button'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router'
import Card from '../../components/Templates/Card'
import TableCaption from '../../components/Templates/TableCaption'
import { PrivateRoute } from '../../helpers/PrivateRoute'
import { useBaseFunction } from '../../layouts/AppContext'
import { useLoading } from '../../layouts/FullScreenLoading/FullScreenLoading'
import { fetchProgramSingle } from '../../redux/slice/program.slice'
import * as ObjectUtils from '../../utils/object.utils'
import ProgramSchema from '../../shared/schema/program.schema'
import { ProgramForm } from './Form/ProgramForm'
import LanguageScoreListing from './LanguageScoreListing'
import ProgramUploadListing from './ProgramUploadListing'
import { useMemo, useState, useEffect, useCallback } from 'react'
import { TabMenu } from 'primereact/tabmenu'
import { createProgram, updateProgram } from '../../shared/services/program.service'
export default function ProgramSingle({ isEdit }) {
  const { programId } = useParams()
  const dispatch = useDispatch()
  const { current, loading: programLoading } = useSelector((state) => state.program)
  const { auth } = useSelector((state) => state.admin)
  const [visible, setVisible] = useState(false)
  const base = useBaseFunction()
  const loading = useLoading()
  const navigate = useNavigate()
  const schema = useMemo(() => new ProgramSchema(loading, base, auth), [loading, base, auth])
  const [initialValues, setInitialValues] = useState(schema.defaultValue)

  schema.setIdentified(programId)
  schema.setUpdateFunction(updateProgram)
  schema.setCrateFunction(async (...props) => {
    const { data } = await createProgram(...props)
    navigate(`/admin/program/edit/${data.id}`, { replace: true })
  })

  const formik = useFormik({
    initialValues,
    validationSchema: schema.validation,
    enableReinitialize: true,
    onSubmit: (values) => {
      schema.handleSubmit(values)
    },
  })
  useEffect(() => {
    if (isEdit && ObjectUtils.isNotEmpty(current)) {
      setInitialValues(ObjectUtils.copy(schema.defaultValue, current))
    }
  }, [current, isEdit, schema])
  const fetchSingleData = useCallback(() => {
    dispatch(fetchProgramSingle(programId))
  }, [programId, dispatch])
  useEffect(() => {
    fetchSingleData()
  }, [fetchSingleData])
  const showDialog = () => {
    setVisible(true)
  }
  const hideDialog = () => {
    setVisible(false)
  }
  const [activeMenu, setActiveMenu] = useState(0)

  const tabs = [
    { label: 'คะแนนภาษา', key: 'score' },
    { label: 'เอกสารเพิ่มเติม', key: 'programupload' },
  ]
  return (
    <PrivateRoute>
      <Card header={isEdit ? 'แก้ไขโปรแกรม' : 'เพิ่มโปรแกรม'} loading={programLoading}>
        <ProgramForm formik={formik} />
      </Card>
      {isEdit && programId && (
        <>
          <TableCaption
            render={
              <Space size='small' className='mb-2'>
                <Button icon='pi pi-plus' onClick={showDialog} />
              </Space>
            }
          />
          <TabMenu model={tabs} activeIndex={activeMenu} onTabChange={(e) => setActiveMenu(e.index)} />
          <Card header={tabs[activeMenu]?.label}>
            {activeMenu === 0 && (
              <LanguageScoreListing programId={programId} visibleDialog={visible} hideDialog={hideDialog} />
            )}
            {activeMenu === 1 && (
              <ProgramUploadListing programId={programId} visibleDialog={visible} hideDialog={hideDialog} />
            )}
          </Card>
          {/* <Card header='คะแนนภาษา'>
          </Card> */}

          {/* <Card header='เอกสารเพิ่มเติม'>
          
          </Card> */}
        </>
      )}
    </PrivateRoute>
  )
}
