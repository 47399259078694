import React from 'react'
import '../assets/scss/template.module.scss'
import MainContainer from '../components/Templates/'
import AppRouting from '../routes'
export default function App() {
  return (
    <MainContainer>
      <AppRouting />
    </MainContainer>
  )
}
