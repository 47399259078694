import { Children, cloneElement, isValidElement, useCallback } from 'react'

export default function InlineLabel({
  label,
  errors,
  touched,
  children,
  name,
  required = false,
  createElement = true,
}) {
  const error = errors && errors[name] && touched[name]
  const childrenWithProps = useCallback(() => {
    return Children.map(children, (child) => {
      if (isValidElement(child)) {
        const defaultClassName = child.props.className ?? ''
        return cloneElement(child, {
          ...child.props,
          className: error ? `${defaultClassName} p-invalid` : defaultClassName,
          name,
          id: name,
        })
      }
      return child
    })
  }, [children, error, name])
  return (
    <div className='app-inline-label'>
      <label id={name} className={error ? 'p-error' : ''}>
        {label} {required && <span style={{ color: 'red' }}>*</span>}
      </label>
      <span className='app-input'>
        {createElement ? childrenWithProps() : children}
        {error && (
          <small className='p-error block  text-right'>{`${
            errors && errors[name].replace('{}', label)
          }`}</small>
        )}
      </span>
    </div>
  )
}
