import { Space } from 'antd'
import { Button } from 'primereact/button'
import { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import TableEditButton from '../../../components/Templates/Button/TableEditButton'
import TableDeleteButton from '../../../components/Templates/Button/TableRemoveButton'
import Card from '../../../components/Templates/Card'
import Table from '../../../components/Templates/Table/Table'
import TableCaption from '../../../components/Templates/TableCaption'
import { PrivateRoute } from '../../../helpers/PrivateRoute'
import { useBaseFunction } from '../../../layouts/AppContext'
import { useLoading } from '../../../layouts/FullScreenLoading/FullScreenLoading'
import { fetchProductList } from '../../../redux/slice/product.slice'
import useTable from '../../../shared/hook/useTable'
import ProductSchema from '../../../shared/schema/product.schema'
import { deleteProduct } from '../../../shared/services/product.service'

export default function ProductListing() {
  const dispatch = useDispatch()
  const [table, setTable] = useTable({ name: { value: '' } })
  const loader = useLoading()
  const navigate = useNavigate()
  const { auth } = useSelector((state) => state.admin)
  const { entities, loading, totalRecords } = useSelector((state) => state.product)
  const base = useBaseFunction()

  const schema = useMemo(() => new ProductSchema(loader, base, auth), [loader, base, auth])
  schema.setRemoveFunction(async (...props) => {
    await deleteProduct(...props, auth.token)
    fetchData()
  })
  const fetchData = useCallback(() => {
    dispatch(fetchProductList(table))
  }, [dispatch, table])
  useEffect(() => {
    fetchData()
  }, [fetchData])
  const handleRemove = (id) => {
    schema.onRemove(id)
  }
  const columns = [
    {
      name: 'ชื่อ',
      key: 'name',
      width: '60%',
      align: 'left',
    },
    {
      name: 'ราคา',
      key: 'price',
      width: '15%',
      align: 'left',
      render: (record) => <span>{record.price.join('/')}</span>,
    },
    {
      name: 'ประเภท',
      key: 'type',
      width: '15%',
      align: 'center',
    },
    {
      key: 'action',
      width: '150px',
      render: (record) => (
        <div className='flex gap-2'>
          <TableEditButton id={record.id} onClick={(id) => navigate('/admin/product/edit/' + id)} />
          <TableDeleteButton id={record.id} onClick={handleRemove} />
        </div>
      ),
    },
  ]
  return (
    <PrivateRoute>
      <TableCaption
        render={
          <Space size='small' className='mb-2'>
            <Button
              className='p-button-secondary'
              icon='pi pi-refresh'
              onClick={fetchData}
              loading={loading}
            />
            <Button icon='pi pi-plus' onClick={() => navigate('/admin/product/new')} />
          </Space>
        }
      />
      <Card header='ขัอมูลสินค้าและบริการ'>
        <Table
          dataKey='id'
          value={entities}
          totalRecords={totalRecords}
          loading={loading}
          table={table}
          setTable={setTable}
          columns={columns}
        />
      </Card>
    </PrivateRoute>
  )
}
