import { Button } from 'primereact/button'
import { Dropdown } from 'primereact/dropdown'
import { InputText } from 'primereact/inputtext'
import { PickList } from 'primereact/picklist'
import React, { Fragment, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import InlineLabel from '../../../../components/Templates/InlineLabel'
import ListOfValue from '../../../../components/Templates/ListOfValue/ListOfValue'
import { ROLE } from '../../../../shared/constant/role'
import { callToApi } from '../../../../shared/services/list-of-value.service'
export default function AdminForm({ formik, isEdit }) {
  const { errors, touched, handleChange, values, handleSubmit, setFieldValue } = formik
  const [source, setSource] = useState([])
  const [target, setTarget] = useState([])
  const { auth } = useSelector((state) => state.admin)

  useEffect(() => {
    const fetchRole = async () => {
      const currentRoles = values.roles.map(({ name, id }) => ({ name, id }))
      const filterRoles = ({ name }) => !currentRoles.map(({ name }) => name).includes(name)
      const { data: roles } = await callToApi('/role/list-of-value', null, auth.token)
      setSource(roles.filter(filterRoles))
      setTarget(currentRoles)
    }
    fetchRole()
  }, [auth, values.roles])
  const onChange = (e) => {
    setTarget(e.target)
    setSource(e.source)
    setFieldValue('roles', e.target)
  }
  return (
    <Fragment>
      <form onSubmit={handleSubmit}>
        <InlineLabel errors={errors} touched={touched} label='ชื่อ' name='firstName'>
          <InputText onChange={handleChange} value={values.firstName} />
        </InlineLabel>
        <InlineLabel errors={errors} touched={touched} label='นามสกุล' name='lastName'>
          <InputText onChange={handleChange} value={values.lastName} />
        </InlineLabel>
        <InlineLabel errors={errors} touched={touched} label='อีเมล' name='email'>
          <InputText onChange={handleChange} value={values.email} disabled={isEdit} />
        </InlineLabel>
        {!isEdit && (
          <InlineLabel errors={errors} touched={touched} label='รหัสผ่าน' name='password'>
            <InputText onChange={handleChange} value={values.password} type='password' />
          </InlineLabel>
        )}
        <InlineLabel errors={errors} touched={touched} label='บทบาท' name='role'>
          <Dropdown
            onChange={handleChange}
            value={values.role}
            optionLabel='label'
            optionValue='value'
            options={ROLE}
          />
        </InlineLabel>
        <InlineLabel errors={errors} touched={touched} label='ตำแหน่ง' name='positionId'>
          <ListOfValue
            setFieldValue={setFieldValue}
            value={values.positionId}
            callTo='/position/list-of-value'
            displayField='name'
            field='id'
          />
        </InlineLabel>
        <div className='p-3'>
          <PickList
            source={source}
            target={target}
            itemTemplate={PickItem}
            onChange={onChange}
            targetStyle={{ height: '100%' }}
            sourceStyle={{ height: '100%' }}
            showSourceControls={false}
            showTargetControls={false}
          />
        </div>
        <div className='d-flex justify-content-end p-4'>
          <Button label='บันทึก' type='submit' />
        </div>
      </form>
    </Fragment>
  )
}

function PickItem(item) {
  return item.name
}
