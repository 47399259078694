import axios from 'axios'
import { useFormik } from 'formik'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import React from 'react'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Swal from 'sweetalert2'
import Card from '../../components/Templates/Card'
import InlineLabel from '../../components/Templates/InlineLabel'
import ListOfValue from '../../components/Templates/ListOfValue/ListOfValue'
import { PrivateRoute } from '../../helpers/PrivateRoute'
import { useBaseFunction } from '../../layouts/AppContext'
import { fetchSetting } from '../../redux/slice/setting.slice'
import { CONFIRM_SAVE } from '../../shared/constant/_confirm'
import { AppContextPath } from '../../shared/constant/_environment'
import Payment from './Payment'
export default function SettingListing() {
  const { entities } = useSelector((state) => state.setting)
  const { auth } = useSelector((state) => state.admin)
  const { successToast, errorToast } = useBaseFunction()
  const [initialValues, setInitialValues] = useState({
    instagram_url: '',
    instagram_id: '',
    facebook_url: '',
    facebook_page: '',
    recommend_id: null,
  })
  const dispatch = useDispatch()
  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: (values) => {
      Swal.fire(CONFIRM_SAVE).then(({ isConfirmed }) => {
        if (isConfirmed) {
          axios({
            url: `${AppContextPath.v1.ADMIN_URL}/config/edit`,
            method: 'put',
            data: values,
            headers: { Authorization: `Bearer ${auth.token}` },
          })
            .then(() => {
              successToast('บันทึกข้อมูลสำเร็จ')
            })
            .catch((e) => errorToast(e.message))
        }
      })
    },
  })
  useEffect(() => {
    dispatch(fetchSetting())
  }, [dispatch])
  const getConfigValue = useCallback(
    (name) => {
      return entities.find((e) => e.name === name)?.desc || ''
    },
    [entities]
  )
  useEffect(() => {
    setInitialValues({
      instagram_url: getConfigValue('instagram_url'),
      instagram_id: getConfigValue('instagram_id'),
      facebook_url: getConfigValue('facebook_url'),
      facebook_page: getConfigValue('facebook_page'),
      recommend_id: getConfigValue('recommend_id'),
    })
  }, [getConfigValue])
  return (
    <PrivateRoute>
      <Card header='แก้ไขข้อมูลระบบ'>
        <form onSubmit={formik.handleSubmit} className='p-3'>
          <InlineLabel
            errors={formik.errors}
            touched={formik.touched}
            label='Instagram Url'
            name='instagram_url'
          >
            <InputText onChange={formik.handleChange} value={formik.values.instagram_url} />
          </InlineLabel>
          <InlineLabel
            errors={formik.errors}
            touched={formik.touched}
            label='Instagram ID'
            name='instagram_id'
          >
            <InputText onChange={formik.handleChange} value={formik.values.instagram_id} />
          </InlineLabel>
          <InlineLabel
            errors={formik.errors}
            touched={formik.touched}
            label='Facebook Url'
            name='facebook_url'
          >
            <InputText onChange={formik.handleChange} value={formik.values.facebook_url} />
          </InlineLabel>
          <InlineLabel
            errors={formik.errors}
            touched={formik.touched}
            label='Facebook PageName'
            name='facebook_page'
          >
            <InputText onChange={formik.handleChange} value={formik.values.facebook_page} />
          </InlineLabel>
          <InlineLabel
            errors={formik.errors}
            touched={formik.touched}
            label='ตำแหน่งแนะแนว'
            name='recommend_id'
          >
            <ListOfValue
              setFieldValue={formik.setFieldValue}
              value={formik.values.recommend_id}
              callTo='/position/list-of-value'
              displayField='name'
              field='id'
            />
          </InlineLabel>
          <div className='flex justify-content-end p-2'>
            <Button type='submit' className='p-button-sm'>บันทึก</Button>
          </div>
        </form>
      </Card>
      <Card header='ข้อมูลการชำระเงิน'>
        <Payment />
      </Card>
    </PrivateRoute>
  )
}
