import React, { useCallback, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import { whiteListPage } from '../shared/constant/pageNames'
import { getSidebarItems } from '../shared/constant/_sidebar'

export const PrivateRoute = ({ children }) => {
  const isLogin = localStorage.getItem('token')
  const navigate = useNavigate()
  const location = useLocation()
  const { auth, currentRole } = useSelector((state) => state.admin)
  if (auth.role !== 'superadmin' && auth.roles.length === 0) {
    navigate('/admin/none-role')
  }
  const verifyPages = useCallback(() => {
    const pathname = location.pathname
    const path = getSidebarItems().find((sidebar) => sidebar.path === pathname)?.name
    if (
      auth.role !== 'superadmin' &&
      path &&
      !currentRole?.pageNames?.includes(path) &&
      !whiteListPage.includes(pathname)
    ) {
      navigate('/admin')
    }
  }, [auth, location.pathname, navigate, currentRole])
  useEffect(() => {
    // verifyPages()
  }, [verifyPages])
  return isLogin ? children : <Navigate to='/admin/login' replace />
}
