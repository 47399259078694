import axios from 'axios'
import { AppContextPath } from '../constant/_environment'
export const deleteStudyLevel = async (sutdyLevelId, token) => {
  return await axios.delete(`${AppContextPath.ADMIN_URL}/study-level/delete/${sutdyLevelId}`, {
    headers: { Authorization: `Bearer ${token}` },
  })
}

export const updateStudyLevel = async (payload, sutdyLevelId, token) => {
  return await axios.put(`${AppContextPath.ADMIN_URL}/study-level/update/${sutdyLevelId}`, payload, {
    headers: { Authorization: `Bearer ${token}` },
  })
}

export const createStudyLevel = async (payload, token) => {
  return await axios.post(`${AppContextPath.ADMIN_URL}/study-level/new`, payload, {
    headers: { Authorization: `Bearer ${token}` },
  })
}
