import {
  AppstoreAddOutlined,
  ClusterOutlined,
  DesktopOutlined,
  ExperimentOutlined,
  FieldBinaryOutlined,
  FileTextOutlined,
  FontSizeOutlined,
  FundViewOutlined,
  InfoCircleOutlined,
  MenuUnfoldOutlined,
  PartitionOutlined,
  PictureOutlined,
  QuestionCircleOutlined,
  SettingOutlined,
  SortAscendingOutlined,
  UserOutlined,
} from '@ant-design/icons'
import React from 'react'

export const getSidebarItems = () => [
  { name: 'Data Management', isTitle: true },
  {
    name: 'university',
    icon: <ExperimentOutlined className='nav-icon' />,
    path: '/admin/university',
  },
  {
    name: 'program',
    icon: <ClusterOutlined className='nav-icon' />,
    path: '/admin/program',
  },

  {
    name: 'course',
    icon: <InfoCircleOutlined className='nav-icon' />,
    path: '/admin/course',
  },
  {
    name: 'bill',
    icon: <FileTextOutlined className='nav-icon' />,
    path: '/admin/billing',
  },
  {
    name: 'blog',
    icon: <AppstoreAddOutlined className='nav-icon' />,
    path: '/admin/blogs',
  },
  {
    name: 'review',
    icon: <FundViewOutlined className='nav-icon' />,
    path: '/admin/reviews',
  },

  {
    name: 'application',
    icon: <DesktopOutlined className='nav-icon' />,
    path: '/admin/application',
  },
  { isTitle: true, name: 'Infomation' },
  {
    name: 'country',
    icon: <FontSizeOutlined className='nav-icon' />,
    path: '/admin/country',
  },
  {
    name: 'product',
    icon: <span className='pi pi-shopping-cart nav-icon' />,
    path: '/admin/product',
  },
  {
    name: 'bank',
    icon: <span className='pi pi-building nav-icon' />,
    path: '/admin/bank',
  },
  {
    name: 'upload',
    icon: <FontSizeOutlined className='nav-icon' />,
    path: '/admin/upload-type',
  },
  {
    name: 'educationLevel',
    icon: <SortAscendingOutlined className='nav-icon' />,
    path: '/admin/study-level',
  },
  {
    name: 'scoreCategory',
    icon: <MenuUnfoldOutlined className='nav-icon' />,
    path: '/admin/score-category',
  },
  {
    name: 'position',
    icon: <span className='pi pi-external-link nav-icon' />,
    path: '/admin/position',
    superadmin: true,
  },
  {
    name: 'languageScore',
    icon: <FieldBinaryOutlined className='nav-icon' />,
    path: '/admin/score',
  },
  {
    name: 'major',
    icon: <PartitionOutlined className='nav-icon' />,
    path: '/admin/major',
  },
  { isTitle: true, name: 'View' },
  {
    name: 'user',
    icon: <UserOutlined className='nav-icon' />,
    path: '/admin/users',
  },
  {
    name: 'questionnaire',
    icon: <QuestionCircleOutlined className='nav-icon' />,
    path: '/admin/questionnaire',
  },
  { isTitle: true, name: 'System' },
  {
    name: 'banner',
    icon: <PictureOutlined className='nav-icon' />,
    path: '/admin/banner',
  },
  {
    name: 'admin',
    icon: <span className='nav-icon pi pi-user' />,
    path: '/admin/management',
    superadmin: true,
  },
  {
    name: 'role',
    icon: <span className='nav-icon pi pi-th-large' />,
    path: '/admin/role',
    superadmin: true,
  },
  {
    name: 'setting',
    icon: <SettingOutlined className='nav-icon' />,
    path: '/admin/setting',
  },
]
