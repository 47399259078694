import { useFormik } from 'formik'
import { Dialog } from 'primereact/dialog'
import { InputText } from 'primereact/inputtext'
import { Fragment, useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ActionInRow from '../../components/Templates/ActionInRow'
import Table from '../../components/Templates/Table/Table'
import TableInput from '../../components/Templates/TableInput'
import { useBaseFunction } from '../../layouts/AppContext'
import { useLoading } from '../../layouts/FullScreenLoading/FullScreenLoading'
import { fetchApplying } from '../../redux/slice/applying.slice'
import ApplyingSchema from '../../shared/schema/applying.schme'
import { createApplying, deleteApplying, updateApplying } from '../../shared/services/applying.service'
import { ApplyingForm } from './Form/ApplyingForm'

import AppCalendar from '../../components/Templates/Calendar'
import DateUtils from '../../utils/date.utils'
import useTable from '../../shared/hook/useTable'

export default function ApplyingListing({ universityId, visibleDialog, hideDialog }) {
  const dispatch = useDispatch()
  const { entities, totalRecords, loading: applyingLoading } = useSelector((state) => state.applying)
  const base = useBaseFunction()
  const { auth } = useSelector((state) => state.admin)
  const loading = useLoading()
  const schema = new ApplyingSchema(loading, base, auth)
  const [table, setTable] = useTable({
    desc: { value: '' },
    closeDate: { value: null },
    startDate: { value: null },
  })
  const fetchData = useCallback(() => {
    dispatch(
      fetchApplying({
        ...table,
        universityId,
      })
    )
  }, [table, universityId, dispatch])
  schema.setUpdateFunction(async (...props) => {
    await updateApplying(...props)
    fetchData()
  })
  schema.setCrateFunction(async (...props) => {
    await createApplying(...props)
    handleClose()
    fetchData()
  })
  schema.setRemoveFunction(async (...props) => {
    const { data } = await deleteApplying(...props)
    if (data) {
      fetchData()
    }
  })

  const handleRemove = (applyingId) => {
    schema.onRemove(applyingId, auth.token)
  }
  useEffect(() => {
    fetchData()
  }, [fetchData])
  const columns = [
    {
      name: 'คำอธิบาย',
      key: 'desc',
      width: '20%',
      align: 'left',
      sortable: true,
      editor: (options) => (
        <TableInput options={options} placeholder='คำอธิบาย'>
          <InputText />
        </TableInput>
      ),
    },
    {
      name: 'วันที่เปิดรับสมัคร',
      key: 'startDate',
      width: '40%',
      align: 'left',
      sortable: true,
      type: 'date',
      editor: (options) => (
        <TableInput options={options} placeholder='วันที่เปิดรับสมัคร' validate={schema.startDateValidation}>
          <AppCalendar />
        </TableInput>
      ),
      render: (record) => DateUtils.getString(record.startDate),
    },
    {
      name: 'วันที่ปิดรับสมัคร',
      key: 'closeDate',
      width: '40%',
      align: 'left',
      sortable: true,
      type: 'date',
      editor: (options) => (
        <TableInput options={options} placeholder='วันที่ปิดรับสมัคร' validate={schema.closeDateValidation}>
          <AppCalendar />
        </TableInput>
      ),
      render: (record) => DateUtils.getString(record.closeDate),
    },

    {
      key: 'action',
      width: '150px',
      rowEditor: true,
      render: (record, { rowEditor }) => (
        <ActionInRow record={record} rowEditor={rowEditor} handleRemove={handleRemove} />
      ),
    },
  ]
  const formik = useFormik({
    initialValues: schema.defaultValue,
    validationSchema: schema.validation,
    onSubmit: (values) => {
      schema.handleSubmit({
        ...values,
        universityId,
      })
    },
  })
  const handleRowSave = async (e) => {
    const { newData } = e
    schema.setIdentified(newData.id)
    schema.handleSubmit(newData, false)
  }
  const handleClose = () => {
    hideDialog()
    formik.resetForm(schema.defaultValue)
    formik.setErrors({})
  }
  return (
    <Fragment>
      <Dialog
        header='เพิ่มข้อมูลการรับสมัคร'
        draggable={false}
        modal={true}
        visible={visibleDialog}
        style={{ maxWidth: '50vw', minWidth: '450px' }}
        onHide={hideDialog}
      >
        <ApplyingForm formik={formik} handleClose={handleClose} />
      </Dialog>
      <Table
        onRowEditComplete={handleRowSave}
        editMode='row'
        validate={schema.validation}
        dataKey='id'
        value={entities}
        totalRecords={totalRecords}
        loading={applyingLoading}
        table={table}
        setTable={setTable}
        columns={columns}
      />
    </Fragment>
  )
}
