import axios from 'axios'
import { AppContextPath } from '../constant/_environment'
export const deleteCountry = async (countryId, token) => {
  return await axios.delete(`${AppContextPath.ADMIN_URL}/country/delete/${countryId}`, {
    headers: { Authorization: `Bearer ${token}` },
  })
}

export const updateCountry = async (payload, countryId, token) => {
  return await axios.put(`${AppContextPath.ADMIN_URL}/country/update/${countryId}`, payload, {
    headers: { Authorization: `Bearer ${token}` },
  })
}

export const createCountry = async (payload, token) => {
  return await axios.post(`${AppContextPath.ADMIN_URL}/country/new`, payload, {
    headers: { Authorization: `Bearer ${token}` },
  })
}
export const deleteCity = async (cityId, token) => {
  return await axios.delete(`${AppContextPath.ADMIN_URL}/city/delete/${cityId}`, {
    headers: { Authorization: `Bearer ${token}` },
  })
}

export const updateCity = async (payload, cityId, token) => {
  return await axios.put(`${AppContextPath.ADMIN_URL}/city/update/${cityId}`, payload, {
    headers: { Authorization: `Bearer ${token}` },
  })
}

export const createCity = async (payload, token) => {
  return await axios.post(`${AppContextPath.ADMIN_URL}/city/new`, payload, {
    headers: { Authorization: `Bearer ${token}` },
  })
}
