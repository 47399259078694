import {
  CheckCircleTwoTone,
  ClockCircleOutlined,
  FileDoneOutlined,
  IssuesCloseOutlined,
} from '@ant-design/icons'
import { List, Tag, Timeline } from 'antd'
import { groupBy } from 'lodash'
import { Button } from 'primereact/button'
import { InputTextarea } from 'primereact/inputtextarea'
import { useSelector } from 'react-redux'
import DisplaySlip from '../../../components/DisplaySlip'
import PreviewUpload from '../../../components/PreviewUpload'
import { useBaseFunction } from '../../../layouts/AppContext'
import { CASC_APPLICATION, MEMBERSHIP_FEE_SLIP } from '../../../old_code/Admin/uploadKey.constant'
import { LANGUAGE_MAP } from '../../../shared/constant/language'
import { PROGRAM_TYPE_MAP } from '../../../shared/constant/programType'
import {
  ADMISSION_LETTER,
  JW202,
  PLANE_TICKET,
  SERVICE_FEE_SLIP,
  VIP_FEE_SLIP,
  VISA,
  VISA_PLANE_SLIP,
} from '../../../shared/constant/uploadKey'
import { notifyUser, updateApplication } from '../../../shared/services/application.service'
import { UploadWrapper } from './UploadWrapper'

const renderItem = (upload) => {
  return (
    <List.Item>
      <List.Item.Meta
        avatar={<PreviewUpload upload={upload} />}
        title={<a>{upload.uploadType}</a>}
        description={`อัพโหลดเมื่อ ${upload.createdAt}`}
      />
      <a
        href={`${process.env.REACT_APP_API_URL}/admin/redirect/assets?bucket=applications&uploadPath=${upload.uploadPath}`}
      >
        ดาวน์โหลด
      </a>
    </List.Item>
  )
}

function ResendButton({ formik, fetchData, step = 1 }) {
  const { auth } = useSelector((state) => state.admin)
  const { successToast, errorToast } = useBaseFunction()
  const onClick = () => {
    updateApplication(
      {
        ...formik.values,
        isSubmitted: false,
      },
      formik.values.id,
      auth.token
    )
      .then(() => {
        fetchData()
        successToast('ส่งคืนเรียบร้อย')
      })
      .catch((err) => {
        errorToast(err.message)
      })
  }
  return (
    <Button
      className='p-button-raised p-button-text'
      onClick={onClick}
      disabled={!formik.values.isSubmitted || formik.values.currentStep > step}
      type='button'
      icon={<IssuesCloseOutlined className='mr-1 text-warning' />}
    >
      ส่งคืน
    </Button>
  )
}

export default function ApplicationForm({ formik, fetchData, application }) {
  const { values, handleSubmit } = formik
  const getApplicationSelected = () => {
    const grouped = groupBy(application.programs, 'university.name')
    return (
      <div className='mb-3'>
        {Object.keys(grouped).map((g) => (
          <div key={g}>
            <span className='m-0 font-bold'>{g}</span>
            <ul>
              {grouped[g].map((p) => (
                <li key={p.id}>
                  {p.name} ({PROGRAM_TYPE_MAP[p.programType]} {LANGUAGE_MAP[p.language]})
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    )
  }
  const getPrice = (price) => {
    return price
      .map((p) =>
        Intl.NumberFormat('th-TH', {
          style: 'currency',
          currency: 'THB',
        }).format(p)
      )
      .join('/')
  }
  const StepButtonGroup = ({ toStep, step, resend = false }) => (
    <div className='p-buttonset'>
      <MoveStepButton toStep={toStep} formik={formik} fetchData={fetchData} />
      {resend && <ResendButton formik={formik} fetchData={fetchData} step={step - 1} />}
      <NotifyUserButton step={step} formik={formik} fetchData={fetchData} />
    </div>
  )
  return (
    <form onSubmit={handleSubmit} className='m-4'>
      <Timeline>
        <Timeline.Item>
          <h3>ขั้นตอนที่ 1</h3>
          <Tag color='red'>ผู้สมัคร</Tag>
          <p>อัพโหลดใบสมัคร และสลิปชำระเงิน</p>
          <List
            itemLayout='horizontal'
            locale={{ emptyText: <span></span> }}
            dataSource={values?.uploads?.filter((upload) =>
              [CASC_APPLICATION, MEMBERSHIP_FEE_SLIP].includes(upload.uploadType)
            )}
            renderItem={(upload) => <DisplaySlip upload={upload} />}
          />
          <div className='mt-3'>
            <label>อัปโหลดใบเสร็จ</label>

            <UploadWrapper uploadType={ADMISSION_LETTER} formik={formik} fetchData={fetchData} />
          </div>
          <StepButtonGroup step={2} toStep={2} resend={true} />
        </Timeline.Item>
        <Timeline.Item>
          <h3>ขั้นตอนที่ 2</h3>
          <Tag color='red'>ผู้สมัคร</Tag>
          <p>รายการที่มีการสมัคร</p>
          {getApplicationSelected()}
          <StepButtonGroup step={3} toStep={3} resend />
        </Timeline.Item>
        <Timeline.Item>
          <h3>ขั้นตอนที่ 3</h3>
          <Tag color='cyan'>แอดมิน</Tag>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <label>เอกสารที่ยังขาด</label>
            <InputTextarea
              rows={4}
              id='missingDocRemark'
              name='missingDocRemark'
              type='text'
              placeholder='เอกสารต่างๆที่ยังขาด'
              onChange={formik.handleChange}
              value={formik.values.missingDocRemark}
              style={{ borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}
            />
            <Button style={{ borderTopRightRadius: 0, borderTopLeftRadius: 0 }} type='submit'>
              อัพเดท
            </Button>
          </div>
          <div className='mt-3'>
            <label>Admission letter</label>
            <UploadWrapper uploadType={ADMISSION_LETTER} formik={formik} fetchData={fetchData} />
          </div>
          <StepButtonGroup step={4} toStep={4} resend={true} />
        </Timeline.Item>
        <Timeline.Item dot={<ClockCircleOutlined style={{ fontSize: '16px' }} />}>
          <h3>ขั้นตอนที่ 4</h3>
          <Tag color='red'>ผู้สมัคร</Tag>
          {formik.values?.vip && formik.values?.product && (
            <>
              <div>บริการสมัครทุนการศึกษา</div>
              <div>
                <span style={{ fontWeight: '500' }}>Price: </span>
                {formik.values.product?.name} {getPrice(formik.values.product?.price)}
              </div>
              <div>
                <span style={{ fontWeight: '500' }}>Title: </span>
                {formik.values.vip?.name} {getPrice(formik.values.vip?.price)}
              </div>
            </>
          )}
          <p className='text-red-500'>รอสลิปชำระค่าบริการจากทางผู้สมัคร</p>
          <List
            itemLayout='horizontal'
            locale={{ emptyText: <span></span> }}
            dataSource={formik.values?.uploads?.filter((upload) =>
              [SERVICE_FEE_SLIP, VIP_FEE_SLIP].includes(upload.uploadType)
            )}
            renderItem={(upload) => <DisplaySlip upload={upload} canRemove/>}
          />
          <StepButtonGroup step={5} toStep={5} resend={true} />
        </Timeline.Item>
        <Timeline.Item>
          <h3>ขั้นตอนที่ 5</h3>
          <Tag color='cyan'>แอดมิน</Tag>
          <div style={{ display: 'flex' }}>
            <div>
              <label>JW202</label>
              <UploadWrapper uploadType={JW202} formik={formik} fetchData={fetchData} />
            </div>
          </div>
          <StepButtonGroup step={6} toStep={6} />
        </Timeline.Item>
        <Timeline.Item>
          <h3>ขั้นตอนที่ 6</h3>
          <Tag color='red'>ผู้สมัคร</Tag>
          <p>รอสลิปชำระค่าบริการจากทางผู้สมัคร</p>
          <List
            itemLayout='horizontal'
            locale={{ emptyText: <span></span> }}
            dataSource={formik.values?.uploads?.filter((upload) =>
              [VISA_PLANE_SLIP].includes(upload.uploadType)
            )}
            renderItem={renderItem}
          />
          <StepButtonGroup step={7} toStep={7} />
        </Timeline.Item>
        <Timeline.Item>
          <h3>ขั้นตอนที่ 7</h3>
          <Tag color='cyan'>แอดมิน</Tag>
          <div style={{ display: 'flex' }}>
            <div>
              <label>Visa</label>
              <UploadWrapper uploadType={VISA} formik={formik} fetchData={fetchData} />
            </div>
            <div>
              <label>ตั๋วเครื่องบิน</label>
              <UploadWrapper uploadType={PLANE_TICKET} formik={formik} fetchData={fetchData} />
            </div>
          </div>
          <StepButtonGroup step={8} toStep={8} />
        </Timeline.Item>

        <Timeline.Item>
          <h3>ขั้นตอนที่ 8</h3>
          <h3>เสร็จสิ้น</h3>
        </Timeline.Item>
      </Timeline>
    </form>
  )
}

function NotifyUserButton({ step, formik, fetchData }) {
  const { auth } = useSelector((state) => state.admin)
  const { successToast, errorToast } = useBaseFunction()
  const isNotCurrentStep = step !== formik.values.currentStep
  const isNotified = formik.values.notifiedSteps?.includes(step)
  const onClick = () => {
    notifyUser(formik.values.id, auth.token)
      .then(() => {
        fetchData()
        successToast('แจ้งผู้สมัครสำเร็จ')
      })
      .catch((err) => errorToast(err.message))
  }
  return (
    <Button
      icon={<FileDoneOutlined className='mr-2' />}
      type='button'
      onClick={onClick}
      className='p-button-raised p-button-text'
      disabled={isNotCurrentStep || isNotified}
    >
      แจ้งผู้สมัคร
    </Button>
  )
}

function MoveStepButton({ toStep, formik, fetchData }) {
  const { auth } = useSelector((state) => state.admin)
  const { successToast, errorToast } = useBaseFunction()
  const onClick = () => {
    updateApplication(
      {
        ...formik.values,
        currentStep: toStep,
      },
      formik.values.id,
      auth.token
    )
      .then(() => {
        fetchData()
        successToast(`เลื่อนขั้นไปขั้น ${toStep} สำเร็จ`)
      })
      .catch((err) => {
        errorToast(err.message)
      })
  }
  return (
    <Button
      className='p-button-raised p-button-text'
      onClick={onClick}
      type='button'
      icon={
        formik.values.currentStep === toStep ? (
          <CheckCircleTwoTone twoToneColor='#52c41a' className='mr-2' />
        ) : (
          <FileDoneOutlined />
        )
      }
    >
      เลื่อนไปขั้น {toStep}
    </Button>
  )
}
