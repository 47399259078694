import { List } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { fetchApplicationSingle } from '../../redux/slice/application.slice'
import { deleteTransaction } from '../../shared/services/transaction.service'
import DateUtils from '../../utils/date.utils'
import PreviewUpload from '../PreviewUpload'

export default function DisplaySlip({ upload, canRemove }) {
  const { current } = useSelector((state) => state.application)
  const { auth } = useSelector((state) => state.admin)
  const dispatch = useDispatch()
  const transactionSlip = current.transactions?.find(
    (transaction) => transaction.upload?.uploadType === upload.uploadType
  )
  const removeTransaction = async () => {
    await deleteTransaction(transactionSlip.id, auth.token)
    dispatch(fetchApplicationSingle(current.id))
  }
  return (
    <List.Item>
      <div className='flex align-items-center w-full gap-2'>
        <div className='flex flex-1 align-items-center gap-2'>
          <div className='flex-shrink'>
            <PreviewUpload upload={upload} />
          </div>
          <ul className='flex-2'>
            <li>
              จ่ายวันที่{' '}
              <span className='font-bold'>
                {DateUtils.getString(transactionSlip?.date, 'DDD เวลา HH:mmน.')}
              </span>
            </li>
            <li>
              จำนวน{' '}
              <span className='font-bold'>
                {Intl.NumberFormat('th-TH', {
                  currency: 'THB',
                }).format(transactionSlip?.totalPrice)}{' '}
              </span>
              บาท
            </li>
            <div className='text-gray-500'>
              อัพโหลดเมื่อ {DateUtils.getString(upload?.createdAt, 'DDD เวลา HH:mmน.')}
            </div>
          </ul>
        </div>
        <div className='flex-2 gap-3 flex text-right'>
          {canRemove && <a onClick={removeTransaction}>ลบ</a>}
          <a
            href={`${process.env.REACT_APP_API_URL}/admin/redirect/assets?bucket=applications&uploadPath=${upload.uploadPath}`}
          >
            ดาวน์โหลด
          </a>
        </div>
      </div>
    </List.Item>
  )
}
