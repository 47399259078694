import { useFormik } from 'formik'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import * as yup from 'yup'
import Background from '../../assets/images/background.jpg'
import { login } from '../../redux/slice/admin.slice'
import style from './login.module.scss'
export default function Login() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { auth: isLogin, authError } = useSelector((state) => state.admin)
  const handleSubmit = (values) => {
    dispatch(login(values))
  }
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: yup.object({
      email: yup.string().email('รูปแบบของอีเมลไม่ถูกต้อง').required('โปรดระบุอีเมล'),
      password: yup.string().required('โปรดระบุรหัสผ่าน'),
    }),
    onSubmit: handleSubmit,
  })

  useEffect(() => {
    if (isLogin) {
      navigate('/admin/university')
    }
  }, [isLogin, navigate])
  return (
    <div className={style.loginBackground}>
      <form className={style.loginForm} onSubmit={formik.handleSubmit}>
        <img src={Background} alt='login-bg' />
        <div className={style.form}>
          <input
            type='email'
            name='email'
            value={formik.values.email}
            onChange={formik.handleChange}
            className={style.loginInput}
            placeholder='อีเมล์'
          />
          {formik.errors.email && <small className='p-error'>{formik.errors.email}!</small>}
          <input
            type='password'
            name='password'
            value={formik.values.password}
            onChange={formik.handleChange}
            className={style.loginInput}
            placeholder='รหัสผ่าน'
          />
          {formik.errors.password && <small className='p-error'>{formik.errors.password}!</small>}
          {authError && <small className='p-error'>{authError.message}</small>}
          <input type='submit' value='เข้าสู่ระบบ' className={style.loginButton} />
        </div>
      </form>
    </div>
  )
}
