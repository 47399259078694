import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import _ from 'lodash'
import { AppContextPath } from '../../shared/constant/_environment'
import FilterUtils from '../../utils/filter.utils'

export const fetchProductList = createAsyncThunk(
  'product/fetchProductList',
  async ({ first: offset = 0, rows: limit = 10, filters }, { getState }) => {
    const filterParam = FilterUtils.getParams(filters)
    const response = await axios({
      method: 'GET',
      url: `${AppContextPath.ADMIN_URL}/product/list`,
      headers: {
        Authorization: 'Bearer ' + getState().admin.auth.token,
      },
      params: { offset, limit, ...filterParam },
    })
    return _.get(response, 'data', [])
  }
)
export const fetchProductSingle = createAsyncThunk(
'product/fetchProductSingke',
  async (productId, { getState }) => {
    const response = await axios({
      method: 'GET',
      url: `${AppContextPath.ADMIN_URL}/product/single/${productId}`,
      headers: {
        Authorization: 'Bearer ' + getState().admin.auth.token,
      },
    })
    return _.get(response, 'data', [])
  }
)

export const productSlice = createSlice({
  name: 'product',
  initialState: {
    entities: [],
    totalRecords: 0,
    loading: false,
    error: null,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProductList.pending, (state) => {
      state.loading = true
    })
    builder.addCase(fetchProductList.fulfilled, (state, action) => {
      state.entities = action.payload.rows
      state.totalRecords = action.payload.count
      state.loading = false
    })
    builder.addCase(fetchProductList.rejected, (state) => {
      state.entities = []
      state.totalRecords = 0
      state.loading = false
    })
    builder.addCase(fetchProductSingle.fulfilled, (state, action) => {
      state.current = action.payload
      state.loading = false
    })
    builder.addCase(fetchProductSingle.pending, (state, action) => {
      state.loading = true
    })
  },
})
