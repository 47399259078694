import axios from 'axios'
import { AppContextPath } from '../constant/_environment'
export const deleteMajor = async (majorId, token) => {
  return await axios.delete(`${AppContextPath.ADMIN_URL}/major/delete/${majorId}`, {
    headers: { Authorization: `Bearer ${token}` },
  })
}

export const updateMajor = async (payload, majorId, token) => {
  return await axios.put(`${AppContextPath.ADMIN_URL}/major/update/${majorId}`, payload, {
    headers: { Authorization: `Bearer ${token}` },
  })
}

export const createMajor = async (payload, token) => {
  return await axios.post(`${AppContextPath.ADMIN_URL}/major/new`, payload, {
    headers: { Authorization: `Bearer ${token}` },
  })
}
