import { useState } from 'react'

export default function useTable(filters) {
  const [table, setTable] = useState({
    first: 0,
    rows: 10,
    page: 1,
    filters,
  })

  return [table, setTable]
}
