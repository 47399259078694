import { object } from 'yup'
import { SchemaTemplate } from './template.schema'
export default class ApplicationSchema extends SchemaTemplate {
  validation = object().shape({})

  defaultValue = {
    id: '',
    cascId: '',
    currentStep: -1,
    missingDocRemark: '',
    notifiedSteps: [],
    phone: [],
    user: {
      id: '',
      firstName: '',
      lastName: '',
      email: '',
    },
    uploads: [],
    isSubmitted: false,
    vip: null,
    product: null,
    admin: {
      fullName: '',
    },
  }
}
