import { array, object, string } from 'yup'
import { SchemaTemplate } from './template.schema'
export default class ProductSchema extends SchemaTemplate {
  static PRODUCT_ENUM = {
    VIPS: 'VIPS',
    SERVICES: 'SERVICES',
  }
  validation = object().shape({
    name: string().required('โปรดระบุ {}!'),
    type: string().required('โปรดระบุ {}!'),
    price: array(string().required()).min(1),
  })

  defaultValue = {
    name: '',
    price: [''],
    type: '',
    file: '',
  }

  constructor(loader, base, auth, def) {
    super(loader, base, auth)
    if (def) {
      this.defaultValue = def
    }
  }
}
