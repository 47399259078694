import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { AppContextPath } from '../../shared/constant/_environment'
import FilterUtils from '../../utils/filter.utils'

export const fetchAdminList = createAsyncThunk(
  'management/fetchAdminList ',
  async ({ first: offset = 0, rows: limit = 10, filters }, { getState }) => {
    const filterParam = FilterUtils.getParams(filters)
    const response = await axios({
      method: 'GET',
      url: `${AppContextPath.ADMIN_URL}/admin/list`,
      headers: {
        Authorization: 'Bearer ' + getState().admin.auth.token,
      },
      params: { offset, limit, ...filterParam },
    })
    return response.data
  }
)
export const fetchAdminSingle = createAsyncThunk(
  'country/fetchAdminSingle ',
  async (adminId, { getState }) => {
    const response = await axios({
      method: 'GET',
      url: `${AppContextPath.ADMIN_URL}/admin/single/${adminId}`,
      headers: {
        Authorization: 'Bearer ' + getState().admin.auth.token,
      },
    })
    return response.data
  }
)

export const managementSlice = createSlice({
  name: 'management',
  initialState: {
    current: {},
    entities: [],
    totalRecords: 0,
    loading: false,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAdminList.pending, (state) => {
      state.loading = true
    })
    builder.addCase(fetchAdminList.fulfilled, (state, action) => {
      state.entities = action.payload.rows
      state.totalRecords = action.payload.count
      state.loading = false
    })
    builder.addCase(fetchAdminSingle.pending, (state) => {
      state.loading = true
    })
    builder.addCase(fetchAdminSingle.fulfilled, (state, action) => {
      state.current = action.payload
      state.loading = false
    })
    builder.addCase(fetchAdminList.rejected, (state) => {
      state.entities = []
      state.totalRecords = 0
      state.loading = false
    })
    builder.addCase(fetchAdminSingle.rejected, (state) => {
      state.current = {}
      state.loading = false
    })
  },
})
