import { Space } from 'antd'
import { Button } from 'primereact/button'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import TableEditButton from '../../../components/Templates/Button/TableEditButton'
import TableDeleteButton from '../../../components/Templates/Button/TableRemoveButton'
import Card from '../../../components/Templates/Card'
import Table from '../../../components/Templates/Table/Table'
import TableCaption from '../../../components/Templates/TableCaption'
import { PrivateRoute } from '../../../helpers/PrivateRoute'
import { useBaseFunction } from '../../../layouts/AppContext'
import { useLoading } from '../../../layouts/FullScreenLoading/FullScreenLoading'
import { fetchCountry } from '../../../redux/slice/country.slice'
import CountrySchema from '../../../shared/schema/country.schema'
import { deleteCountry } from '../../../shared/services/country.service'
export default function CountryListing() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const loading = useLoading()
  const base = useBaseFunction()
  const { entities, loading: countryLoading, totalRecords } = useSelector((state) => state.country)
  const { auth } = useSelector((state) => state.admin)
  const [table, setTable] = useState({
    first: 0,
    rows: 10,
    page: 1,
    sortField: null,
    sortOrder: null,
    filters: {
      name: { value: '' },
      unit: { value: '' },
    },
  })
  const schema = new CountrySchema(loading, base, auth)
  const columns = [
    {
      name: 'ประเทศ',
      key: 'name',
      width: '70%',
      sortable: true,
    },
    {
      name: 'สกุลเงิน',
      key: 'unit',
      width: '20%',
      sortable: true,
    },
    {
      key: 'action',
      width: '150px',
      render: (record) => (
        <Space size='small'>
          <TableEditButton id={record.id} onClick={handleEdit} />
          <TableDeleteButton id={record.id} onClick={handleRemove} />
        </Space>
      ),
    },
  ]
  const fetchData = useCallback(() => {
    dispatch(
      fetchCountry({
        ...table,
      })
    )
  }, [table, dispatch])
  const handleEdit = (id) => {
    navigate(`/admin/country/edit/${id}`)
  }
  useEffect(() => {
    fetchData()
  }, [fetchData])

  schema.setRemoveFunction(async (...props) => {
    const { data } = await deleteCountry(...props)
    if (data) {
      fetchData()
    }
  })
  const handleCreate = () => {
    navigate('/admin/country/new')
  }
  const handleRemove = (id) => {
    schema.onRemove(id, auth.token)
  }
  return (
    <PrivateRoute>
      <TableCaption
        render={
          <Space size='small' className='mb-2'>
            <Button
              className='p-button-secondary'
              icon='pi pi-refresh'
              onClick={fetchData}
              loading={countryLoading}
            />
            <Button icon='pi pi-plus' onClick={handleCreate} />
          </Space>
        }
      />
      <Card header='ข้อมูลประเทศ'>
        <Table
          dataKey='id'
          value={entities}
          totalRecords={totalRecords}
          loading={countryLoading}
          table={table}
          setTable={setTable}
          columns={columns}
        />
      </Card>
    </PrivateRoute>
  )
}
