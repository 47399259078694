import { Space } from 'antd'
import { Button } from 'primereact/button'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import TableEditButton from '../../../components/Templates/Button/TableEditButton'
import TableDeleteButton from '../../../components/Templates/Button/TableRemoveButton'
import Card from '../../../components/Templates/Card'
import Table from '../../../components/Templates/Table/Table'
import TableCaption from '../../../components/Templates/TableCaption'
import { PrivateRoute } from '../../../helpers/PrivateRoute'
import { useBaseFunction } from '../../../layouts/AppContext'
import { useLoading } from '../../../layouts/FullScreenLoading/FullScreenLoading'
import { fetchRoles } from '../../../redux/slice/role.slice'
import RoleSchema from '../../../shared/schema/role.schema'
import { deleteRole } from '../../../shared/services/role.service'

export default function RoleListing() {
  const { auth } = useSelector((state) => state.admin)
  const { entities, totalRecords, loading: roleLoading } = useSelector((state) => state.role)
  const dispatch = useDispatch()
  const loading = useLoading()
  const navigate = useNavigate()
  const base = useBaseFunction()
  const [table, setTable] = useState({
    first: 0,
    rows: 10,
    page: 1,
    filters: {
      name: { value: '' },
    },
  })
  const schema = new RoleSchema(loading, base, auth)
  const fetchData = useCallback(() => {
    dispatch(fetchRoles(table))
  }, [dispatch, table])
  useEffect(() => {
    fetchData()
  }, [fetchData])
  const columns = [
    {
      key: 'name',
      name: 'บทบาท',
      width: '90%',
      sortable: true,
    },
    {
      key: 'action',
      width: '150px',
      render: (record) => (
        <Space size='small'>
          <TableEditButton id={record.id} onClick={onClickEdit} />
          <TableDeleteButton id={record.id} onClick={handleRemove} />
        </Space>
      ),
    },
  ]

  schema.setRemoveFunction(async (...props) => {
    const { data } = await deleteRole(...props)
    if (data) {
      fetchData()
    }
  })
  const handleRemove = (id) => {
    schema.setRemoveFunction(async () => {
      const { data } = await deleteRole(id, auth.token)
      if (data) {
        fetchData()
      }
    })
    schema.onRemove(id)
  }
  const onClickEdit = (id) => navigate(`/admin/role/edit/${id}`)
  const onClickAdd = () => navigate('/admin/role/new')
  return (
    <PrivateRoute>
      <TableCaption
        render={
          <Space size='small' className='mb-2'>
            <Button
              className='p-button-secondary'
              icon='pi pi-refresh'
              onClick={fetchData}
              loading={roleLoading}
            />
            <Button icon='pi pi-plus' onClick={onClickAdd} />
          </Space>
        }
      />
      <Card header='ข้อมูลบทบาทการใช้งานระบบ'>
        <Table
          value={entities}
          totalRecords={totalRecords}
          loading={roleLoading}
          table={table}
          setTable={setTable}
          columns={columns}
        />
      </Card>
    </PrivateRoute>
  )
}
