import { object, string } from 'yup'
import { SchemaTemplate } from './template.schema'

export default class ProgramUploadSchema extends SchemaTemplate {
  nameValidation = string().uuid('รูปแบบ UUID ไม่ถูกต้อง').required('ต้องระบุ {}!')
  validation = object().shape({
    uploadTypeId: this.nameValidation,
  })

  defaultValue = {
    uploadTypeId: '',
  }
}
