import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import { InputTextarea } from 'primereact/inputtextarea'
import React from 'react'
import InlineLabel from '../../../components/Templates/InlineLabel'
export function ScholarshipForm({ formik, handleClose }) {
  const { errors, touched, handleChange, values, handleSubmit } = formik
  return (
    <form onSubmit={handleSubmit}>
      <InlineLabel errors={errors} touched={touched} label='การรับสมัคร' name='name' required>
        <InputText onChange={handleChange} value={values.name} />
      </InlineLabel>
      <InlineLabel errors={errors} touched={touched} label='คำอธิบาย' name='desc' required>
        <InputTextarea onChange={handleChange} value={values.desc} rows={5} />
      </InlineLabel>
      <InlineLabel errors={errors} touched={touched} label='ลิงก์' name='link'>
        <InputText onChange={handleChange} value={values.link} />
      </InlineLabel>
      <div className='d-flex justify-content-end pt-3 gap-2'>
        <Button type='reset' label='ยกเลิก' className='p-button-secondary' onClick={handleClose} />
        <Button type='submit' label='บันทึก' />
      </div>
    </form>
  )
}
