import React from 'react'
import Loading from '../Loading/Loading'

export default function Card({ children, header, loading = false }) {
  return (
    <div className='card mb-4'>
      {header && <div className='card-header'>{header} </div>}
      <div className={'card-body' + (loading ? ' app-loading' : '')}>{loading ? <Loading /> : children}</div>
    </div>
  )
}
