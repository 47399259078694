import React, { useRef } from 'react'
import { Button, Col, Row } from 'antd'
import ImagePreviewItem from './ImagePreviewItem'

const ImagesPicker = ({ value, onChange, multiple = false }) => {
  const fileInputRef = useRef(null)

  const onFileInputChange = (e) => {
    const { files: inputFiles } = e.target

    if (inputFiles && inputFiles.length > 0) {
      if (multiple) {
        for (let file of inputFiles) {
          onChange([...value, file])
        }
      } else {
        const file = inputFiles[0]
        onChange(file)
      }
    }
  }

  const removeFile = (index) => {
    if (multiple) {
      onChange(value.filter((i) => i !== value[index]))
    } else {
      onChange(null)
    }
  }

  const getUrl = (file) => {
    if (file instanceof File) {
      return URL.createObjectURL(file)
    }
    return file
  }
  const getValue = () => {
    if (!value) return []
    if (multiple) {
      return value
    } else {
      return [value]
    }
  }
  const _value = getValue()
  return (
    <Row>
      <Col span={24}>
        <Button
          icon={<span className='pi pi-images mr-2'></span>}
          onClick={() => fileInputRef.current.click()}
        >
          เลือกรูปภาพ
        </Button>
        <input type='file' hidden accept='image/*' ref={fileInputRef} onChange={onFileInputChange} />
      </Col>
      <Col span={24}>
        <Row gutter={12}>
          {_value.map((im, i) => (
            <Col key={im}>
              <ImagePreviewItem src={getUrl(im)} onRemove={() => removeFile(i)} />
            </Col>
          ))}
        </Row>
      </Col>
    </Row>
  )
}

export default ImagesPicker
