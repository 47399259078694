import { Button } from 'primereact/button'
import { InputNumber } from 'primereact/inputnumber'
import { InputText } from 'primereact/inputtext'
import React from 'react'
import InlineLabel from '../../../components/Templates/InlineLabel'
export function CostForm({ formik, handleClose, onCallProgram }) {
  const { errors, touched, handleChange, values, handleSubmit } = formik
  return (
    <form onSubmit={handleSubmit}>
      <InlineLabel errors={errors} touched={touched} label='ค่าใช้จ่าย' name='name' required>
        <InputText onChange={handleChange} value={values.name} />
      </InlineLabel>
      <InlineLabel errors={errors} touched={touched} label='จำนวน' name='value1' required>
        <InputNumber onValueChange={handleChange} value={values.value1} maxLength={8} />
      </InlineLabel>
      <InlineLabel errors={errors} touched={touched} label='ไม่เกิน' name='value2'>
        <InputNumber onValueChange={handleChange} value={values.value2} maxLength={8} />
      </InlineLabel>
      <div className='d-flex justify-content-end pt-3 gap-2'>
        <Button type='reset' label='ยกเลิก' className='p-button-secondary' onClick={handleClose} />
        <Button
          label='นำเข้าจากโปรแกรม'
          className='p-button-help'
          onClick={onCallProgram}
        />
        <Button type='submit' label='บันทึก' />
      </div>
    </form>
  )
}
