import { Button } from 'primereact/button'
import { InputNumber } from 'primereact/inputnumber'
import React from 'react'
import InlineLabel from '../../../components/Templates/InlineLabel'
import ListOfValue from '../../../components/Templates/ListOfValue/ListOfValue'
export function ScoreUniversityForm({ formik, handleClose }) {
  const { errors, touched, handleChange, values, handleSubmit, setFieldValue } = formik
  return (
    <form onSubmit={handleSubmit}>
      <InlineLabel errors={errors} touched={touched} label='คะแนน' name='scoreId' required>
        <ListOfValue
          setFieldValue={setFieldValue}
          value={values.scoreId}
          callTo='/score/list-of-value'
          displayField='name'
          field='id'
        />
      </InlineLabel>
      <InlineLabel errors={errors} touched={touched} label='ระดับการศึกษา' name='studyLevelId' required>
        <ListOfValue
          setFieldValue={setFieldValue}
          value={values.studyLevelId}
          callTo='/study-level/list-of-value'
          displayField='name'
          field='id'
        />
      </InlineLabel>
      <InlineLabel errors={errors} touched={touched} label='จำนวน (คะแนนขั้นต่ำ)' name='point'>
        <InputNumber onValueChange={handleChange} value={values.point} minFractionDigits='2' />
      </InlineLabel>
      <div className='d-flex justify-content-end pt-3 gap-2'>
        <Button type='reset' label='ยกเลิก' className='p-button-secondary' onClick={handleClose} />
        <Button type='submit' label='บันทึก' />
      </div>
    </form>
  )
}
