import axios from 'axios'
import { AppContextPath } from '../constant/_environment'
export const deleteAdmin = async (adminId, token) => {
  return await axios.delete(`${AppContextPath.ADMIN_URL}/admin/delete/${adminId}`, {
    headers: { Authorization: `Bearer ${token}` },
  })
}

export const updateAdmin = async (payload, adminId, token) => {
  return await axios.put(`${AppContextPath.ADMIN_URL}/admin/update/${adminId}`, payload, {
    headers: { Authorization: `Bearer ${token}` },
  })
}

export const createAdmin = async (payload, token) => {
  return await axios.post(`${AppContextPath.ADMIN_URL}/admin/new`, payload, {
    headers: { Authorization: `Bearer ${token}` },
  })
}
