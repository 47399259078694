import React from 'react'
import { CloseCircleFilled } from '@ant-design/icons'
import styled from 'styled-components'

const Wrapper = styled.div`
  position: relative;
  border: 1px solid #000;
  width: fit-content;
  margin-top: 10px;
`

const RemoveButton = styled.a`
  padding: 5px;
  position: absolute;
  top: 0;
  right: 0;
`

const FilePreview = (props) => {
  const { src, onRemove } = props
  if (!src) return <div />

  return (
    <Wrapper>
      <img src={src} style={{ height: 150, width: '100%', objectFit: 'cover' }} alt='preview-item' />
      <RemoveButton onClick={onRemove}>
        <CloseCircleFilled style={{ color: 'red', fontSize: 30, objectFit: 'cover' }} />
      </RemoveButton>
    </Wrapper>
  )
}

export default FilePreview
