import { boolean, number, object, string } from 'yup'
import { SchemaTemplate } from './template.schema'

export default class UniversitySchema extends SchemaTemplate {
  validation = object().shape({
    name: string().required('จำเป็นต้องกรอก {}'),
    desc: string().required('จำเป็นต้องกรอก {}'),
    link: string(),
    recommended: boolean().default(false),
    site: string(),
    address: string(),
    hashtag: string(),
    countryId: string().uuid().nullable(),
    cityId: string().uuid().nullable(),
    grade: number("{} จะต้องเป็นตัวเลขเท่านั้น").nullable(),
    ranking: number().integer().nullable(),
    totalStudent: number().integer().nullable(),
  })

  defaultValue = {
    name: '',
    desc: '',
    link: '',
    recommended: false,
    site: '',
    address: '',
    hashtag: '',
    countryId: null,
    cityId: null,
    grade: null,
    ranking: null,
    totalStudent: null,
    about: '',
    about2: '',
    about3: '',
    logoPath: null,
    images: [],
  }
}
