import { Button } from 'primereact/button'
import { Dropdown } from 'primereact/dropdown'
import { Fragment, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLoading } from '../../layouts/FullScreenLoading/FullScreenLoading'
import { changeRole } from '../../redux/slice/admin.slice'
export default function ChangeRole({ roles, currentRole }) {
  const dropdownStyle = { height: '40px', display: 'flex', alignItems: 'center' }
  const dispatch = useDispatch()
  const { setLoading } = useLoading()
  const [role, setRole] = useState(currentRole)
  const handleSave = () => {
    dispatch(changeRole(role))
    setLoading(true)
    setTimeout(() => window.location.reload(), 1000)
  }
  return (
    <Fragment>
      <div className='my-2'>
        <Dropdown
          options={roles}
          optionLabel='name'
          value={role}
          style={dropdownStyle}
          placeholder={currentRole?.name}
          onChange={(e) => setRole(e.target.value)}
        />
      </div>
      <div className='flex justify-content-end'>
        <Button onClick={handleSave}>บันทึก</Button>
      </div>
    </Fragment>
  )
}
