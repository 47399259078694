import { object, string } from 'yup'
import { SchemaTemplate } from './template.schema'

export default class LanguageTypeSchema extends SchemaTemplate {
  nameValidation = string().required('โปรดระบุ {}!')
  validation = object().shape({
    name: this.nameValidation,
  })

  defaultValue = {
    name: '',
  }
}
