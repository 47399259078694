import { useFormik } from 'formik'
import { Dialog } from 'primereact/dialog'
import { InputNumber } from 'primereact/inputnumber'
import { Fragment, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ActionInRow from '../../components/Templates/ActionInRow'
import ListOfValue from '../../components/Templates/ListOfValue/ListOfValue'
import Table from '../../components/Templates/Table/Table'
import TableInput from '../../components/Templates/TableInput'
import { useBaseFunction } from '../../layouts/AppContext'
import { useLoading } from '../../layouts/FullScreenLoading/FullScreenLoading'
import { fetchScoreUniversityList } from '../../redux/slice/score-university.slice'
import ScoreUniversitySchema from '../../shared/schema/score-university.schema'
import {
  createScoreUniversity,
  deleteScoreUniversity,
  updateScoreUniversity
} from '../../shared/services/score-university.service'
import { ScoreUniversityForm } from './Form/ScoreProgramForm'

export default function LanguageScoreListing({ universityId, visibleDialog, hideDialog }) {
  const dispatch = useDispatch()
  const {
    entities,
    totalRecords,
    loading: scoreUniversityLoading,
  } = useSelector((state) => state.scoreUniversity)
  const base = useBaseFunction()
  const { auth } = useSelector((state) => state.admin)
  const loading = useLoading()
  const schema = new ScoreUniversitySchema(loading, base, auth)
  const [table, setTable] = useState({
    first: 0,
    rows: 10,
    page: 1,
    sortField: null,
    sortOrder: null,
    filters: {
      name: { value: '' },
      desc: { value: '' },
      link: { value: '' },
    },
  })
  const fetchData = useCallback(() => {
    dispatch(
      fetchScoreUniversityList({
        ...table,
        universityId,
      })
    )
  }, [table, universityId, dispatch])
  useEffect(() => {
    fetchData()
  }, [fetchData])
  schema.setUpdateFunction(async (...props) => {
    await updateScoreUniversity(...props)
    fetchData()
  })
  schema.setCrateFunction(async (...props) => {
    await createScoreUniversity(...props)
    handleClose()
    fetchData()
  })
  schema.setRemoveFunction(async (...props) => {
    const { data } = await deleteScoreUniversity(...props)
    if (data) {
      fetchData()
    }
  })

  const handleRemove = (scholarshipId) => {
    schema.onRemove(scholarshipId, auth.token)
  }
  const columns = [
    {
      name: 'คะแนน',
      key: 'score.id',
      width: '30%',
      align: 'left',
      editor: (options) => (
        <TableInput options={options} validate={schema.nameValidation} placeholder='คะแนน'>
          <ListOfValue callTo='/score/list-of-value' name='name' displayField='name' field='id' />
        </TableInput>
      ),
      render: (record) => <span>{record.score.name}</span>,
    },
    {
      name: 'ระดับการศึกษา',
      key: 'studyLevel.id',
      width: '30%',
      align: 'left',
      editor: (options) => (
        <TableInput options={options} validate={schema.nameValidation} placeholder='ระดับการศึกษา'>
          <ListOfValue
            callTo='/study-level/list-of-value'
            name='studyLevelId'
            displayField='name'
            field='id'
          />
        </TableInput>
      ),
      render: (record) => <span>{record.studyLevel.name}</span>,
    },
    {
      name: 'จำนวน (คะแนนขั้นต่ำ)',
      key: 'point',
      width: '30%',
      align: 'center',
      editor: (options) => (
        <TableInput options={options} validate={schema.nameValidation} placeholder='จำนวน (คะแนนขั้นต่ำ)'>
          <InputNumber minFractionDigits='2' />
        </TableInput>
      ),
    },
    {
      key: 'action',
      width: '150px',
      rowEditor: true,
      render: (record, { rowEditor }) => (
        <ActionInRow record={record} rowEditor={rowEditor} handleRemove={handleRemove} />
      ),
    },
  ]
  const formik = useFormik({
    initialValues: schema.defaultValue,
    validationSchema: schema.validation,
    onSubmit: (values) => {
      schema.handleSubmit({
        ...values,
        universityId,
      })
    },
  })
  const handleRowSave = async (e) => {
    const { newData } = e
    newData.studyLevelId = newData['studyLevel.id']
    newData.scoreId = newData['score.id']
    schema.setIdentified(newData.id)
    schema.handleSubmit(newData, false)
  }
  const handleClose = () => {
    hideDialog()
    formik.resetForm(schema.defaultValue)
    formik.setErrors({})
  }
  return (
    <Fragment>
      <Dialog
        header='เพิ่มข้อมูลคะแนนภาษา'
        draggable={false}
        modal={true}
        visible={visibleDialog}
        style={{ maxWidth: '50vw', minWidth: '450px' }}
        onHide={hideDialog}
      >
        <ScoreUniversityForm formik={formik} handleClose={handleClose} />
      </Dialog>
      <Table
        onRowEditComplete={handleRowSave}
        editMode='row'
        validate={schema.validation}
        dataKey='id'
        value={entities}
        totalRecords={totalRecords}
        loading={scoreUniversityLoading}
        table={table}
        setTable={setTable}
        columns={columns}
      />
    </Fragment>
  )
}
