import { useFormik } from 'formik'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import Card from '../../components/Templates/Card'
import { PrivateRoute } from '../../helpers/PrivateRoute'
import { useBaseFunction } from '../../layouts/AppContext'
import { useLoading } from '../../layouts/FullScreenLoading/FullScreenLoading'
import ApplicationSchema from '../../shared/schema/application.schema'
import ApplicationForm from './Form/ApplicationForm'
import * as ObjectUtils from '../../utils/object.utils'
import { fetchApplicationSingle } from '../../redux/slice/application.slice'
import UserInfomation from './Form/UserInfomation'
import { updateApplication } from '../../shared/services/application.service'
import DisplayRegisterForm from './Form/DisplayRegisterForm'
export default function ApplicationSingle() {
  const loading = useLoading()
  const base = useBaseFunction()
  const dispatch = useDispatch()
  const { applicationId } = useParams()
  const { auth } = useSelector((state) => state.admin)
  const { current: application } = useSelector((state) => state.application)
  const schema = useMemo(() => new ApplicationSchema(loading, base, auth), [loading, base, auth])
  const [initialValues, setInitialValues] = useState(schema.defaultValue)
  const fetchData = useCallback(
    () => dispatch(fetchApplicationSingle(applicationId)),
    [dispatch, applicationId]
  )
  schema.setIdentified(applicationId)
  schema.setUpdateFunction(async (...props) => {
    await updateApplication(...props)
    fetchData()
  })
  const formik = useFormik({
    initialValues,
    validationSchema: schema.validation,
    onSubmit: (values) => schema.handleSubmit(values),
    enableReinitialize: true,
  })
  useEffect(() => {
    if (ObjectUtils.isNotEmpty(application)) {
      setInitialValues(ObjectUtils.copy(schema.defaultValue, application))
    }
  }, [application, schema])
  useEffect(() => {
    fetchData()
  }, [fetchData])
  return (
    <PrivateRoute>
      <Card header='ข้อมูลผู้สมัคร'>
        <UserInfomation formik={formik} />
      </Card>
      <Card header='ใบสมัคร' loading={!application?.id}>
        <DisplayRegisterForm applicationId={application.id} />
      </Card>
      <Card header='Tracking Process'>
        <ApplicationForm formik={formik} fetchData={fetchData} application={application} />
      </Card>
    </PrivateRoute>
  )
}
