export default class FilterUtils {
  static getParams(filters) {
    const params = {}
    Object.keys(filters).forEach((filter) => {
      if (typeof filters[filter].value === 'string') {
        params[filter] = filters[filter].value.toLowerCase() ?? ''
      } else {
        params[filter] = filters[filter].value
      }
    })
    return { ...params }
  }
}
