import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import ImagePicker from '../../../../components/ImagePicker'
import InlineLabel from '../../../../components/Templates/InlineLabel'

export default function BankForm({ formik }) {
  const { values, errors, handleSubmit, touched, handleChange, setFieldValue } = formik
  return (
    <form onSubmit={handleSubmit}>
      <InlineLabel errors={errors} touched={touched} label='ชื่อธนาคาร' name='name' required>
        <InputText onChange={handleChange} value={values.name} />
      </InlineLabel>
      <InlineLabel errors={errors} touched={touched} label='โลโก้' name='logo' required>
        <ImagePicker value={values.logo} onChange={(e) => setFieldValue('logo', e)} accept='image/*' />
      </InlineLabel>
      <div className='d-flex justify-content-end gap-2 m-3'>
        <Button type='submit' label='บันทึก' />
      </div>
    </form>
  )
}
