import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import thunkMiddleware from 'redux-thunk'

import { setupListeners } from '@reduxjs/toolkit/dist/query'
import { rootReducers } from './rootReducers'
import { configureStore } from '@reduxjs/toolkit'

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['admin', 'i18n'],
}

const persistedReducer = persistReducer(persistConfig, rootReducers)
const reducer = {
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(thunkMiddleware),
}
export const store = configureStore(reducer)
export const persistor = persistStore(store)

setupListeners(store.dispatch)
