import { Button } from 'primereact/button'
import { InputSwitch } from 'primereact/inputswitch'
import { InputText } from 'primereact/inputtext'
import { InputTextarea } from 'primereact/inputtextarea'
import { InputNumber } from 'primereact/inputnumber'
import React, { Fragment } from 'react'
import InlineLabel from '../../../components/Templates/InlineLabel'
import ListOfValue from '../../../components/Templates/ListOfValue/ListOfValue'
import ImagePicker from '../../../components/ImagePicker'
import FormUtils from '../../../utils/form.utils'
export default function UniversityForm({ formik }) {
  const aboutInlineStyle = { display: 'flex', justifyContent: 'space-between', gap: '10px' }
  const { errors, touched, handleSubmit, handleChange, values, setFieldValue } = formik
  return (
    <Fragment>
      <form onSubmit={handleSubmit}>
        <InlineLabel errors={errors} touched={touched} label='ชื่อมหาวิทยาลัย' name='name' required>
          <InputText onChange={handleChange} value={values.name} />
        </InlineLabel>
        <InlineLabel errors={errors} touched={touched} label='ตัวอย่างลิงก์' name='name'>
          <div className='text-right'>{`/${FormUtils.getSlug(values.name)}`}</div>
        </InlineLabel>
        <InlineLabel errors={errors} touched={touched} label='คำอธิบาย' name='desc' required>
          <InputTextarea rows={5} cols={30} onChange={handleChange} value={values.desc} />
        </InlineLabel>
        <InlineLabel errors={errors} touched={touched} label='ลิงก์การสมัคร' name='link'>
          <InputText type='url' onChange={handleChange} value={values.link} />
        </InlineLabel>
        <InlineLabel errors={errors} touched={touched} label='Partner University' name='recommended'>
          <InputSwitch onChange={handleChange} checked={values.recommended} />
        </InlineLabel>
        <InlineLabel errors={errors} touched={touched} label='เว็บไซต์มหาวิทยาลัย' name='site'>
          <InputText type='url' onChange={handleChange} value={values.site} />
        </InlineLabel>
        <InlineLabel errors={errors} touched={touched} label='ที่อยู่' name='address'>
          <InputTextarea rows={5} cols={30} onChange={handleChange} value={values.address} />
        </InlineLabel>

        <InlineLabel errors={errors} touched={touched} label='เกี่ยวกับมหาวิทยาลัย' name='about'>
          <div style={aboutInlineStyle}>
            <InputText onChange={handleChange} value={values.about} name='about' />
            <InputText onChange={handleChange} value={values.about2} name='about2' />
            <InputText onChange={handleChange} value={values.about3} name='about3' />
          </div>
        </InlineLabel>
        <InlineLabel errors={errors} touched={touched} label='แฮชแท็ก' name='hashtag'>
          <InputText onChange={handleChange} value={values.hashtag} />
        </InlineLabel>
        <InlineLabel errors={errors} touched={touched} label='ประเทศ' name='countryId'>
          <ListOfValue
            setFieldValue={setFieldValue}
            value={values.countryId}
            callTo='/country/list-of-value'
            displayField='name'
            field='id'
          />
        </InlineLabel>
        <InlineLabel errors={errors} touched={touched} label='เมือง' name='cityId'>
          <ListOfValue
            setFieldValue={setFieldValue}
            value={values.cityId}
            callTo='/city/list-of-value'
            displayField='name'
            field='id'
            parameters={{
              countryId: values.countryId,
            }}
          />
        </InlineLabel>
        <InlineLabel errors={errors} touched={touched} label='เกรดขั้นต่ำ' name='grade'>
          <InputNumber
            min={0}
            max={4}
            onValueChange={handleChange}
            value={values.grade}
            minFractionDigits={2}
            maxFractionDigits={2}
          />
        </InlineLabel>
        <InlineLabel errors={errors} touched={touched} label='อันดับมหาวิทยาลัย' name='ranking'>
          <InputNumber onValueChange={handleChange} value={values.ranking} min={0} />
        </InlineLabel>
        <InlineLabel errors={errors} touched={touched} label='จำนวนนักศึกษา' name='totalStudent'>
          <InputNumber min={0} onValueChange={handleChange} value={values.totalStudent} />
        </InlineLabel>
        <InlineLabel errors={errors} touched={touched} label='โลโก้มหาวิทยาลัย' name='logoPath'>
          <ImagePicker value={values.logoPath} onChange={(logo) => setFieldValue('logoPath', logo)} />
        </InlineLabel>
        <InlineLabel errors={errors} touched={touched} label='รูปมหาวิทยาลัย' name='images'>
          <ImagePicker
            value={values.images}
            onChange={(images) => setFieldValue('images', images)}
            multiple
          />
        </InlineLabel>
        <div className='d-flex justify-content-end p-4'>
          <Button label='บันทึก' type='submit' />
        </div>
      </form>
    </Fragment>
  )
}
