import axios from 'axios'
import { AppContextPath } from '../constant/_environment'
export const deleteScholarship = async (scholarshipId, token) => {
  return await axios.delete(`${AppContextPath.ADMIN_URL}/scholarship/delete/${scholarshipId}`, {
    headers: { Authorization: `Bearer ${token}` },
  })
}

export const updateScholarship = async (payload, scholarshipId, token) => {
  return await axios.put(`${AppContextPath.ADMIN_URL}/scholarship/update/${scholarshipId}`, payload, {
    headers: { Authorization: `Bearer ${token}` },
  })
}

export const createScholarship = async (payload, token) => {
  return await axios.post(`${AppContextPath.ADMIN_URL}/scholarship/new`, payload, {
    headers: { Authorization: `Bearer ${token}` },
  })
}
