import { Space } from 'antd'
import { useFormik } from 'formik'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { InputText } from 'primereact/inputtext'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ActionInRow from '../../components/Templates/ActionInRow'
import Card from '../../components/Templates/Card'
import ListOfValue from '../../components/Templates/ListOfValue/ListOfValue'
import Table from '../../components/Templates/Table/Table'
import TableCaption from '../../components/Templates/TableCaption'
import TableInput from '../../components/Templates/TableInput'
import { PrivateRoute } from '../../helpers/PrivateRoute'
import { useBaseFunction } from '../../layouts/AppContext'
import { useLoading } from '../../layouts/FullScreenLoading/FullScreenLoading'
import { fetchScoreList } from '../../redux/slice/score.slice'
import ScoreSchema from '../../shared/schema/score.schema'
import { createScore, deleteScore, updateScore } from '../../shared/services/score.service'
import { ScoreForm } from './Form/ScoreForm'
export default function ScoreListing() {
  const dispatch = useDispatch()
  const loading = useLoading()
  const base = useBaseFunction()
  const { entities, loading: scoreCategoryLoading, totalRecords } = useSelector((state) => state.score)
  const { auth } = useSelector((state) => state.admin)
  const [visibleDialog, setVisibleDialog] = useState()
  const [table, setTable] = useState({
    first: 0,
    rows: 10,
    page: 1,
    sortField: null,
    sortOrder: null,
    filters: {
      name: { value: '' },
      'score_category.name': { value: '' },
    },
  })
  const schema = new ScoreSchema(loading, base, auth)
  const columns = [
    {
      name: 'ประเภทคะแนนภาษา',
      key: 'name',
      width: '50%',
      sortable: true,
      editor: (options) => (
        <TableInput options={options} validate={schema.nameValidation} placeholder='ประเภทคะแนนภาษา'>
          <InputText />
        </TableInput>
      ),
    },
    {
      name: 'หมวดหมู่',
      key: 'score_category.id',
      width: '40%',
      sortable: true,
      editor: (options) => (
        <TableInput options={options} validate={schema.nameValidation} placeholder='ประเภทคะแนนภาษา'>
          <ListOfValue
            callTo='/score-category/list-of-value'
            name='categoryId'
            displayField='name'
            field='id'
          />
        </TableInput>
      ),
      render: (record) => <span>{record.score_category.name}</span>,
    },
    {
      key: 'action',
      width: '150px',
      rowEditor: true,
      render: (record, { rowEditor }) => (
        <ActionInRow record={record} rowEditor={rowEditor} handleRemove={handleRemove} />
      ),
    },
  ]
  const fetchData = useCallback(() => {
    dispatch(
      fetchScoreList({
        ...table,
      })
    )
  }, [table, dispatch])
  useEffect(() => {
    fetchData()
  }, [fetchData])
  const formik = useFormik({
    initialValues: schema.defaultValue,
    validationSchema: schema.validation,
    onSubmit: (values) => {
      schema.handleSubmit(values)
    },
  })
  schema.setUpdateFunction(async (...props) => {
    await updateScore(...props)
    fetchData()
  })
  schema.setCrateFunction(async (...props) => {
    await createScore(...props)
    handleClose()
    fetchData()
  })
  schema.setRemoveFunction(async (...props) => {
    const { data } = await deleteScore(...props)
    if (data) {
      fetchData()
    }
  })
  const handleRemove = (id) => {
    schema.onRemove(id, auth.token)
  }
  const handleRowSave = async (e) => {
    const { newData } = e
    if (newData['score_category.id']) {
      newData.categoryId = newData['score_category.id']
    }
    schema.setIdentified(newData.id)
    schema.handleSubmit(newData, false)
  }
  const handleClose = () => {
    hideDialog()
    formik.resetForm(schema.defaultValue)
    formik.setErrors({})
  }
  const hideDialog = () => setVisibleDialog(false)
  const showDialog = () => setVisibleDialog(true)
  return (
    <PrivateRoute>
      <Dialog
        header='เพิ่มข้อมูลประเภทคะแนนภาษา'
        draggable={false}
        modal={true}
        visible={visibleDialog}
        style={{ maxWidth: '50vw', minWidth: '450px' }}
        onHide={hideDialog}
      >
        <ScoreForm formik={formik} handleClose={handleClose} />
      </Dialog>
      <TableCaption
        render={
          <Space size='small' className='mb-2'>
            <Button
              className='p-button-secondary'
              icon='pi pi-refresh'
              onClick={fetchData}
              loading={scoreCategoryLoading}
            />
            <Button icon='pi pi-plus' onClick={showDialog} />
          </Space>
        }
      />
      <Card header='ข้อมูลประเภทคะแนนภาษา'>
        <Table
          onRowEditComplete={handleRowSave}
          editMode='row'
          validate={schema.validation}
          dataKey='id'
          value={entities}
          totalRecords={totalRecords}
          loading={scoreCategoryLoading}
          table={table}
          setTable={setTable}
          columns={columns}
        />
      </Card>
    </PrivateRoute>
  )
}
