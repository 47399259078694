import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import _ from 'lodash'
import { AppContextPath } from '../../shared/constant/_environment'
import FilterUtils from '../../utils/filter.utils'

export const fetchCountry = createAsyncThunk(
  'country/fetchCountry ',
  async ({ first: offset = 0, rows: limit = 10, filters }, { getState }) => {
    const filterParam = FilterUtils.getParams(filters)
    const response = await axios({
      method: 'GET',
      url: `${AppContextPath.ADMIN_URL}/country/list`,
      headers: {
        Authorization: 'Bearer ' + getState().admin.auth.token,
      },
      params: { offset, limit, ...filterParam },
    })
    return _.get(response, 'data')
  }
)
export const fetchCountrySingle = createAsyncThunk(
  'country/fetchCountrySingle ',
  async (countryId, { getState }) => {
    const response = await axios({
      method: 'GET',
      url: `${AppContextPath.ADMIN_URL}/country/${countryId}`,
      headers: {
        Authorization: 'Bearer ' + getState().admin.auth.token,
      },
    })
    return _.get(response, 'data')
  }
)

export const countrySlice = createSlice({
  name: 'country',
  initialState: {
    current: {},
    entities: [],
    totalRecords: 0,
    loading: false,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCountry.pending, (state) => {
      state.loading = true
    })
    builder.addCase(fetchCountry.fulfilled, (state, action) => {
      state.entities = action.payload.rows
      state.loading = false
    })
    builder.addCase(fetchCountrySingle.pending, (state, action) => {
      state.current = action.payload
      state.loading = false
    })
    builder.addCase(fetchCountrySingle.fulfilled, (state, action) => {
      state.entities = action.payload.rows
      state.totalRecords = action.payload.count
      state.loading = false
    })
    builder.addCase(fetchCountry.rejected, (state) => {
      state.entities = []
      state.totalRecords = 0
      state.loading = false
    })
    builder.addCase(fetchCountrySingle.rejected, (state) => {
      state.current = {}
      state.loading = false
    })
  },
})
