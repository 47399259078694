import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import React from 'react'
import AppCalendar from '../../../components/Templates/Calendar'
import InlineLabel from '../../../components/Templates/InlineLabel'
export function ApplyingForm({ formik, handleClose }) {
  const { errors, touched, handleChange, values, handleSubmit, setFieldValue } = formik
  return (
    <form onSubmit={handleSubmit}>
      <InlineLabel errors={errors} touched={touched} label='รายละเอียด' name='desc'>
        <InputText onChange={handleChange} value={values.desc} />
      </InlineLabel>
      <InlineLabel errors={errors} touched={touched} label='วันที่เปิดรับสมัคร' name='startDate' required>
        <AppCalendar onChange={(e) => setFieldValue('startDate', e.value)} />
      </InlineLabel>
      <InlineLabel errors={errors} touched={touched} label='วันที่ปิดรับสมัคร' name='closeDate' required>
        <AppCalendar onChange={(e) => setFieldValue('closeDate', e.value)} />
      </InlineLabel>
      <div className='d-flex justify-content-end pt-3 gap-2'>
        <Button type='reset' label='ยกเลิก' className='p-button-secondary' onClick={handleClose} />
        <Button type='submit' label='บันทึก' />
      </div>
    </form>
  )
}
