import { object, string } from 'yup'
import { SchemaTemplate } from './template.schema'
export default class ProgramSchema extends SchemaTemplate {
  uuidValidation = string().required('ต้องระบุ {}!')

  validation = object().shape({
    // name:this.uuidValidation,
    programType : this.uuidValidation,
    universityId : this.uuidValidation,
    majorId : this.uuidValidation,
    language :this.uuidValidation


  })

  defaultValue = {
    name: '',
    link:'',
    programType:'',
    language:'',
    price:'',
    majorId:'',
    universityId:'',
    duration:'',

  }
}
