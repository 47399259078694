import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { InputText } from 'primereact/inputtext'
import { Fragment } from 'react'
import AppCalendar from '../Calendar'
export default function Table({
  value = [],
  loading = true,
  table,
  setTable,
  dataKey,
  totalRecords = 0,
  columns = [],
  editMode,
  onRowEditComplete,
  validate,
}) {
  const onValidate = (e) => {
    if (validate) {
      try {
        return validate.validateSync(e)
      } catch (e) {
        return false
      }
    } else {
      return true
    }
  }
  return (
    <DataTable
      editMode={editMode}
      value={value}
      paginator
      currentPageReportTemplate='แสดง {first} ถึง {last} จาก {totalRecords}'
      paginatorTemplate='FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown'
      rowEditValidator={onValidate}
      onRowEditComplete={onRowEditComplete}
      dataKey={dataKey}
      loading={loading}
      rows={table.rows}
      first={table.first}
      onPage={(e) => setTable(e)}
      onFilter={(e) => setTable(e)}
      totalRecords={totalRecords}
      lazy={true}
      filters={table.filters}
      responsiveLayout='stack'
      rowsPerPageOptions={[10, 25, 50]}
      rowHover
      filterDisplay='menu'
      emptyMessage='ไม่พบข้อมูล'
    >
      {columns.map((column) => (
        <Column
          filterField={column.key}
          key={column.key}
          filterMatchMode='contains'
          filter={column.sortable}
          filterElement={(props) => SearchFilter(props, `ค้นหา${column.name}`, column.type)}
          showFilterOperator={false}
          showFilterMatchModes={false}
          showFilterMenuOptions={false}
          field={column.key}
          header={column.name}
          editor={column.editor}
          alignHeader='center'
          filtered
          align={column.align}
          style={{ width: column.width, fontFamily: 'kanit' }}
          body={column.render}
          rowEditor={column.rowEditor}
        />
      ))}
    </DataTable>
  )
}

const SearchFilter = ({ filterCallback, value }, placeholder, type = 'text') => {
  if (type === 'text') {
    return (
      <Fragment>
        <InputText
          value={value ?? ''}
          onChange={(e) => filterCallback(e.target.value)}
          placeholder={placeholder}
        />
      </Fragment>
    )
  } else if (type === 'date') {
    return (
      <Fragment>
        <AppCalendar
          value={value ?? null}
          onChange={(e) => filterCallback(e.target.value)}
          placeholder={placeholder}
        />
      </Fragment>
    )
  }
}
