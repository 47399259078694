export default class FormUtils {
  static get yup() {
    return {
      transformNumber: (_, val) => (val === Number(val) ? val : null),
    }
  }

  static getSlug(message = '') {
    return message
      .trim()
      .replace(/[~!@#$%^&*()\-_=+{}[\]\\|;:'‘’"“”,.<>/?]/g, ' ') // replace special characters with space
      .replace(/ /g, '-') // replace spaces with hyphen
      .replace(/--+/g, '-') // collapse consecutive hyphens
      .replace(/-+$/, '') // remove trailing hyphens
  }
}
