import { Space } from 'antd'
import { Button } from 'primereact/button'
import { Image } from 'primereact/image'
import { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import TableEditButton from '../../../components/Templates/Button/TableEditButton'
import TableDeleteButton from '../../../components/Templates/Button/TableRemoveButton'
import Card from '../../../components/Templates/Card'
import Table from '../../../components/Templates/Table/Table'
import TableCaption from '../../../components/Templates/TableCaption'
import { PrivateRoute } from '../../../helpers/PrivateRoute'
import { useBaseFunction } from '../../../layouts/AppContext'
import { useLoading } from '../../../layouts/FullScreenLoading/FullScreenLoading'
import { fetchBankList } from '../../../redux/slice/bank.slice'
import useTable from '../../../shared/hook/useTable'
import BankSchema from '../../../shared/schema/bank.schema'
import { deleteBank } from '../../../shared/services/bank.service'

export default function BankListing() {
  const dispatch = useDispatch()
  const [table, setTable] = useTable({ name: { value: '' } })
  const loader = useLoading()
  const navigate = useNavigate()
  const { auth } = useSelector((state) => state.admin)
  const { entities, loading, totalRecords } = useSelector((state) => state.bank)
  const base = useBaseFunction()
  const schema = useMemo(() => new BankSchema(loader, base, auth), [loader, base, auth])
  schema.setRemoveFunction(async (...props) => {
    await deleteBank(...props)
  })
  const fetchData = useCallback(() => {
    dispatch(fetchBankList(table))
  }, [dispatch, table])
  useEffect(() => {
    fetchData()
  }, [fetchData])
  const handleRemove = (id) => {
    schema.onRemove(id)
    fetchData()
  }
  const columns = [
    {
      name: 'โลโก้',
      key: 'logo',
      width: '10%',
      align: 'left',
      render: (record) => <Image src={record.logo} preview width='75px' />,
    },
    {
      name: 'ธนาคาร',
      key: 'name',
      width: '90%',
      align: 'left',
      sortable: true,
    },
    {
      key: 'action',
      width: '150px',
      render: (record) => (
        <div className='flex gap-2'>
          <TableEditButton id={record.id} onClick={(id) => navigate('/admin/bank/edit/' + id)} />
          <TableDeleteButton id={record.id} onClick={handleRemove} />
        </div>
      ),
    },
  ]
  return (
    <PrivateRoute>
      <TableCaption
        render={
          <Space size='small' className='mb-2'>
            <Button
              className='p-button-secondary'
              icon='pi pi-refresh'
              onClick={fetchData}
              loading={loading}
            />
            <Button icon='pi pi-plus' onClick={() => navigate('/admin/bank/new')} />
          </Space>
        }
      />
      <Card header='ข้อมูลธนาคาร'>
        <Table
          dataKey='id'
          value={entities}
          totalRecords={totalRecords}
          loading={loading}
          table={table}
          setTable={setTable}
          columns={columns}
        />
      </Card>
    </PrivateRoute>
  )
}
