import axios from 'axios'
import { AppContextPath } from '../constant/_environment'
export const deleteScoreUniversity = async (scoreUniversityId, token) => {
  return await axios.delete(`${AppContextPath.ADMIN_URL}/score-university/delete/${scoreUniversityId}`, {
    headers: { Authorization: `Bearer ${token}` },
  })
}

export const updateScoreUniversity = async (payload, scoreUniversityId, token) => {
  return await axios.put(
    `${AppContextPath.ADMIN_URL}/score-university/update/${scoreUniversityId}`,
    payload,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  )
}

export const createScoreUniversity = async (payload, token) => {
  return await axios.post(`${AppContextPath.ADMIN_URL}/score-university/new`, payload, {
    headers: { Authorization: `Bearer ${token}` },
  })
}
