import { Space } from 'antd'
import { useFormik } from 'formik'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { InputText } from 'primereact/inputtext'
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router'
import ActionInRow from '../../../components/Templates/ActionInRow'
import Card from '../../../components/Templates/Card'
import Table from '../../../components/Templates/Table/Table'
import TableCaption from '../../../components/Templates/TableCaption'
import TableInput from '../../../components/Templates/TableInput'
import { PrivateRoute } from '../../../helpers/PrivateRoute'
import { useBaseFunction } from '../../../layouts/AppContext'
import { useLoading } from '../../../layouts/FullScreenLoading/FullScreenLoading'
import { fetchCity } from '../../../redux/slice/city.slice'
import { fetchCountrySingle } from '../../../redux/slice/country.slice'
import CitySchema from '../../../shared/schema/city.schema'
import CountrySchema from '../../../shared/schema/country.schema'
import {
  createCity,
  createCountry,
  deleteCity,
  updateCity,
  updateCountry,
} from '../../../shared/services/country.service'
import * as ObjectUtils from '../../../utils/object.utils'
import { CityForm } from './Form/CityForm'
import { CountryForm } from './Form/CountryForm'
export default function CountrySingle({ isEdit }) {
  const { countryId } = useParams()
  const navigate = useNavigate()
  const loading = useLoading()
  const { auth } = useSelector((state) => state.admin)
  const base = useBaseFunction()
  const [visibleDialog, setVisibleDialog] = useState(false)
  const schema = useMemo(() => new CountrySchema(loading, base, auth), [loading, auth, base])
  const citySchema = new CitySchema(loading, base, auth)
  const { entities, loading: cityLoading, totalRecords } = useSelector((state) => state.city)
  const { current: country, loading: countryLoading } = useSelector((state) => state.country)
  const [initialValues, setInitialValues] = useState(schema.defaultValue)
  const dispatch = useDispatch()
  const [table, setTable] = useState({
    first: 0,
    rows: 10,
    page: 1,
    sortField: null,
    sortOrder: null,
    filters: {
      name: { value: '' },
      desc: { value: '' },
      link: { value: '' },
    },
  })
  useEffect(() => {
    if (isEdit && ObjectUtils.isNotEmpty(country)) {
      setInitialValues(ObjectUtils.copy(schema.defaultValue, country))
    }
  }, [country, isEdit, schema])
  schema.setIdentified(countryId)
  schema.setCrateFunction(async (...props) => {
    const { data } = await createCountry(...props)
    navigate(`/admin/country/edit/${data.id}`, { replace: true })
  })
  schema.setUpdateFunction(updateCountry)
  const onSubmit = (values) => {
    schema.handleSubmit(values)
  }
  const formik = useFormik({
    initialValues,
    onSubmit: onSubmit,
    validationSchema: schema.validation,
    enableReinitialize: true,
  })

  //cities
  const fetchData = useCallback(() => {
    dispatch(
      fetchCity({
        ...table,
        countryId,
      })
    )
  }, [table, countryId, dispatch])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  citySchema.setUpdateFunction(async (...props) => {
    await updateCity(...props)
    fetchData()
  })
  citySchema.setCrateFunction(async (...props) => {
    await createCity(...props)
    handleClose()
    fetchData()
  })
  citySchema.setRemoveFunction(async (...props) => {
    const { data } = await deleteCity(...props)
    if (data) {
      fetchData()
    }
  })
  const cityFormik = useFormik({
    initialValues: citySchema.defaultValue,
    validationSchema: citySchema.validation,
    onSubmit: (values) => citySchema.handleSubmit({ ...values, countryId }),
  })
  useEffect(() => {
    if (isEdit) {
      dispatch(fetchCountrySingle(countryId))
    }
  }, [dispatch, countryId, isEdit])
  const columns = [
    {
      name: 'เมือง',
      key: 'name',
      width: '90%',
      align: 'left',
      sortable: true,
      editor: (options) => (
        <TableInput options={options} validate={citySchema.nameValidation} placeholder='เมือง'>
          <InputText />
        </TableInput>
      ),
    },
    {
      key: 'action',
      width: '150px',
      rowEditor: true,
      render: (record, { rowEditor }) => (
        <ActionInRow record={record} rowEditor={rowEditor} handleRemove={handleRemove} />
      ),
    },
  ]
  const handleRemove = (cityId) => {
    citySchema.onRemove(cityId, auth.token)
  }
  const handleRowSave = (e) => {
    const { newData } = e
    newData.countryId = countryId
    citySchema.setIdentified(newData.id)
    citySchema.handleSubmit(newData, false)
  }
  const handleClose = () => {
    hideDialog()
    cityFormik.resetForm()
  }
  const showDialog = () => setVisibleDialog(true)
  const hideDialog = () => setVisibleDialog(false)
  return (
    <PrivateRoute>
      <Card header={isEdit ? 'แก้ไขประเทศ' : 'เพิ่มประเทศ'} loading={countryLoading}>
        <CountryForm formik={formik} />
      </Card>

      {isEdit && (
        <Fragment>
          <Dialog
            header='เพิ่มข้อมูลการรับสมัคร'
            draggable={false}
            modal={true}
            visible={visibleDialog}
            style={{ maxWidth: '50vw', minWidth: '450px' }}
            onHide={handleClose}
          >
            <CityForm formik={cityFormik} handleClose={handleClose} />
          </Dialog>
          <TableCaption
            render={
              <Space size='small' className='mb-2'>
                <Button icon='pi pi-plus' onClick={showDialog} />
              </Space>
            }
          />
          <Card header='เพิ่มข้อมูลเมือง'>
            <Table
              onRowEditComplete={handleRowSave}
              editMode='row'
              validate={citySchema.validation}
              dataKey='id'
              value={entities}
              totalRecords={totalRecords}
              loading={cityLoading}
              table={table}
              setTable={setTable}
              columns={columns}
            />
          </Card>
        </Fragment>
      )}
    </PrivateRoute>
  )
}
