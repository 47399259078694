import { AppContextPath } from '../constant/_environment'
import axios from 'axios'
export const updateApplication = async (payload, applicationId, token) => {
  return await axios.put(`${AppContextPath.ADMIN_URL}/application/update/${applicationId}`, payload, {
    headers: { Authorization: `Bearer ${token}` },
  })
}
export const notifyUser = async (applicationId, token) => {
  return await axios.post(
    `${AppContextPath.v1.ADMIN_URL}/applications/notify-user`,
    { applicationId },
    {
      headers: { Authorization: `Bearer ${token}` },
      timeout: 5000,
    }
  )
}

export const deleteFile = async (upload, token) => {
  return axios.delete(`${AppContextPath.v1.ADMIN_URL}/applications/upload/${upload.id}`, {
    timeout: 5000,
    headers: { Authorization: `Bearer ${token}` },
  })
}
