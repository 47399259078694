import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { AppContextPath } from '../../shared/constant/_environment'
import FilterUtils from '../../utils/filter.utils'

export const fetchUploadType = createAsyncThunk(
  'upload-type/fetchUploadType',
  async ({ first: offset = 0, rows: limit = 10, filters }, { getState }) => {
    const filterParam = FilterUtils.getParams(filters)
    console.log(`filterParam : ${JSON.stringify(filterParam)}`)
    const response = await axios({
      method: 'GET',
      url: `${AppContextPath.ADMIN_URL}/upload-type/list`,
      headers: {
        Authorization: 'Bearer ' + getState().admin.auth.token,
      },
      params: { offset, limit, ...filterParam },
    })
    return response.data
  }
)

export const uploadTypeSlice = createSlice({
  name: 'uploadType',
  initialState: {
    entities: [],
    totalRecords: 0,
    loading: false,
    error: null,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUploadType.pending, (state) => {
      state.loading = true
    })
    builder.addCase(fetchUploadType.fulfilled, (state, action) => {
      state.entities = action.payload.rows
      state.totalRecords = action.payload.count
      state.loading = false
    })
    builder.addCase(fetchUploadType.rejected, (state) => {
      state.entities = []
      state.totalRecords = []
      state.loading = false
    })
  },
})
