import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { AppContextPath } from '../../shared/constant/_environment'
import FilterUtils from '../../utils/filter.utils'

export const fetchRoles = createAsyncThunk(
  'role/fetchRoles ',
  async ({ first: offset = 0, rows: limit = 10, filters }, { getState }) => {
    const filterParam = FilterUtils.getParams(filters)
    const response = await axios({
      method: 'GET',
      url: `${AppContextPath.ADMIN_URL}/role/list`,
      headers: {
        Authorization: 'Bearer ' + getState().admin.auth.token,
      },
      params: { offset, limit, ...filterParam },
    })
    return response.data
  }
)
export const fetchRoleSingle = createAsyncThunk('role/fetchRoleSingle ', async (roleId, { getState }) => {
  const response = await axios({
    method: 'GET',
    url: `${AppContextPath.ADMIN_URL}/role/single/${roleId}`,
    headers: {
      Authorization: 'Bearer ' + getState().admin.auth.token,
    },
  })
  return response.data
})
export const roleSlice = createSlice({
  name: 'role',
  initialState: {
    current: {},
    entities: [],
    totalRecords: 0,
    loading: false,
  },
  extraReducers: (builder) => {
    builder.addCase([fetchRoles.pending], (state) => {
      state.loading = true
    })
    builder.addCase(fetchRoles.fulfilled, (state, action) => {
      state.entities = action.payload.rows
      state.totalRecords = action.payload.count
      state.loading = false
    })
    builder.addCase(fetchRoles.rejected, (state) => {
      state.entities = []
      state.totalRecords = 0
      state.loading = false
    })
    builder.addCase(fetchRoleSingle.pending, (state) => {
      state.loading = true
    })
    builder.addCase(fetchRoleSingle.fulfilled, (state, action) => {
      state.current = action.payload
      state.loading = false
    })
    builder.addCase(fetchRoleSingle.rejected, (state) => {
      state.current = {}
      state.loading = false
    })
  },
})
