import axios from 'axios'
import { AppContextPath } from '../constant/_environment'
export const deleteProgram = async (programId, token) => {
  return await axios.delete(`${AppContextPath.ADMIN_URL}/program/delete/${programId}`, {
    headers: { Authorization: `Bearer ${token}` },
  })
}

export const updateProgram = async (payload, programId, token) => {
  return await axios.put(`${AppContextPath.ADMIN_URL}/program/update/${programId}`, payload, {
    headers: { Authorization: `Bearer ${token}` },
  })
}

export const createProgram = async (payload, token) => {
  return await axios.post(`${AppContextPath.ADMIN_URL}/program/new`, payload, {
    headers: { Authorization: `Bearer ${token}` },
  })
}

export const getMinMaxPriceProgram = async (universityId, token) => {
  return await axios.get(`${AppContextPath.ADMIN_URL}/program/getMinMaxPriceProgram/${universityId}`, {
    headers: { Authorization: `Bearer ${token}` },
  })
}
