import { DateTime } from 'luxon'
export default class DateUtils {
  static getString(date, format = 'DDD') {
    if (!!date) {
      return DateTime.fromJSDate(typeof date === 'string' ? new Date(date) : date)
        .setZone('Asia/Bangkok')
        .setLocale('th-TH')
        .toFormat(format)
    }
    return ''
  }
}
