import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { AppContextPath } from '../../shared/constant/_environment'
import FilterUtils from '../../utils/filter.utils'

export const fetchApplication = createAsyncThunk(
  'application/fetchApplication ',
  async ({ first: offset = 0, rows: limit = 10, filters }, { getState }) => {
    const filterParam = FilterUtils.getParams(filters)
    const response = await axios({
      method: 'GET',
      url: `${AppContextPath.ADMIN_URL}/application/list`,
      headers: {
        Authorization: 'Bearer ' + getState().admin.auth.token,
      },
      params: { offset, limit, ...filterParam },
    })
    return response.data
  }
)
export const fetchApplicationSingle = createAsyncThunk(
  'application/fetchApplicationSingle',
  async (applicationId, { getState }) => {
    const response = await axios({
      method: 'GET',
      url: `${AppContextPath.v1.ADMIN_URL}/applications/${applicationId}`,
      headers: {
        Authorization: 'Bearer ' + getState().admin.auth.token,
      },
    })
    return response.data
  }
)
export const fetchRegisterForm = createAsyncThunk(
  'application/fetchRegisterForm',
  async (applicationId, { getState }) => {
    const response = await axios({
      method: 'GET',
      url: `${AppContextPath.PUBLIC_URL}/register/single/${applicationId}`,
      headers: {
        Authorization: 'Bearer ' + getState().admin.auth.token,
      },
    })
    return response.data
  }
)
export const applicationSlice = createSlice({
  name: 'application',
  initialState: {
    current: {},
    entities: [],
    totalRecords: 0,
    loading: false,
    register: null,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchApplication.pending, (state) => {
      state.loading = true
    })
    builder.addCase(fetchApplication.fulfilled, (state, action) => {
      state.entities = action.payload.rows
      state.totalRecords = action.payload.count
      state.loading = false
    })
    builder.addCase(fetchApplicationSingle.pending, (state) => {
      state.loading = true
    })
    builder.addCase(fetchApplicationSingle.fulfilled, (state, action) => {
      state.current = action.payload
      state.loading = false
    })
    builder.addCase(fetchApplication.rejected, (state) => {
      state.entities = []
      state.totalRecords = 0
      state.loading = false
    })
    builder.addCase(fetchApplicationSingle.rejected, (state) => {
      state.current = {}
      state.loading = false
    })
    builder.addCase(fetchRegisterForm.fulfilled, (state, action) => {
      state.register = action.payload
    })
  },
})
