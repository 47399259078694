import { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router'
import Card from '../../../components/Templates/Card'
import { PrivateRoute } from '../../../helpers/PrivateRoute'
import { useBaseFunction } from '../../../layouts/AppContext'
import { useLoading } from '../../../layouts/FullScreenLoading/FullScreenLoading'
import { fetchProductSingle } from '../../../redux/slice/product.slice'
import ProductSchema from '../../../shared/schema/product.schema'
import { createProduct, updateProduct } from '../../../shared/services/product.service'
import ProductForm from './Form/ProductForm'

export default function ProductSingle({ isEdit = false }) {
  const { productId } = useParams()
  const dispatch = useDispatch()
  const { current: product, loading } = useSelector((state) => state.product)
  const { auth } = useSelector((state) => state.admin)
  const loader = useLoading()
  const base = useBaseFunction()
  const schema = useMemo(
    () => new ProductSchema(loader, base, auth, isEdit ? product : null),
    [loader, base, auth, product, isEdit]
  )
  const navigate = useNavigate()
  const fetchData = useCallback(() => {
    if (productId) {
      dispatch(fetchProductSingle(productId))
    }
  }, [productId, dispatch])
  schema.setCrateFunction(async (...props) => {
    const { data } = await createProduct(...props)
    fetchData()
    navigate(`/admin/product/edit/${data.id}`)
  })
  schema.setUpdateFunction(async (...props) => {
    await updateProduct(...props)
    fetchData()
  })
  schema.setIdentified(productId)

  useEffect(() => {
    fetchData()
  }, [fetchData])
  return (
    <PrivateRoute>
      <Card header={isEdit ? 'แก้ไขสินค้าและบริการ' : 'เพิ่มสินค้าและบริการ'} loading={loading}>
        <ProductForm
          initialValues={schema.defaultValue}
          validationSchema={schema.validation}
          onSubmit={(values) => schema.handleSubmit(values)}
        />
      </Card>
    </PrivateRoute>
  )
}
