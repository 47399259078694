import { useFormik } from 'formik'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router'
import Card from '../../../components/Templates/Card'
import { PrivateRoute } from '../../../helpers/PrivateRoute'
import { useBaseFunction } from '../../../layouts/AppContext'
import { useLoading } from '../../../layouts/FullScreenLoading/FullScreenLoading'
import { fetchRoleSingle } from '../../../redux/slice/role.slice'
import RoleSchema from '../../../shared/schema/role.schema'
import { createRole, updateRole } from '../../../shared/services/role.service'
import * as ObjectUtils from '../../../utils/object.utils'
import RoleForm from './Form/RoleForm'

export default function RoleSingle({ isEdit }) {
  const { roleId } = useParams()
  const { auth } = useSelector((state) => state.admin)
  const base = useBaseFunction()
  const loading = useLoading()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const schema = useMemo(() => new RoleSchema(loading, base, auth), [loading, base, auth])
  const { current: role, roleLoading } = useSelector((state) => state.role)
  const [initialValues, setInitialValues] = useState(schema.defaultValue)
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: schema.validation,
    onSubmit: (values) => schema.handleSubmit(values),
    enableReinitialize: true,
  })
  schema.setIdentified(roleId)
  schema.setCrateFunction(async (...props) => {
    const { data } = await createRole(...props)
    navigate(`/admin/role/edit/${data.id}`, { replace: true })
  })
  schema.setUpdateFunction(updateRole)
  useEffect(() => {
    if (isEdit && ObjectUtils.isNotEmpty(role)) {
      setInitialValues(ObjectUtils.copy(schema.defaultValue, role))
    }
  }, [role, isEdit, schema])
  useEffect(() => {
    if (isEdit) {
      dispatch(fetchRoleSingle(roleId))
    }
  }, [dispatch, roleId, isEdit])
  return (
    <PrivateRoute>
      <Card header={isEdit ? 'แก้ไขบทบาทการเช้าสู่ระบบ' : 'เพิ่มบทบาทการเช้าสู่ระบบ'} loading={roleLoading}>
        <RoleForm formik={formik} />
      </Card>
    </PrivateRoute>
  )
}
