import { date, object, string } from 'yup'
import { SchemaTemplate } from './template.schema'

export default class ApplyingSchema extends SchemaTemplate {
  applyIdValidation = string().uuid().nullable(true).required('ต้องระบุการรับสมัคร!')
  startDateValidation = date().nullable(true).required('ต้องระบุวันที่เปิดรับสมัคร!')
  closeDateValidation = date().nullable(true).required('ต้องระบุวันที่ปิดรับสมัคร!')
  validation = object().shape({
    startDate: this.startDateValidation,
    closeDate: this.closeDateValidation,
  })

  defaultValue = {
    startDate: null,
    closeDate: null,
    desc: '',
  }
}
