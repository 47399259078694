import { Button } from 'primereact/button'
import { Chips } from 'primereact/chips'
import { InputText } from 'primereact/inputtext'
import React from 'react'
import InlineLabel from '../../../components/Templates/InlineLabel'
export function MajorForm({ formik, handleClose }) {
  const { errors, touched, handleChange, values, handleSubmit } = formik
  return (
    <form onSubmit={handleSubmit}>
      <InlineLabel errors={errors} touched={touched} label='สาขาวิชา' name='name' required>
        <InputText onChange={handleChange} value={values.name} />
      </InlineLabel>
      <InlineLabel errors={errors} touched={touched} label='หมวดหมู่' name='categories'>
        <Chips
          onChange={handleChange}
          value={values.categories}
          placeholder='ใช้ , เพื่อแยกหมวดหมู่'
          onAdd={(e) => e.value.trim()}
          separator=','
        />
      </InlineLabel>

      <div className='d-flex justify-content-end pt-3 gap-2'>
        <Button type='reset' label='ยกเลิก' className='p-button-secondary' onClick={handleClose} />
        <Button type='submit' label='บันทึก' />
      </div>
    </form>
  )
}
