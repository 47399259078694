import { DeleteOutlined } from '@ant-design/icons'
import { Upload } from 'antd'
import { Button } from 'primereact/button'
import { Image } from 'primereact/image'
import { ProgressSpinner } from 'primereact/progressspinner'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import Swal from 'sweetalert2'
import PreviewUpload from '../../../components/PreviewUpload'
import { useBaseFunction } from '../../../layouts/AppContext'
import { CONFIRM_REMOVE } from '../../../shared/constant/_confirm'
import { AppContextPath } from '../../../shared/constant/_environment'
import { deleteFile } from '../../../shared/services/application.service'
export const UploadWrapper = ({ uploadType, formik, fetchData }) => {
  const upload = formik.values?.uploads?.find((upload) => upload.uploadType === uploadType)
  const uploadPath = upload?.uploadPath
  const { auth } = useSelector((state) => state.admin)
  const { errorToast } = useBaseFunction()
  const [loading, setLoading] = useState(false)
  const handleFileChange = async (info) => {
    if (info.file.status === 'uploading') {
      await setLoading(true)
      return
    }
    if (info.file.status === 'done') {
      await setLoading(false)
      if (fetchData) {
        fetchData()
      }
    }
  }
  const handleDelete = () => {
    Swal.fire(CONFIRM_REMOVE).then(({ isConfirmed }) => {
      if (isConfirmed) {
        deleteFile(upload, auth.token)
          .then(fetchData)
          .catch((err) => errorToast(err.message))
      }
    })
  }
  return (
    <div className='mt-2 flex flex-column w-fit'>
      <div>
        {uploadPath &&
          (['docx', 'pdf'].includes(upload.uploadFileType?.toLowerCase()) ? (
            <PreviewUpload upload={upload} />
          ) : (
            <Image
              width='100px'
              height='100px'
              shape='square'
              style={{ objectFit: 'cover' }}
              src={`${AppContextPath.v1.ADMIN_URL}/redirect/assets?bucket=applications&uploadPath=${uploadPath}`}
            />
          ))}
        {!uploadPath && (
          <Upload
            name='upload'
            listType='picture-card'
            className='avatar-uploader'
            data={(file) => {
              return {
                uploadType,
                uploadFileType: file.name.split('.')?.reverse()?.[0],
                uploadOriginalName: file.name,
              }
            }}
            showUploadList={false}
            action={`${AppContextPath.v1.ADMIN_URL}/applications/${formik.values.id}/upload`}
            headers={{ Authorization: 'Bearer ' + auth.token }}
            onChange={handleFileChange}
          >
            {loading ? <ProgressSpinner /> : <span className='pi pi-plus'></span>}
          </Upload>
        )}
      </div>
      {uploadPath && (
        <Button
          icon={<DeleteOutlined />}
          onClick={handleDelete}
          type='button'
          className='mb-2 p-button-text '
        >
          ลบ
        </Button>
      )}
    </div>
  )
}
