import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import _ from 'lodash'

export const deleteBlog = createAsyncThunk('admin/deleteBlog', async (blogId, { getState }) => {
  const res = await axios({
    method: 'DELETE',
    url: `${process.env.REACT_APP_API_URL}/admin/blogs/${blogId}`,
    headers: {
      Authorization: 'Bearer ' + _.get(getState().auth, 'token', ''),
    },
  })
  return _.get(res, 'data', null)
})

export const adminFetchBlogs = createAsyncThunk(
  'university/adminFetchBlogs ',
  async (__unused, { getState }) => {
    const res = await axios({
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/admin/blogs`,
      headers: {
        Authorization: 'Bearer ' + _.get(getState().auth, 'token', ''),
      },
    })
    return _.get(res, 'data', null)
  }
)

export const login = createAsyncThunk('university/adminLogin', async (params, { rejectWithValue }) => {
  const email = params?.email
  const password = params?.password
  try {
    const res = await axios.post(`${process.env.REACT_APP_API_URL}/admin/managements/login`, {
      email,
      password,
    })
    const data = _.get(res, 'data', null)
    localStorage.setItem('token', data.token)
    return data
  } catch (err) {
    if (!err.response) {
      throw err
    }
    return rejectWithValue(err.response.data)
  }
})
export const getAdminInfo = createAsyncThunk(
  'admin/getAdminInfo',
  async (__unused, { rejectWithValue, getState }) => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/admin/managements/getAdminInfo`, {
        headers: {
          Authorization: 'Bearer ' + _.get(getState().admin.auth, 'token', ''),
        },
      })
      const data = _.get(res, 'data', null)
      return data
    } catch (err) {
      if (!err.response) {
        throw err
      }
      return rejectWithValue(err.response.data)
    }
  }
)
export const changeRole = createAsyncThunk('university/changeRole', async (role) => {
  return role
})
export const adminSlice = createSlice({
  name: 'admin',
  initialState: {
    loading: false,
    authError: null,
    currentRole: null,
    auth: null,
  },
  reducers: {
    applyFilter: (state) => state,
    logout: (state) => {
      state.auth = null
      localStorage.removeItem('token')
    },
  },
  extraReducers: (builder) => {
    builder.addCase(adminFetchBlogs.fulfilled, (state, action) => {
      state.blogs = action.payload
    })
    builder.addCase(getAdminInfo.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getAdminInfo.fulfilled, (state, action) => {
      state.auth = {
        ...state.auth,
        ...action.payload,
      }
      if (!state.currentRole && action.payload.roles.length > 0) {
        state.currentRole = action.payload.roles[0]
      }
      if (state.currentRole && !state.auth.roles.map(({ name }) => name).includes(state.currentRole.name)) {
        state.currentRole = action.payload.roles[0]
      }
    })
    builder.addCase(changeRole.fulfilled, (state, action) => {
      state.currentRole = action.payload
    })
    builder.addCase(deleteBlog.fulfilled, (state, action) => {
      state.blogs = state.blogs.filter((blog) => blog.id !== action.payload.id)
    })
    builder.addCase(login.fulfilled, (state, action) => {
      state.auth = action.payload
    })
    builder.addCase(login.rejected, (state, action) => {
      state.authError = action.payload
    })
  },
})

export const { logout } = adminSlice.actions
