import { ProgressSpinner } from 'primereact/progressspinner'
import React, { createContext, useContext, useState } from 'react'
import style from './full-screen-loading.module.scss'
export function FullScreenLoadingProvider({ children }) {
  const [loading, _setLoading] = useState(false)
  const setLoading = (_loading) => {
    let timeout = 0
    if (!_loading) timeout = 500
    return new Promise((resolve) => {
      setTimeout(() => {
        _setLoading(_loading)
        setTimeout(() => {
          resolve()
        }, timeout + 100)
      }, timeout)
    })
  }
  return (
    <LoadingContext.Provider value={{ setLoading, loading }}>
      <div className={`${style.fullScreenLoading} ${loading ? style.loading : ''}`}>
        <ProgressSpinner className={style.spinner} />
      </div>
      {children}
    </LoadingContext.Provider>
  )
}
const LoadingContext = createContext({
  setLoading: async (_loading) => {},
  loading: false,
})

export function useLoading() {
  const context = useContext(LoadingContext)
  if (!context) {
    throw new Error('useLoading must be used within LoadingProvider')
  }
  return context
}
