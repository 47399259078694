import { useFormik } from 'formik'
import { useEffect } from 'react'
import { useCallback } from 'react'
import { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import Card from '../../../components/Templates/Card'
import { PrivateRoute } from '../../../helpers/PrivateRoute'
import { useBaseFunction } from '../../../layouts/AppContext'
import { useLoading } from '../../../layouts/FullScreenLoading/FullScreenLoading'
import { fetchBankSingle } from '../../../redux/slice/bank.slice'
import BankSchema from '../../../shared/schema/bank.schema'
import { createBank, updateBank } from '../../../shared/services/bank.service'
import BankForm from './Form/BankForm'

export default function BankSingle({ isEdit = false }) {
  const { bankId } = useParams()
  const dispatch = useDispatch()
  const { current: bank, loading } = useSelector((state) => state.bank)
  const { auth } = useSelector((state) => state.admin)
  const loader = useLoading()
  const base = useBaseFunction()
  const schema = useMemo(() => new BankSchema(loader, base, auth, bank), [loader, base, auth, bank])
  const fetchData = useCallback(() => {
    if (bankId) {
      dispatch(fetchBankSingle(bankId))
    }
  }, [bankId, dispatch])
  schema.setCrateFunction(async (...props) => {
    await createBank(...props)
    fetchData()
  })
  schema.setUpdateFunction(async (...props) => {
    await updateBank(...props)
  })
  schema.setIdentified(bankId)
  const formik = useFormik({
    initialValues: schema.defaultValue,
    enableReinitialize: true,
    validationSchema: schema.validation,
    onSubmit: (values) => schema.handleSubmit(values),
  })
  useEffect(() => {
    fetchData()
  }, [fetchData])
  return (
    <PrivateRoute>
      <Card header={isEdit ? 'แก้ไขธนาคาร' : 'เพิ่มธนาคาร'} loading={loading}>
        <BankForm formik={formik} />
      </Card>
    </PrivateRoute>
  )
}
