import axios from 'axios'
import { AppContextPath } from '../constant/_environment'
export const deleteApplying = async (applyingId, token) => {
  return await axios.delete(`${AppContextPath.ADMIN_URL}/applying/delete/${applyingId}`, {
    headers: { Authorization: `Bearer ${token}` },
  })
}

export const updateApplying = async (payload, applyingId, token) => {
  return await axios.put(`${AppContextPath.ADMIN_URL}/applying/update/${applyingId}`, payload, {
    headers: { Authorization: `Bearer ${token}` },
  })
}

export const createApplying = async (payload, token) => {
  return await axios.post(`${AppContextPath.ADMIN_URL}/applying/new`, payload, {
    headers: { Authorization: `Bearer ${token}` },
  })
}
