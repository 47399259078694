import axios from 'axios'
import { useFormik } from 'formik'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import { useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Swal from 'sweetalert2'
import InlineLabel from '../../components/Templates/InlineLabel'
import ListOfValue from '../../components/Templates/ListOfValue/ListOfValue'
import { useBaseFunction } from '../../layouts/AppContext'
import { fetchSetting } from '../../redux/slice/setting.slice'
import { CONFIRM_SAVE } from '../../shared/constant/_confirm'
import { AppContextPath } from '../../shared/constant/_environment'

export default function Payment() {
  const dispatch = useDispatch()
  const { entities } = useSelector((state) => state.setting)
  const { successToast, errorToast } = useBaseFunction()
  const { auth } = useSelector((state) => state.admin)
  useEffect(() => {
    dispatch(fetchSetting())
  }, [dispatch])
  const getConfigValue = useCallback(
    (name) => {
      return entities.find((e) => e.name === name)?.desc || ''
    },
    [entities]
  )
  const formik = useFormik({
    initialValues: {
      paymentName: getConfigValue('paymentName'),
      paymentNumber: getConfigValue('paymentNumber'),
      paymentBankId: getConfigValue('paymentBankId'),
    },
    enableReinitialize: true,
    onSubmit: (values) =>
      Swal.fire(CONFIRM_SAVE).then(({ isConfirmed }) => {
        if (isConfirmed) {
          axios({
            url: `${AppContextPath.v1.ADMIN_URL}/config/edit`,
            method: 'put',
            data: values,
            headers: { Authorization: `Bearer ${auth.token}` },
          })
            .then(() => {
              successToast('บันทึกข้อมูลสำเร็จ')
            })
            .catch((e) => errorToast(e.message))
        }
      }),
  })
  return (
    <form onSubmit={formik.handleSubmit}>
      <InlineLabel errors={formik.errors} touched={formik.touched} label='ธนาคาร' name='paymentBankId'>
        <ListOfValue
          setFieldValue={formik.setFieldValue}
          value={formik.values.paymentBankId}
          callTo='/bank/list-of-value'
          displayField='name'
          field='id'
        />
      </InlineLabel>
      <InlineLabel errors={formik.errors} touched={formik.touched} label='ชื่อบัญชี' name='paymentName'>
        <InputText onChange={formik.handleChange} value={formik.values.paymentName} />
      </InlineLabel>
      <InlineLabel errors={formik.errors} touched={formik.touched} label='เลขบัญชี' name='paymentNumber'>
        <InputText onChange={formik.handleChange} value={formik.values.paymentNumber} />
      </InlineLabel>
      <div className='flex justify-content-end m-3'>
        <Button label='บันทึก' type='submit' className='p-button-sm' />
      </div>
    </form>
  )
}
