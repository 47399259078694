import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import React from 'react'
import InlineLabel from '../../../components/Templates/InlineLabel'
import ListOfValue from '../../../components/Templates/ListOfValue/ListOfValue'
export function ScoreForm({ formik, handleClose }) {
  const { errors, touched, handleChange, values, handleSubmit, setFieldValue } = formik
  return (
    <form onSubmit={handleSubmit}>
      <InlineLabel errors={errors} touched={touched} label='คะแนน' name='name' required>
        <InputText onChange={handleChange} value={values.name} />
      </InlineLabel>
      <InlineLabel errors={errors} touched={touched} label='ประเภท' name='categoryId'>
        <ListOfValue
          setFieldValue={setFieldValue}
          value={values.countryId}
          callTo='/score-category/list-of-value'
          displayField='name'
          field='id'
        />
      </InlineLabel>
      <div className='d-flex justify-content-end pt-3 gap-2'>
        <Button type='reset' label='ยกเลิก' className='p-button-secondary' onClick={handleClose} />
        <Button type='submit' label='บันทึก' />
      </div>
    </form>
  )
}
