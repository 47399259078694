import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import _ from 'lodash'
import { AppContextPath } from '../../shared/constant/_environment'
import FilterUtils from '../../utils/filter.utils'

export const fetchBankList = createAsyncThunk(
  'bank/fetchBankList',
  async ({ first: offset = 0, rows: limit = 10, filters }, { getState }) => {
    const filterParam = FilterUtils.getParams(filters)
    const response = await axios({
      method: 'GET',
      url: `${AppContextPath.ADMIN_URL}/bank/list`,
      headers: {
        Authorization: 'Bearer ' + getState().admin.auth.token,
      },
      params: { offset, limit, ...filterParam },
    })
    return _.get(response, 'data', [])
  }
)
export const fetchBankSingle = createAsyncThunk('bank/fetchBankSingle', async (bankId, { getState }) => {
  const response = await axios({
    method: 'GET',
    url: `${AppContextPath.ADMIN_URL}/bank/single/${bankId}`,
    headers: {
      Authorization: 'Bearer ' + getState().admin.auth.token,
    },
  })
  return _.get(response, 'data', [])
})

export const bankSlice = createSlice({
  name: 'bank',
  initialState: {
    entities: [],
    totalRecords: 0,
    loading: false,
    error: null,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchBankList.pending, (state) => {
      state.loading = true
    })
    builder.addCase(fetchBankList.fulfilled, (state, action) => {
      state.entities = action.payload.rows
      state.totalRecords = action.payload.count
      state.loading = false
    })
    builder.addCase(fetchBankList.rejected, (state) => {
      state.entities = []
      state.totalRecords = 0
      state.loading = false
    })
    builder.addCase(fetchBankSingle.fulfilled, (state, action) => {
      state.current = action.payload
      state.loading = false
    })
    builder.addCase(fetchBankSingle.pending, (state, action) => {
      state.loading = true
    })
  },
})
