import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { AppContextPath } from '../../shared/constant/_environment'
import FilterUtils from '../../utils/filter.utils'

export const fetchPosition = createAsyncThunk(
  'position/fetchPosition ',
  async ({ first: offset = 0, rows: limit = 10, filters }, { getState }) => {
    const filterParam = FilterUtils.getParams(filters)
    const response = await axios({
      method: 'GET',
      url: `${AppContextPath.ADMIN_URL}/position/list`,
      headers: {
        Authorization: 'Bearer ' + getState().admin.auth.token,
      },
      params: { offset, limit, ...filterParam },
    })
    return response.data
  }
)

export const positionSlice = createSlice({
  name: 'positions',
  initialState: {
    current: {},
    entities: [],
    totalRecords: 0,
    loading: false,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPosition.pending, (state) => (state.loading = true))
    builder.addCase(fetchPosition.fulfilled, (state, action) => {
      state.entities = action.payload.rows
      state.totalRecords = action.payload.count
      state.loading = false
    })
    builder.addCase(fetchPosition.rejected, (state) => {
      state.entities = []
      state.totalRecords = 0
      state.loading = false
    })
  },
})
