import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import AdminListing from '../pages/Admin/Management/AdminListing'
import AdminSingle from '../pages/Admin/Management/AdminSingle'
import PositionListing from '../pages/Admin/Position/PositionListing'
import RoleListing from '../pages/Admin/Role/RoleListing'
import RoleSingle from '../pages/Admin/Role/RoleSingle'
import ApplicationListing from '../pages/Application/ApplicationListing'
import ApplicationSingle from '../pages/Application/ApplicationSingle'
import BankListing from '../pages/Controllers/Bank/BankListing'
import BankSingle from '../pages/Controllers/Bank/BankSingle'
import CountryListing from '../pages/Controllers/Country/CountryListing'
import CountrySingle from '../pages/Controllers/Country/CountrySingle'
import LanguageTypeListing from '../pages/Controllers/LanguageTypeListing'
import MajorListing from '../pages/Controllers/MajorListing'
import ProductListing from '../pages/Controllers/Product/ProductListing'
import ProductSingle from '../pages/Controllers/Product/ProductSingle'
import ScoreCategoryListing from '../pages/Controllers/ScoreCategoryListing'
import ScoreListing from '../pages/Controllers/ScoreListing'
import StudyLevelListing from '../pages/Controllers/StudyLevelListing'
import UploadTypeListing from '../pages/Controllers/UploadTypeListing'
import ProgramListing from '../pages/Program/ProgramListing'
import ProgramSingle from '../pages/Program/ProgramSingle'
import SettingListing from '../pages/Setting/SettingListing'
import UniversityListing from '../pages/University/UniversityListing'
import UniversitySingle from '../pages/University/UniversitySingle'
export default function AppRouting() {
  return (
    <Routes>
      <Route path='/university' element={<UniversityListing />} />
      <Route path='/university/edit/:universityId' element={<UniversitySingle isEdit />} />
      <Route path='/university/new' element={<UniversitySingle />} />
      <Route path='/study-level' element={<StudyLevelListing />} />
      <Route path='/score-category' element={<ScoreCategoryListing />} />
      <Route path='/score' element={<ScoreListing />} />
      <Route path='/country' element={<CountryListing />} />
      <Route path='/country/new' element={<CountrySingle />} />
      <Route path='/country/edit/:countryId' element={<CountrySingle isEdit />} />
      <Route path='/program' element={<ProgramListing />} />
      <Route path='/major' element={<MajorListing />} />
      <Route path='/language-type' element={<LanguageTypeListing />} />
      <Route path='/program/new' element={<ProgramSingle />} />
      <Route path='/program/edit/:programId' element={<ProgramSingle isEdit />} />
      <Route path='/role' element={<RoleListing />} />
      <Route path='/role/new' element={<RoleSingle />} />
      <Route path='/role/edit/:roleId' element={<RoleSingle isEdit />} />
      <Route path='/management' element={<AdminListing />} />
      <Route path='/management/new' element={<AdminSingle />} />
      <Route path='/management/edit/:adminId' element={<AdminSingle isEdit />} />
      <Route path='/application' element={<ApplicationListing />} />
      <Route path='/application/edit/:applicationId' element={<ApplicationSingle />} />
      <Route path='/position' element={<PositionListing />} />
      <Route path='/setting' element={<SettingListing />} />
      <Route path='/upload-type' element={<UploadTypeListing />} />
      <Route path='/bank' element={<BankListing />} />
      <Route path='/bank/new' element={<BankSingle />} />
      <Route path='/bank/edit/:bankId' element={<BankSingle isEdit />} />
      <Route path='/product' element={<ProductListing />} />
      <Route path='/product/new' element={<ProductSingle />} />
      <Route path='/product/edit/:productId' element={<ProductSingle isEdit />} />
      <Route path='*' element={<Navigate to='/admin/university' />} />
    </Routes>
  )
}
