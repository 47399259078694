import React from 'react'
import { Helmet } from 'react-helmet-async'

const meta = {
  currentUrl: 'localhost:3000',
  title: 'CASC | Chinese Abroad Study Center',
  desc: `Chinese Abroad Study Center (CASC) ทุนการศึกษาฟรีกว่า 500 ทุน ตัวแทนศึกษาต่อประเทศจีนจากมหาวิทยาลัยชั้นนำมากกว่า 50 แห่ง ด้วยการบริการที่ครบวงจร บริษัทฯทีมงานผู้เชี่ยวชาญยินดีให้คำปรึกษาและแนะแนวการศึกษา`,
}

export default function AppHelmet({ lang = 'en' }) {
  return (
    <Helmet
      htmlAttributes={{
        lang: lang,
      }}
      // title={'xxx'}
      titleTemplate={`CASC | %s`}
      defaultTitle={meta.title}
      meta={[
        {
          name: `description`,
          content: meta.desc,
        },
        {
          property: `og:title`,
          content: meta.title,
        },
        {
          property: `og:description`,
          content: meta.desc,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        // {
        //   property: `og:url`,
        //   content: 'https://dev.appadmit.codemonday.net',
        // },
      ].concat(meta)}
      // script={[{ src: 'https://cdn.omise.co/omise.js', type: 'text/javascript' }]}
    />
  )
}
