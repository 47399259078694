import { List, Space } from 'antd'
import { Avatar } from 'primereact/avatar'
import { Button } from 'primereact/button'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import PreviewUpload from '../../components/PreviewUpload'
import TableEditButton from '../../components/Templates/Button/TableEditButton'
import TableDeleteButton from '../../components/Templates/Button/TableRemoveButton'
import Card from '../../components/Templates/Card'
import Table from '../../components/Templates/Table/Table'
import TableCaption from '../../components/Templates/TableCaption'
import { PrivateRoute } from '../../helpers/PrivateRoute'
import { fetchApplication } from '../../redux/slice/application.slice'
import DateUtils from '../../utils/date.utils'

const renderItem = (upload) => {
  return (
    <List.Item title={upload.uploadType}>
      <List.Item.Meta avatar={<PreviewUpload upload={upload} />} title={<a>{upload.uploadType}</a>} />
    </List.Item>
  )
}

export default function ApplicationListing() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { auth } = useSelector((state) => state.admin)
  const { entities, totalRecords, loading: applicationLoading } = useSelector((state) => state.application)
  const [table, setTable] = useState({
    first: 0,
    rows: 10,
    page: 1,
    filters: {
      'user.fullName': { value: '' },
      cascId: { value: '' },
    },
  })
  const fetchData = useCallback(() => {
    dispatch(fetchApplication(table))
  }, [dispatch, table])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  const onClickEdit = (id) => {
    return navigate(`/admin/application/edit/${id}`)
  }
  const onClickDelete = (id) => {}
  const columns = [
    {
      key: 'profilePath',
      name: 'รูปภาพ',
      width: '5%',
      align: 'center',
      render: ({ user }) => (
        <Avatar shape='circle' image={user?.profilePath} size='xlarge' label={user.firstName[0]} />
      ),
    },
    {
      key: 'user.fullName',
      name: 'ชื่อ - สกุล',
      width: '10%',
      sortable: true,
    },
    {
      key: 'cascId',
      name: 'CASC ID',
      width: '10%',
      align: 'center',
      sortable: true,
    },
    {
      key: 'currentStep',
      name: 'ขั้นตอนที่อยู่',
      width: '10%',
      align: 'center',
    },
    {
      key: 'phone',
      name: 'เอกสารอัพโหลด',
      width: '40%',
      align: 'center',
      render: (record) => (
        <List
          grid={{ gutter: 16, column: 2 }}
          itemLayout='horizontal'
          locale={{ emptyText: <span></span> }}
          dataSource={record.uploads}
          renderItem={renderItem}
        />
      ),
    },
    {
      key: 'createdAt',
      name: 'เริ่มกรอกเมื่อ',
      align: 'center',
      width: '15%',
      render: (record) => <span>{DateUtils.getString(record.createdAt)}</span>,
    },
    {
      key: 'action',
      width: '150px',
      render: (record) => (
        <Space size='small'>
          <TableEditButton id={record.id} onClick={onClickEdit} />
          {auth.role === 'superadmin' && <TableDeleteButton id={record.id} onClick={onClickDelete} />}
        </Space>
      ),
    },
  ]

  return (
    <PrivateRoute>
      <TableCaption
        render={
          <Space size='small' className='mb-2'>
            <Button
              className='p-button-secondary'
              icon='pi pi-refresh'
              onClick={fetchData}
              loading={applicationLoading}
            />
            <Button icon='pi pi-plus' onClick={() => {}} />
          </Space>
        }
      />
      <Card header='ข้อมูลแอปพลิเคชัน'>
        <Table
          value={entities}
          totalRecords={totalRecords}
          loading={applicationLoading}
          table={table}
          setTable={setTable}
          columns={columns}
        />
      </Card>
    </PrivateRoute>
  )
}
