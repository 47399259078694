import { combineReducers } from 'redux'
import { adminSlice } from './slice/admin.slice'
import { applicationSlice } from './slice/application.slice'
import { applySlice } from './slice/applying.slice'
import { bankSlice } from './slice/bank.slice'
import { citySlice } from './slice/city.slice'
import { costSlice } from './slice/cost.slice'
import { countrySlice } from './slice/country.slice'
import { i18nSlice } from './slice/i18n.slice'
import { languageTypeSlice } from './slice/language-type.slice'
import { majorCategorySlice } from './slice/major-category.slice'
import { majorSlice } from './slice/major.slice'
import { managementSlice } from './slice/management.slice'
import { positionSlice } from './slice/position.slice'
import { productSlice } from './slice/product.slice'
import { programUploadSlice } from './slice/program-upload.slice'
import { programSlice } from './slice/program.slice'
import { roleSlice } from './slice/role.slice'
import { scholarshipSlice } from './slice/scholarship.slice'
import { scoreCategorySlice } from './slice/score-category.slice'
import { scoreUniversitySlice } from './slice/score-university.slice'
import { scoreSlice } from './slice/score.slice'
import { settingSlice } from './slice/setting.slice'
import { studyLevelSlice } from './slice/study-level.slice'
import { universitySlice } from './slice/university.slice'
import { uploadTypeSlice } from './slice/upload-type.slice'

export const rootReducers = combineReducers({
  admin: adminSlice.reducer,
  university: universitySlice.reducer,
  country: countrySlice.reducer,
  applying: applySlice.reducer,
  cost: costSlice.reducer,
  scholarship: scholarshipSlice.reducer,
  studyLevel: studyLevelSlice.reducer,
  scoreCategory: scoreCategorySlice.reducer,
  score: scoreSlice.reducer,
  scoreUniversity: scoreUniversitySlice.reducer,
  city: citySlice.reducer,
  majorCategory: majorCategorySlice.reducer,
  major: majorSlice.reducer,
  program: programSlice.reducer,
  languageType: languageTypeSlice.reducer,
  i18n: i18nSlice.reducer,
  role: roleSlice.reducer,
  management: managementSlice.reducer,
  application: applicationSlice.reducer,
  position: positionSlice.reducer,
  setting: settingSlice.reducer,
  uploadType: uploadTypeSlice.reducer,
  programUpload: programUploadSlice.reducer,
  bank: bankSlice.reducer,
  product: productSlice.reducer,
})
