import { Button, Tooltip } from 'antd'
import React from 'react'
import Swal from 'sweetalert2'
import { CONFIRM_SAVE } from '../../../shared/constant/_confirm'
export default function TableSaveButton({ onClick, tooltip = 'บันทึก', id }) {
  const onSaveClick = () => {
    Swal.fire(CONFIRM_SAVE).then(({ isConfirmed }) => {
      if (isConfirmed) {
        onClick(id)
      }
    })
  }

  return (
    <Tooltip title={tooltip}>
      <Button
        type='text'
        shape='circle'
        style={{ color: 'green' }}
        icon={<span className='pi pi-check'></span>}
        onClick={onSaveClick}
      />
    </Tooltip>
  )
}
