import Swal from 'sweetalert2'
import { CONFIRM_REMOVE, CONFIRM_SAVE } from '../constant/_confirm'

export class SchemaTemplate {
  constructor(loading, base, auth) {
    this.loading = loading
    this.base = base
    this.auth = auth
  }
  setIdentified(id) {
    this.id = id
  }

  get isEdit() {
    return this.id ? true : false
  }
  setToken(token) {
    this.token = token
  }
  setUpdateFunction(fn) {
    this.update = fn
  }
  setCrateFunction(fn) {
    this.create = fn
  }
  setRemoveFunction(fn) {
    this.remove = fn
  }

  hasFile(values) {
    return Object.keys(values).some(
      (key) =>
        values[key] instanceof File ||
        (Array.isArray(values[key]) && values[key].some((v) => v instanceof File))
    )
  }

  prepareFormData(values) {
    const formData = new FormData()
    Object.keys(values).forEach((key) => {
      if (values[key] != null) {
        if (Array.isArray(values[key])) {
          for (let item of values[key]) {
            formData.append(key, item)
          }
        } else {
          formData.append(key, values[key])
        }
      }
    })
    return formData
  }
  async handleSubmit(_values, confirmed = true) {
    return confirmed ? await this.withConfirm(_values) : await this.onSave(_values)
  }
  async withConfirm(_values) {
    return Swal.fire(CONFIRM_SAVE).then(async ({ isConfirmed }) => {
      if (isConfirmed) {
        await this.onSave(_values)
      }
    })
  }
  async onSave(_values) {
    let values = _values
    if (!this.isEdit) {
      delete values.id
    }
    if (this.hasFile(_values)) {
      values = this.prepareFormData(_values)
    }
    if (this.isEdit) {
      return await this.onUpdate(values, this.id, this.auth.token)
    }
    return await this.onCreate(values, this.auth.token)
  }
  onCreate(...props) {
    const { loading } = this.loading
    if (!loading) {
      this.loading.setLoading(true)
      return this.create(...props)
        .then(() => {
          setTimeout(() => {
            this.base.successToast('เพิ่มข้อมูลสำเร็จ')
          }, 1000)
          return true
        })
        .catch((err) => {
          setTimeout(() => {
            this.base.errorToast(err.response?.data?.message || err.message)
          }, 1000)
        })
        .finally(() => {
          this.loading.setLoading(false)
        })
    }
  }
  onUpdate(...props) {
    const { loading } = this.loading
    if (!loading) {
      this.loading.setLoading(true)
      return this.update(...props)
        .then(() => {
          setTimeout(() => {
            this.base.successToast('แก้ไขข้อมูลสำเร็จ')
          }, 1000)
          return true
        })
        .catch((err) => {
          setTimeout(() => {
            this.base.errorToast(err.response?.data?.message || err.message)
          }, 1000)
        })
        .finally(() => {
          this.loading.setLoading(false)
        })
    }
  }
  async onRemove(...props) {
    return Swal.fire(CONFIRM_REMOVE).then(({ isConfirmed }) => {
      if (isConfirmed) {
        const { loading } = this.loading
        if (!loading) {
          this.loading.setLoading(true)
          this.remove(...props)
            .then((response) => {
              setTimeout(() => {
                this.base.successToast('ลบข้อมูลสำเร็จ')
              }, 1000)
            })
            .catch((err) => {
              setTimeout(() => {
                this.base.errorToast(err.message)
              }, 1000)
            })
            .finally(() => {
              this.loading.setLoading(false)
            })
        }
      }
    })
  }
}
