import axios from 'axios'
import { AppContextPath } from '../constant/_environment'
export const deleteScore = async (scoreId, token) => {
  return await axios.delete(`${AppContextPath.ADMIN_URL}/score/delete/${scoreId}`, {
    headers: { Authorization: `Bearer ${token}` },
  })
}

export const updateScore = async (payload, scoreId, token) => {
  return await axios.put(`${AppContextPath.ADMIN_URL}/score/update/${scoreId}`, payload, {
    headers: { Authorization: `Bearer ${token}` },
  })
}

export const createScore = async (payload, token) => {
  return await axios.post(`${AppContextPath.ADMIN_URL}/score/new`, payload, {
    headers: { Authorization: `Bearer ${token}` },
  })
}
export const deleteScoreCategory = async (categoryId, token) => {
  return await axios.delete(`${AppContextPath.ADMIN_URL}/score-category/delete/${categoryId}`, {
    headers: { Authorization: `Bearer ${token}` },
  })
}

export const updateScoreCategory = async (payload, categoryId, token) => {
  return await axios.put(`${AppContextPath.ADMIN_URL}/score-category/update/${categoryId}`, payload, {
    headers: { Authorization: `Bearer ${token}` },
  })
}

export const createScoreCategory = async (payload, token) => {
  return await axios.post(`${AppContextPath.ADMIN_URL}/score-category/new`, payload, {
    headers: { Authorization: `Bearer ${token}` },
  })
}
